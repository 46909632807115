import Page, { TitledContent } from '../../Layout/Page';
import { routes } from '../../features/navigation/constants';
import TermsAndConditionsSection from './components/TermsAndConditionsSection';

const TermsAndConditions = () => {
	return (
		<Page activeRoute={routes.TERMS_AND_CONDITIONS.route}>
			<TitledContent title="OLOOPO Obchodní podmínky">
				<TermsAndConditionsSection />
			</TitledContent>
		</Page>
	);
};

export default TermsAndConditions;
