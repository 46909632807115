import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { all, any, equals, pick, values } from 'ramda';
import { isNilOrEmpty } from 'ramda-extension';

const useDefaultUserFormData = (setFormData) => {
	const [searchParams] = useSearchParams();
	const name = searchParams.get('formName');
	const email = searchParams.get('formEmail');

	useEffect(() => {
		if (name || email) {
			setFormData((prevFormData) => ({ ...prevFormData, ...{ name, email } }));
		}
	}, [name, email, setFormData]);
};

const defaultFomData = { name: '', email: '', description: '', reCaptchaValue: null };
const defaultValidationState = { name: null, email: null, description: null };

export const useOtherProblemForm = () => {
	const [formData, setFormData] = useState(defaultFomData);
	const [formValidation, setFormValidation] = useState(defaultValidationState);

	useDefaultUserFormData(setFormData);

	const isSendButtonDisabled = () => {
		const validFieldsIsValid = all(equals(null), values(formValidation));

		const anySelectedFieldIsEmpty = any(
			isNilOrEmpty,
			values(pick(['name', 'email', 'description', 'reCaptchaValue'], formData)),
		);

		return anySelectedFieldIsEmpty || !validFieldsIsValid;
	};
	return { formData, setFormData, formValidation, setFormValidation, isSendButtonDisabled };
};
