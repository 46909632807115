import Typography from '@mui/material/Typography';
import ButtonPrimary from '../../../../common/Components/ButtonPrimary';
import { openInNewTab } from '../../../../features/navigation/utils';
import { useLocalizedAppExampleImg, useTranslation } from '../../../../features/localization/hooks';

const underLineStyles = {
	fontFamily: 'KohinoorBangla',
	fontSize: '10px',
	fontWeight: 400,
	lineHeight: '15px',
	letterSpacing: '0em',
	textAlign: 'left',
	maxWidth: '344px',
	color: '#5E7181',
};

const UnderLine = ({ text }) => <Typography sx={underLineStyles}>{text}</Typography>;

const PleasureSection = () => {
	const t = useTranslation('landingPage');
	const appExample = useLocalizedAppExampleImg();
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				background: '#FFF',
				paddingTop: '32px',
			}}>
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					overflowX: 'hidden',
					padding: '0 16px',
				}}>
				<img src={appExample} alt={'appExample'} style={{ width: '100%', height: 'auto' }} />
			</div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					background: '#FFF',
				}}>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						padding: '32px 24px 24px 16px',
					}}>
					<Typography
						sx={{
							fontFamily: 'KohinoorBangla',
							fontSize: '30px',
							fontWeight: 700,
							lineHeight: '45px',
							letterSpacing: '0em',
							textAlign: 'center',
							maxWidth: '264px',
						}}>
						{t('pleasureTitle')}
					</Typography>
				</div>
				<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
					<Typography
						sx={{
							fontFamily: 'KohinoorBangla',
							fontSize: '16px',
							fontWeight: 400,
							lineHeight: '24px',
							letterSpacing: '0em',
							textAlign: 'center',
							maxWidth: '344px',
						}}>
						{t('pleasureDescription')}
						<sup style={{ lineHeight: '15px', fontSize: '10px', verticalAlign: 'super' }}>{'2'}</sup>
					</Typography>
				</div>
			</div>
			<div
				style={{
					padding: '16px 0 24px 0',
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'center',
					position: 'sticky',
				}}>
				<ButtonPrimary label={t('oloopoItBtn')} onClick={openInNewTab('http://tst.oloopo.com:8080')} />
			</div>
			<div style={{ maxWidth: '344px', marginBottom: '24px' }}>
				<ol style={{ paddingLeft: '16px', margin: 0 }}>
					{[t('adOne'), t('adTwo')].map((text, index) => (
						<li key={index} style={{ ...underLineStyles, ...(index !== 0 && { marginTop: '8px' }) }}>
							<UnderLine text={text} />
						</li>
					))}
				</ol>
			</div>
		</div>
	);
};

export default PleasureSection;
