import { useTranslation } from '../../../../features/localization/hooks';

const Guarantee = () => {
	const t = useTranslation('supportAndFaq');
	return (
		<div>
			<p>{t('guarantee.content')}</p>
		</div>
	);
};

Guarantee.title = 'supportAndFaq.guarantee.title';
Guarantee.id = 'guarantee';

export default Guarantee;
