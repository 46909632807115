import Page, { TitledContent } from '../../Layout/Page';
import { routes } from '../../features/navigation/constants';
import PrivacySection from './components/PrivacySection';

const Privacy = () => {
	return (
		<Page activeRoute={routes.TERMS_AND_CONDITIONS.route}>
			<TitledContent title="Zásady ochrany soukromí">
				<PrivacySection />
			</TitledContent>
		</Page>
	);
};

export default Privacy;
