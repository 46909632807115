import Price from './faqs/Price';
import Chance from './faqs/Chance';
import LowValue from './faqs/LowValue';
import Guarantee from './faqs/Guarantee';
import Loop from './faqs/Loop';
import Item from './faqs/Item';
import MaximalChance from './faqs/MaximalChance';
import IncreaseRating from './faqs/IncreaseRating';
import LowerRaring from './faqs/LowerRaring';
import Limits from './faqs/Limits';
import OtherProblem from './faqs/OtherProblem';
import DocumentsAccordion from '../../../common/Components/DocumentsAccordion';

const accordionItems = [
	Price,
	Chance,
	LowValue,
	Guarantee,
	Loop,
	Item,
	MaximalChance,
	IncreaseRating,
	LowerRaring,
	Limits,
	OtherProblem,
];

const SupportAndFaqSection = () => (
	<div>
		<DocumentsAccordion accordionItems={accordionItems} />
	</div>
);

export default SupportAndFaqSection;
