import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import { Link, Element, scroller } from 'react-scroll';
import IntroSection from './IntroSection';
import IntroSectionMD from './IntroSection/IntroSectionMD';
import AlgorithmSection from './AlgorithmSection';
import AlgorithmSectionMd from './AlgorithmSection/AlgorithmSectionMd';
import StorySection from './StorySection';
import StorySectionMd from './StorySection/StorySectionMd';
import PleasureSection from './PleasureSection';
import PleasureSectionMd from './PleasureSection/PleasureSectionMd';

const HEADER_HEIGHT = 74;

const sections = {
	INTRO: 'introSection',
	INTRO_MD: 'introSectionMd',
	ALGORITHM: 'algorithmSection',
	ALGORITHM_MD: 'algorithmSectionMd',
	STORY: 'storySection',
	STORY_MD: 'storySectionMd',
	PLEASURE: 'pleasureSection',
	PLEASURE_MD: 'pleasureSectionMd',
};

const handleScrollToNext = (nextScreenCode) => (/* EVENT */) => {
	scroller.scrollTo(nextScreenCode, {
		duration: 1500,
		delay: 100,
		smooth: true,
		offset: -74,
	});
};

const SectionDivider = () => <div style={{ height: '200px' }}></div>;

const ScrollablePage = () => {
	const [pageContentHeight, setPageContentHeight] = useState(window.innerHeight - HEADER_HEIGHT);

	useEffect(() => {
		const handleWindowResize = () => {
			setPageContentHeight(window.innerHeight - HEADER_HEIGHT);
		};
		window.addEventListener('resize', handleWindowResize);
		return () => {
			window.removeEventListener('resize', handleWindowResize);
		};
	});

	return (
		<>
			{/* mobile version */}
			<Box sx={{ display: { xs: 'block', md: 'none' } }}>
				<div
					onClick={handleScrollToNext(sections.ALGORITHM)}
					id={sections.INTRO}
					style={{
						height: `${pageContentHeight}px`,
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
					}}>
					<IntroSection />
				</div>
				<div id={sections.ALGORITHM}>
					<AlgorithmSection />
				</div>
				<div id={sections.STORY}>
					<StorySection />
				</div>
				<div id={sections.PLEASURE}>
					<PleasureSection />
				</div>
			</Box>
			{/* desktop version */}
			<Box sx={{ display: { xs: 'none', md: 'block' } }}>
				<div
					onClick={handleScrollToNext(sections.ALGORITHM_MD)}
					id={sections.INTRO_MD}
					style={{
						height: `${pageContentHeight}px`,
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
					}}>
					<IntroSectionMD />
				</div>
				<SectionDivider />
				<div id={sections.ALGORITHM_MD}>
					<AlgorithmSectionMd />
				</div>
				<SectionDivider />
				<div id={sections.STORY_MD}>
					<StorySectionMd />
				</div>
				<div id={sections.PLEASURE_MD}>
					<PleasureSectionMd />
				</div>
			</Box>
		</>
	);
};

export default ScrollablePage;
