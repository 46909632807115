import { useTranslation } from '../../../../features/localization/hooks';

const Price = () => {
	const t = useTranslation('supportAndFaq');
	return (
		<div>
			<p>{t('price.content')}</p>
		</div>
	);
};

Price.title = 'supportAndFaq.price.title';
Price.id = 'price';

export default Price;
