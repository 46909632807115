import roleCeo from '../../../assets/images/roleCeo.png';
import roleCmo from '../../../assets/images/roleCmo.png';
import roleCto from '../../../assets/images/roleCto.png';
import roleCio from '../../../assets/images/roleCio.png';
import roleCfo from '../../../assets/images/roleCFO.png';
import roleDirectorOfLogistic from '../../../assets/images/roleDirectorOfLogistic.png';

export const BOARD_PERSONS = [
	{
		name: 'Marián Seifert',
		role: 'Chief Executive Officer',
		photo: roleCeo,
		linkedInUrl: 'https://www.linkedin.com/in/1seifert/',
	},
	{
		name: 'Jakub Kubica',
		role: 'Chief Technology Officer',
		photo: roleCto,
		linkedInUrl: 'https://www.linkedin.com/in/jakub-kubica-28527757/',
	},
	{
		name: 'Tomáš Pavelka',
		role: 'Chief Innovation Officer',
		photo: roleCio,
		linkedInUrl: 'https://www.linkedin.com/in/2019tomaspavelka/',
	},
	{
		name: 'Miroslav Rozenberg',
		role: 'Chief Marketing Officer',
		photo: roleCmo,
		linkedInUrl: 'https://www.linkedin.com/in/miroslavrozenberg/',
	},
	{
		name: 'Igor Čaniga',
		role: 'Chief Financial Officer',
		photo: roleCfo,
		linkedInUrl: 'https://www.linkedin.com/in/igor-caniga-8873a28a/',
	},
	{
		name: 'Miroslav Seifert',
		role: 'Director of Logistics',
		photo: roleDirectorOfLogistic,
		linkedInUrl: 'https://www.linkedin.com/in/miroslav-seifert-ab2a0356/',
	},
];
