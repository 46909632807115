export default {
	title: 'Careers',
	introSectionHeader: 'OLOOPO your job for the desired one. Join us.',
	introSectionFirstText:
		'Want to join a company that plans to conquer the world? Help us fulfil our\
		 mission so that everyone gets what they want in exchange for the unneeded.',
	introSectionSecondText:
		'It doesn’t matter where you live. Our employees work from multiple locations.\
		We work from home vast majority of time. We meet in person on quarterly basis\
		and pay accommodation for our employees.',
	bulletOne: "Things we don't use should be shared.",
	bulletTwo: 'We aim to live sustainably.',
	bulletThree: 'We support equality. Our employees and customers are our priority.',
	form: {
		generalInformation: {
			title: 'General information',
			fullName: 'Full name',
			email: 'Email',
			phoneNumber: 'Phone number',
			jobType: 'Preferred job type',
			jobTypeOptions: {
				fullTime: 'Full-time',
				partTime: 'Part-time',
				internship: 'Internship',
				shortTerm: 'Short-term',
			},
			department: 'Preferred department',
			departmentOptions: {
				it: 'Information Technology',
				finance: 'Finance',
				marketing: 'Marketing',
				hr: 'Human Resources',
				design: 'Design',
				legal: 'Legal',
				operations: 'Operations',
			},
		},
		attachments: {
			title: 'Show us your work',
			documentButton: 'Click to attach document',
			documentButtonDescription: 'CV / Resume (txt, pdf, doc, jpeg, ppt, docx)',
			documentMaxSpace: 'Maximum file size is 7 MB',
			linkToShare: 'Any important link to share',
			alertFileMB: 'The file {{fileName}} is larger than 7 MB.',
			alertFileType: 'The file {{fileName}} has an unsupported type.',
			alertFileProcessingError: 'Error processing {{file.name}}.',
		},
		employerBrandingSurvey: {
			title: 'Employer Branding survey',
			description: 'How did you hear about OLOOPO’s job listing?',
			questions: {
				oloopoRelated: 'I have friend/family who work at OLOOPO',
				socialMedia: 'Social media or online ads (LinkedIn post or ad, Facebook, Instagram, Google Ads, etc.)',
				media: 'Media ( newspaper, magazines, news websited, TV etc.)',
				jobPortal: 'Job portal or job search (LindekIn, Indeed, Google search, Justjoin.it, etc.)',
				school: 'University ( advert, newsletter, events, etc. )',
				advert: 'Physical advert ( banner, poster, etc. )',
				oloopoContact: 'A OLOOPO employee contacted me',
				other: 'Other',
			},
		},
		disclaimer: {
			description:
				'For information on how we will handle your personal data when you apply for a job us,\
				please see our ',
			link: 'Data Privacy Notice for Candidates.',
		},
		submitButton: 'Submit application',
		fieldRequired: 'Field is required',
	},
};
