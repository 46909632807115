import Box from '@mui/material/Box';
import { TitlePrimary, TextArea } from '../../../Layout/Page';
import { useTranslation } from '../../../features/localization/hooks';
import PersonCard from './PersonCard';
import { BOARD_PERSONS } from './constants';

const BoardSection = () => {
	const t = useTranslation('aboutUs');
	return (
		<Box sx={{ paddingTop: { xs: '32px', md: '64px' } }}>
			<TitlePrimary text={t('boardTitle')} />
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}>
				<Box sx={{ paddingX: '8px', paddingTop: '8px', maxWidth: '994px', width: '100%' }}>
					{BOARD_PERSONS.map((props, index) => (
						<PersonCard key={index} index={index} {...props} />
					))}
				</Box>
			</Box>
		</Box>
	);
};

export default BoardSection;
