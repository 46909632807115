import { useContext } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Divider from '@mui/material/Divider';

import CustomAlert from '../../../common/Components/CustomAlert';
import ReCaptcha from './ReCaptcha';
import Disclaimer from './Disclaimer';
import Attachments from './Attachments';
import GeneralInformation from './GeneralInformation';
import EmployerBrandingSurvey from './EmployerBrandingSurvey';
import ButtonPrimary from '../../../common/Components/ButtonPrimary';

import { isCareersFormInvalid } from '../utils';
import { useTranslation } from '../../../features/localization/hooks';
import { careersFormSubmittedAlert } from '../constant';
import { CareersContext } from '../../../store/CareersContextProvider';
import {languagesDefinitionMap} from "../../../features/localization/constants";

const dividerStyle = { marginBottom: { xs: '24px', md: '40px' } };

const centeredProps = { display: 'flex', flexDirection: 'column', alignItems: 'center' };

const CareersFormSection = () => {
	const { formData, formValidation, handleClickReCaptcha, handleSubmitForm, isCallInProgress, isSubmitted } =
		useContext(CareersContext);

	const t = useTranslation('careers');

	return (
		<Box paddingTop={{ md: '80px', xs: '24px' }} alignSelf={'center'} display={'flex'} flexDirection={'column'}>
			<GeneralInformation />
			<Divider orientation={'vertical'} sx={dividerStyle} />
			<Attachments />
			<Divider orientation={'vertical'} sx={dividerStyle} />
			<EmployerBrandingSurvey />
			<Grid container spacing={2}>
				<Grid xs={12} sx={{ marginTop: '40px', ...centeredProps }}>
					<ReCaptcha onClickHandler={handleClickReCaptcha} translationLanguage={languagesDefinitionMap.EN.code} />
				</Grid>
				<Grid xs={12} sx={{ marginTop: '24px', ...centeredProps }}>
					<Disclaimer />
				</Grid>
				<Grid xs={12} sx={{ marginTop: '24px', ...centeredProps }}>
					{isCallInProgress && !isSubmitted ? (
						<CustomAlert
							severity={'info'}
							title={careersFormSubmittedAlert.INFO.title}
							description={careersFormSubmittedAlert.INFO.description}
						/>
					) : isSubmitted ? (
						<CustomAlert
							severity={'success'}
							title={careersFormSubmittedAlert.SUCCESS.title}
							description={careersFormSubmittedAlert.SUCCESS.description}
						/>
					) : (
						<ButtonPrimary
							disabled={isCareersFormInvalid(formData, formValidation)}
							label={t('form.submitButton')}
							onClick={handleSubmitForm}
						/>
					)}
				</Grid>
			</Grid>
		</Box>
	);
};

export default CareersFormSection;
