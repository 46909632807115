import Page, { TitledContent } from '../../Layout/Page';
import { routes } from '../../features/navigation/constants';
import { useTranslation } from '../../features/localization/hooks';
import AboutSection from './components/AboutSection';
import BoardSection from './components/BoardSection';

const AboutUs = () => {
	const t = useTranslation('aboutUs');
	return (
		<Page activeRoute={routes.ABOUT_US.route}>
			<TitledContent title={t('title')}>
				<AboutSection />
				<BoardSection />
			</TitledContent>
		</Page>
	);
};

export default AboutUs;
