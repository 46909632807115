import Page, { TitledContent } from '../../Layout/Page';
import { routes } from '../../features/navigation/constants';
import SupportAndFaqSection from './components/SupportAndFaqSection';
import { useTranslation } from '../../features/localization/hooks';

const SupportAndFaq = () => {
	const t = useTranslation('common');
	return (
		<Page activeRoute={routes.SUPPORT_AND_FAQ.route}>
			<TitledContent title={t(`navigation.${routes.SUPPORT_AND_FAQ.label}`)}>
				<SupportAndFaqSection />
			</TitledContent>
		</Page>
	);
};

export default SupportAndFaq;
