import youtubeDummy1 from '../../assets/images/DummyYTImgages/youtubeDummy1.jpeg';
import youtubeDummy2 from '../../assets/images/DummyYTImgages/youtubeDummy2.jpeg';
import youtubeDummy3 from '../../assets/images/DummyYTImgages/youtubeDummy3.jpeg';
import youtubeDummy4 from '../../assets/images/DummyYTImgages/youtubeDummy4.jpeg';
import youtubeDummy5 from '../../assets/images/DummyYTImgages/youtubeDummy5.jpeg';
import youtubeDummy6 from '../../assets/images/DummyYTImgages/youtubeDummy6.jpeg';

export const tutorialVideos = [
	{
		name: 'Noisia',
		title: 'videos.dummyTitle1',
		description: 'videos.dummyDescription',
		url: 'https://www.youtube.com/watch?v=JnhjWUepaE8',
		image: youtubeDummy1,
		duration: '5:02',
	},
	{
		name: 'Noisia2',
		title: 'videos.dummyTitle2',
		description: 'videos.dummyDescription',
		url: 'https://www.youtube.com/watch?v=1RCTyKUOKq4',
		image: youtubeDummy2,
		duration: '4:59',
	},
	{
		name: 'Dummy1',
		title: 'videos.dummyTitle3',
		description: 'videos.dummyDescription',
		url: 'https://www.youtube.com/watch?v=Px5nmExb-uw',
		image: youtubeDummy3,
		duration: '20:02',
	},
	{
		name: 'Dummy2',
		title: 'videos.dummyTitle4',
		description: 'videos.dummyDescription',
		url: 'https://www.youtube.com/watch?v=9VsEAEX6C9Q',
		image: youtubeDummy4,
		duration: '0:13',
	},
	{
		name: 'Dummy3',
		title: 'videos.dummyTitle5',
		description: 'videos.dummyDescription',
		url: 'https://www.youtube.com/watch?v=QItGeDOZNMA',
		image: youtubeDummy5,
		duration: '11:54:56',
	},
	{
		name: 'Dummy4',
		title: 'videos.dummyTitle6',
		description: 'videos.dummyDescription',
		url: 'https://www.youtube.com/watch?v=8xT0vWporWs',
		image: youtubeDummy6,
		duration: '2:39',
	},
];
