import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Collapse from '@mui/material/Collapse';
import Snackbar from '@mui/material/Snackbar';
import ButtonPrimary from '../../common/Components/ButtonPrimary';
import CookiesDisclaimer from './components/CookiesDisclaimer';
import TitleWithCrossIcon from './components/TitleWithCrossIcon';
import CookieCategorySetting from './components/CookieCategorySetting';
import ButtonSecondary from '../../common/Components/ButtonSecondary/ButtonSecondary';
import CookieColumnWrapper from './components/CookieColumnWrapper';
import { useTranslation } from '../localization/hooks';
import { COOKIE_CATEGORIES, cookieCategoryEnum } from './constants';
import {
	getIsCookiesModalVisible,
	isTimestampInPast,
	loadCookieConsentsFromLocalStorage,
	removeCookieConsentTimestamp,
	saveCookieConsents,
	enableAllAndSave,
	setCookieConsentTimestamp,
} from './utils';
import { useBlockCookiesModal } from './hooks';

const essentialCategory = cookieCategoryEnum.ESSENTIAL;

const CookiesModal = () => {
	const t = useTranslation('cookies');
	const isCookiesModalBlocked = useBlockCookiesModal();
	const [isCookiesModalVisible, setIsCookiesModalVisible] = useState(getIsCookiesModalVisible(isCookiesModalBlocked));
	const [isExpanded, setIsExpanded] = useState(false);
	const [cookieConsents, setCookieConsents] = useState(loadCookieConsentsFromLocalStorage(COOKIE_CATEGORIES));

	const handleClose = () => setIsCookiesModalVisible(false);

	const handleCookieEnableAllAndSave = () => {
		const cookieState = enableAllAndSave(cookieConsents);
		setCookieConsents(cookieState);
		saveCookieConsents(cookieState);
		setIsCookiesModalVisible(false);
	};

	const handleCookieSave = (cookies) => {
		saveCookieConsents(cookies);
		setIsCookiesModalVisible(false);
	};

	const handleCookieChange = (event) => {
		const { name, checked } = event.target;
		checked
			? setCookieConsentTimestamp(name, cookieConsents, setCookieConsents)
			: removeCookieConsentTimestamp(name, cookieConsents, setCookieConsents);
	};

	const handleCookieSettingClick = () => setIsExpanded(!isExpanded);

	return (
		<Snackbar
			open={isCookiesModalVisible}
			autoHideDuration={null}
			ClickAwayListenerProps={{ onClickAway: () => null }}
			onClose={handleClose}
			sx={{
				paddingX: { xs: '16px', md: '48px' },
				display: 'flex',
				flexDirection: 'column-reverse',
				paddingBottom: { xs: '16px', md: '32px' },
			}}>
			<Box
				sx={{
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					backgroundColor: 'white',
					borderRadius: '16px',
					padding: '24px',
					overflow: 'overlay',
					maxHeight: { xs: '600px', md: 'none' },
					maxWidth: '100%',
					width: '100%',
					boxShadow: 10,
				}}>
				<TitleWithCrossIcon title={t('title')} onClose={handleClose} />
				<Box display={'flex'} flexDirection={{ xs: 'column', md: 'row' }} justifyContent={'flex-end'}>
					<CookiesDisclaimer />
					{!isExpanded && (
						<Box
							sx={{
								display: 'flex',
								flexDirection: { xs: 'column-reverse', md: 'row' },
								width: { xs: '100%', md: '100%' },
								marginTop: { xs: '16px', md: '0px' },
							}}>
							<Box sx={{ marginLeft: { md: '32px' }, marginBottom: { xs: '8px' } }}></Box>
							<ButtonSecondary label={t('cookieSettings')} onClick={handleCookieSettingClick} />
							<Box sx={{ marginLeft: { md: '16px' }, marginBottom: { xs: '8px' } }}></Box>
							<ButtonPrimary label={'OK'} onClick={handleCookieEnableAllAndSave} />
						</Box>
					)}
				</Box>
				<Collapse sx={{ marginTop: '16px' }} in={isExpanded} timeout="auto" unmountOnExit>
					<Grid container spacing={2}>
						{COOKIE_CATEGORIES.map((category, index) => (
							<CookieCategorySetting
								key={category}
								isDisabled={category === essentialCategory}
								isChecked={
									category === essentialCategory ||
									(cookieConsents[category] && !isTimestampInPast(cookieConsents[category]))
								}
								isBottomBorder={COOKIE_CATEGORIES.length > index + 1}
								category={category}
								handleCookieChange={handleCookieChange}
							/>
						))}
						<Grid md={12}>
							<Grid container spacing={2} justifyContent={'flex-end'}>
								<CookieColumnWrapper>
									<ButtonSecondary label={t('disableAll')} onClick={() => handleCookieSave({})} />
								</CookieColumnWrapper>
								<CookieColumnWrapper>
									<ButtonPrimary label={t('enableAll')} onClick={handleCookieEnableAllAndSave} />
								</CookieColumnWrapper>
								<CookieColumnWrapper>
									<ButtonPrimary label={t('settingsSave')} onClick={() => handleCookieSave(cookieConsents)} />
								</CookieColumnWrapper>
							</Grid>
						</Grid>
					</Grid>
				</Collapse>
			</Box>
		</Snackbar>
	);
};

export default CookiesModal;
