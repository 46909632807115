import Box from '@mui/material/Box';

import { TextArea } from '../../../Layout/Page';

export default ({ children, title, titleDescription }) => (
	<Box
		sx={{
			alignSelf: 'center',
			width: '100%',
			maxWidth: '756px',
			padding: '24px',
			backgroundColor: 'background.secondary',
			borderRadius: '16px',
		}}>
		{title && <TextArea text={title} bold inline />}
		{titleDescription && <TextArea text={titleDescription} inline />}
		{children}
	</Box>
);
