const DocumentIntro = () => {
	return (
		<div>
			<p>
				Tyto obchodní podmínky stanovují podmínky Vašeho užívání OLOOPO platformy a služeb, které jsou popsané níže.
				Projevíte-li svůj souhlas s těmito obchodními podmínkami, což je podmínka registrace na OLOOPO platformě a
				využití dalších funkcionalit a služeb OLOOPO, stanovují tyto obchodní podmínky právně závaznou smlouvu. Tyto
				obchodní podmínky tak stanovují smluvní práva a povinnosti Vás a OLOOPO.
			</p>
			<p>
				Jakákoli odchylná ujednání mezi OLOOPO a Vámi musí mít formu písemné smlouvy. OLOOPO nebude vázáno jakýmkoli
				Vaším protinávrhem odchylujícím se nebo doplňujícím tyto obchodní podmínky, neodsouhlasí-li jej výslovně v
				písemné formě.
			</p>
			<p>
				Rádi bychom Vás upozornili, že tento dokument může obsahovat odkazy na jiné dokumenty rovněž zveřejněné na naší
				platformě nebo veřejně přístupné na internetu. Tyto dokumenty jsou v takovém případě vtěleny do těchto
				obchodních podmínek jako jejich nedílné součásti a můžou obsahovat naše dodatečná vzájemná práva a povinnosti.
				Věnujte jim, prosím, pozornost a pečlivě si je prostudujte.
			</p>
		</div>
	);
};

DocumentIntro.title = 'Co tento dokument představuje?';
DocumentIntro.id = 'documentIntro';

export default DocumentIntro;
