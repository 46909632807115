import iconFacebook from '../../assets/images/iconFacebook.svg';
import iconInstagram from '../../assets/images/iconInstagram.svg';
import iconTiktok from '../../assets/images/iconTiktok.svg';
import iconYoutube from '../../assets/images/iconYoutube.svg';

export const SOCIAL_MEDIA_MENU_ITEMS = [
	{
		code: 'facebook',
		link: 'https://www.facebook.com/oloopo.official',
		icon: iconFacebook,
	},
	{
		code: 'instagram',
		link: 'https://www.instagram.com/oloopo_official',
		icon: iconInstagram,
	},
	{
		code: 'tiktok',
		link: 'https://www.tiktok.com/@oloopo_official',
		icon: iconTiktok,
	},
	{
		code: 'youtube',
		link: 'https://youtube.com/@oloopo',
		icon: iconYoutube,
	},
];
