import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';

const CookieColumnWrapper = ({ children }) => (
	<Grid xs={12} md={3}>
		<div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>{children}</div>
	</Grid>
);

export default CookieColumnWrapper;
