const OperationalConditions = () => {
	return (
		<div>
			<p>
				OLOOPO provozuje svoji platformu jako komunitní platformu, a tedy jeho obsah je z části vytvářen samotnými
				uživateli. K jakémukoli obsahu nahranému na OLOOPO platformu, jenž je chráněn právem duševního vlastnictví
				(např. popisy předmětů, fotografie, grafická díla), udělujete společnosti OLOOPO neomezenou, neodvolatelnou a
				trvalou licenci k jeho užití pro účely provozování platformy a poskytování služeb OLOOPO.
			</p>
			<p>
				OLOOPO nicméně nenese žádnou odpovědnost za obsah, jež je na platformu umístěn jakoukoli třetí stranou. OLOOPO v
				rámci obvyklých nástrojů umožňuje monitorování uživatelského obsahu a jeho nahlašování jinými uživateli. OLOOPO
				si vyhrazuje právo jakýkoli obsah nesplňující požadavky dle těchto podmínek bez dalšího a trvale odstranit a
				učinit jakékoli další zákonné kroky, a to bez jakékoli odpovědnosti vůči třetím osobám.
			</p>
			<p>
				OLOOPO nenese žádnou odpovědnost a neposkytuje jakékoli záruky týkající se dostupnosti platformy, dostupnosti a
				trvající podpoře její funkcionalit, bezchybovosti platformy nebo úplnosti a pravdivosti veškerého obsahu
				zveřejněného na platformě. Platforma může být dočasně nebo trvale nedostupná, jako celek nebo částečně, a také
				může být na základě našeho výhradního rozhodnutí bez předchozího upozornění rozšířena, upravena nebo odstraněna.
			</p>
			<p>
				Vlastnictví a veškerá práva duševního vlastnictví související s logem OLOOPO, názvem OLOOPO, názvy OLOOPO služeb
				a platformou samotnou náleží společnosti OLOOPO (nebo jejím obchodním partnerům) a uživatelům je uděleno
				oprávnění k užívání platformy pouze v nezbytném rozsahu k využití funkcionalit platformy v souladu s těmito
				podmínkami. Algoritmy, na kterých je postavena OLOOPO platforma můžou být v některých zemích předmětem patentové
				ochrany.
			</p>
			<p>
				Prosté zveřejnění ochranné, servisní nebo obchodní známky, loga, názvu třetí strany nebo jejího produktu nebo
				služby na OLOOPO platformě neznamená, že tato strana je smluvním partnerem OLOOPO nebo platformu a služby OLOOPO
				jakýmkoli způsobem doporučuje, sponzoruje, podporuje nebo akceptuje. Veškerá práva třetích stran k označení
				svých služeb a produktů zůstávají zachována a nedotčena jejich zveřejněním na OLOOPO platformě a OLOOPO s tímto
				nečiní jakékoli nároky na tato označení.
			</p>
		</div>
	);
};

OperationalConditions.title = 'Za jakých podmínek OLOOPO provozuje svoji platformu?';
OperationalConditions.id = 'operationalConditions';

export default OperationalConditions;
