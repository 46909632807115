import { values } from 'ramda';
import { routes } from '../navigation/constants';
import Cookies from '../../Pages/Privacy/components/privacySectionChapters/Cookies';
import { ACCORDION_PANEL_ID_PREFIX, ACTIVE_PANEL } from '../../common/Components/DocumentsAccordion/constants';

export const cookieCategoryEnum = {
	ESSENTIAL: 'essential',
	MARKETING: 'marketing',
	PERSONALIZATION: 'personalization',
	ANALYTICS: 'analytics',
};

export const COOKIE_CONSENT_NOT_GIVEN = 'cookiesConsentNotGiven';

export const COOKIE_CATEGORIES = values(cookieCategoryEnum);

const cookiesPanel = `${ACCORDION_PANEL_ID_PREFIX}${Cookies.id}`;

export const PRIVACY_NAVIGATION_TO_COOKIES_PANEL_URL = `${routes.PRIVACY.route}/?${ACTIVE_PANEL}=${cookiesPanel}`;
