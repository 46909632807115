import { useTranslation } from '../../../../features/localization/hooks';

const Loop = () => {
	const t = useTranslation('supportAndFaq');
	return (
		<div>
			<p>{t('loop.content_1')}</p>
			<p>{t('loop.content_2')}</p>
		</div>
	);
};

Loop.title = 'supportAndFaq.loop.title';
Loop.id = 'loop';

export default Loop;
