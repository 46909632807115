import Box from '@mui/material/Box';
import './OloopoLogo.css';
import oloopoLogo from '../../../assets/images/oloopoLogo.svg';
import oloopoLogoInverted from '../../../assets/images/oloopoLogoInverted.svg';
import { useHandleNavigate } from '../../../features/navigation/hooks';
import { routes } from '../../../features/navigation/constants';

const DEFAULT_HEIGHT = '44px';

const OloopoLogo = ({ height = DEFAULT_HEIGHT, inverted = false }) => {
	const handleNavigate = useHandleNavigate();
	return (
		<Box sx={{ display: 'block', flexGrow: 1, height }}>
			<div className="OloopoLogo" onClick={handleNavigate(routes.HOME.route)}>
				<img style={{ height }} src={inverted ? oloopoLogoInverted : oloopoLogo} alt="oloopo logo" />
			</div>
		</Box>
	);
};

export default OloopoLogo;
