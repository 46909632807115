import { useRef, useState, useCallback, useContext } from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Unstable_Grid2';
import { isEmpty, gte } from 'ramda';
import { dotPath } from 'ramda-extension';

import ValidationInput from './ValidationInput';
import FormTile from './FormTile';
import FileRow from './FileRow';
import ButtonPrimary from '../../../common/Components/ButtonPrimary';

import { CareersContext } from '../../../store/CareersContextProvider';
import { openFileInNewTab, removeFileFormFormData, uploadImage } from '../utils';
import { useTranslation } from '../../../features/localization/hooks';
import { careersFormStructure } from './careersFormStructure';
import { MAX_FILE_COUNT, STRINGIFIED_ALLOWED_MIME_TYPES } from '../constant';
import { GET_URL_ERROR_MESSAGE } from '../../../features/validations/validations';
import { languagesDefinitionMap } from '../../../features/localization/constants';

const Attachments = () => {
	const { formData, formValidation, handleInputChange, setFormValidation, setFormData } = useContext(CareersContext);
	const t = useTranslation('careers');
	const [uploadFileAlerts, setUploadFileAlerts] = useState([]);
	const ref = useRef();

	const updateAlertHandler = useCallback(
		(alertMessageTPath, fileName) =>
			setUploadFileAlerts((prevAlert) => [...prevAlert, t(alertMessageTPath, { fileName })]),
		[setUploadFileAlerts],
	);

	return (
		<FormTile title={t('form.attachments.title')} titleDescription={' (required file or link)'}>
			<FormControl
				sx={{
					width: '100%',
				}}>
				<Grid>
					<Grid sx={{ flexDirection: 'column', marginY: '16px' }}>
						{formData.attachments.map(({ name, contentBase64, mimeType }, index) => (
							<FileRow
								key={index}
								label={name}
								onLabelClick={() => openFileInNewTab(contentBase64, mimeType)}
								onCrossClick={() => removeFileFormFormData(setFormData, index, formData)}
							/>
						))}
						{uploadFileAlerts &&
							uploadFileAlerts.map((alert, index) => (
								<Grid key={index} sx={{ marginBottom: index + 1 < uploadFileAlerts.length && '8px' }}>
									<Alert sx={{ width: { md: '400px' } }} severity="error" variant={'filled'}>
										<AlertTitle> {alert}</AlertTitle>
									</Alert>
								</Grid>
							))}
					</Grid>
					<Grid
						container
						spacing={3}
						flexDirection={{ xs: 'column', md: 'row' }}
						marginBottom={'16px'}
						alignItems={{ md: 'center' }}
						alignContent={'center'}>
						<Grid md={5}>
							<ButtonPrimary
								disabled={gte(dotPath('attachments.length')(formData), MAX_FILE_COUNT)}
								label={t('form.attachments.documentButton')}
								onClick={() => (isEmpty(uploadFileAlerts) ? null : setUploadFileAlerts([]))}>
								<input
									multiple
									ref={ref}
									type="file"
									accept={STRINGIFIED_ALLOWED_MIME_TYPES}
									onChange={(event) => uploadImage(event, formData, setFormData, updateAlertHandler)}
									onClick={(event) => {
										event.target.value = null;
									}}
									hidden
								/>
							</ButtonPrimary>
						</Grid>
						<Grid>
							{[
								'form.attachments.documentButtonDescription',
								'form.attachments.maxDocuments',
								'form.attachments.documentMaxSpace',
							].map((label, index) => (
								<Typography
									key={index}
									paragraph={false}
									sx={{
										color: 'typography.gray',
										fontFamily: 'KohinoorBangla',
										fontSize: '16px',
										fontWeight: 400,
										lineHeight: '24px',
										letterSpacing: '0em',
									}}>
									{t(label)}
								</Typography>
							))}
						</Grid>
					</Grid>
					<Grid>
						<ValidationInput
							translationLanguage={languagesDefinitionMap.EN.code}
							name={careersFormStructure.attachments.LINK_TO_SHARE}
							section={'attachments'}
							formData={formData}
							formValidation={formValidation}
							setFormValidation={setFormValidation}
							onChange={handleInputChange}
							errorMessageResolver={GET_URL_ERROR_MESSAGE}
						/>
					</Grid>
				</Grid>
			</FormControl>
		</FormTile>
	);
};

export default Attachments;
