import OtherProblemForm from './OtherProblemForm';
import { useTranslation } from '../../../../features/localization/hooks';

const OtherProblem = () => {
	const t = useTranslation('supportAndFaq');
	return (
		<div>
			<p>{t('otherProblem.content')}</p>
			<OtherProblemForm />
		</div>
	);
};

OtherProblem.title = 'supportAndFaq.otherProblem.title';
OtherProblem.id = 'otherProblem';

export default OtherProblem;
