import Citation from './Citation';
import swapExampleEduardAndZuzana from '../../../../assets/images/swapExampleEduardAndZuzana.svg';
import swapExampleJana from '../../../../assets/images/swapExampleJana.svg';
import swapExampleTomas from '../../../../assets/images/swapExampleTomas.svg';
import { useTranslation } from '../../../../features/localization/hooks';

const StorySectionMd = () => {
	const t = useTranslation('landingPage');
	return (
		<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', background: '#FFF' }}>
			<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
				<div style={{ paddingLeft: '16px', paddingRight: '32px' }}>
					<Citation text={t('citationOne.text')} name={t('citationOne.name')} md />
				</div>
				<div>
					<img src={swapExampleEduardAndZuzana} alt={'swapExampleEduardAndZuzana'} width="150%" />
				</div>
			</div>
			<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
				<div>
					<img
						src={swapExampleJana}
						alt={'swapExampleJana'}
						width="150%"
						style={{ position: 'relative', right: '50%' }}
					/>
				</div>
				<div style={{ paddingLeft: '32px', paddingRight: '16px' }}>
					<Citation text={t('citationTwo.text')} name={t('citationTwo.name')} md />
				</div>
			</div>
			<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
				<div style={{ paddingLeft: '16px', paddingRight: '32px' }}>
					<Citation text={t('citationThree.text')} name={t('citationThree.name')} md />
				</div>
				<div>
					<img src={swapExampleTomas} alt={'img_8_Tomas'} width="150%" />
				</div>
			</div>
		</div>
	);
};

export default StorySectionMd;
