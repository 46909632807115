import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import PropTypes from 'prop-types';
import { useTranslation } from '../../localization/hooks';

const CustomSwitch = styled((props) => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(
	({ theme }) => ({
		width: 42,
		height: 26,
		padding: 0,
		'& .MuiSwitch-switchBase': {
			padding: 0,
			margin: 2,
			transitionDuration: '300ms',
			'&.Mui-checked': {
				transform: 'translateX(16px)',
				color: '#fff',
				'& + .MuiSwitch-track': {
					backgroundColor: '#FA7007',
					opacity: 1,
					border: 0,
				},
				'&.Mui-disabled + .MuiSwitch-track': {
					opacity: 0.3,
				},
			},
			'&.Mui-focusVisible .MuiSwitch-thumb': {
				color: '#FA7007',
				border: '6px solid #fff',
			},
			'&.Mui-disabled .MuiSwitch-thumb': {
				color: theme.palette.grey[100],
			},
			'&.Mui-disabled + .MuiSwitch-track': {
				opacity: 0.7,
			},
		},
		'& .MuiSwitch-thumb': {
			boxSizing: 'border-box',
			width: 22,
			height: 22,
		},
		'& .MuiSwitch-track': {
			borderRadius: 26 / 2,
			backgroundColor: '#E9E9EA',
			opacity: 1,
			transition: theme.transitions.create(['background-color'], {
				duration: 500,
			}),
		},
	}),
);

const CookieCategorySetting = ({ isDisabled, isChecked, isBottomBorder, handleCookieChange, category }) => {
	const t = useTranslation('cookies');
	return (
		<Box
			sx={{
				borderBottom: isBottomBorder && 1,
				paddingBottom: '16px',
				marginBottom: '16px',
				borderColor: 'typography.primary',
			}}>
			<FormControlLabel
				disabled={isDisabled}
				checked={isChecked}
				control={<CustomSwitch sx={{ m: 1 }} />}
				label={
					<Typography
						sx={{
							fontFamily: 'inherit',
							fontSize: '18px',
							fontWeight: 700,
							lineHeight: '27px',
							letterSpacing: '0em',
							textAlign: 'left',
						}}
						variant="body1"
						color="typography.default">
						{t(`category.${category}.title`)}
					</Typography>
				}
				name={category}
				onChange={handleCookieChange}
			/>
			<Box>
				<Typography
					sx={{
						fontFamily: 'inherit',
						fontSize: '14px',
						fontWeight: 400,
						lineHeight: '21px',
					}}
					variant="body1"
					color="typography.default">
					{t(`category.${category}.description`)}
				</Typography>
			</Box>
		</Box>
	);
};

CookieCategorySetting.propTypes = {
	isDisabled: PropTypes.bool,
	isChecked: PropTypes.bool,
	isBottomBorder: PropTypes.bool,
	handleCookieChange: PropTypes.func,
	category: PropTypes.string,
};

export default CookieCategorySetting;
