import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import documentGray from '../../../assets/images/documentGray.png';
import cross from '../../../assets/images/cross.png';

const FileRow = ({ label, onLabelClick, onCrossClick }) => (
	<Box
		sx={{
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			cursor: 'pointer',
			alignItems: 'center',
			marginBottom: { xs: '8px' },
		}}>
		<Box
			sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: { xs: '4px' } }}
			onClick={onLabelClick}>
			<Box
				sx={{
					width: { xs: '18px', md: '20px' },
					height: { xs: '18px', md: '20px' },
					marginRight: '18px',
				}}>
				<img src={documentGray} alt="document" width="100%" height="100%" />
			</Box>
			<Typography
				sx={{
					color: 'typography.default',
					fontFamily: 'KohinoorBangla',
					fontSize: { xs: '16px', md: '16px' },
					lineHeight: { xs: '16px', md: '24px' },
					letterSpacing: '0em',
				}}>
				{label}
			</Typography>
		</Box>
		<Box
			onClick={onCrossClick}
			sx={{
				width: { xs: '20px', md: '22px' },
				height: { xs: '20px', md: '22px' },
			}}>
			<img src={cross} alt="document" width="100%" height="100%" />
		</Box>
	</Box>
);

FileRow.propTypes = {
	label: PropTypes.string,
	onLabelClick: PropTypes.func,
	onCrossClick: PropTypes.func,
};

export default FileRow;
