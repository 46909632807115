// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../fonts/KohinoorBangala/KohinoorBangla-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../fonts/KohinoorBangala/KohinoorBangla-Bold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n\tfont-family: 'KohinoorBangla';\n\tsrc: local('KohinoorBangla'), url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('truetype');\n\tfont-weight: 400;\n}\n\n@font-face {\n\tfont-family: 'KohinoorBangla';\n\tsrc: local('KohinoorBangla'), url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('truetype');\n\tfont-weight: 700;\n}\n\n/* Primarily this is the KohinoorBangla font provider */\n.App {\n\tfont-family: 'KohinoorBangla', sans-serif;\n\toverflow-x: hidden;\n}\n", "",{"version":3,"sources":["webpack://./src/assets/css/App.css"],"names":[],"mappings":"AAAA;CACC,6BAA6B;CAC7B,wFAA2G;CAC3G,gBAAgB;AACjB;;AAEA;CACC,6BAA6B;CAC7B,wFAAwG;CACxG,gBAAgB;AACjB;;AAEA,uDAAuD;AACvD;CACC,yCAAyC;CACzC,kBAAkB;AACnB","sourcesContent":["@font-face {\n\tfont-family: 'KohinoorBangla';\n\tsrc: local('KohinoorBangla'), url('../fonts/KohinoorBangala/KohinoorBangla-Regular.ttf') format('truetype');\n\tfont-weight: 400;\n}\n\n@font-face {\n\tfont-family: 'KohinoorBangla';\n\tsrc: local('KohinoorBangla'), url('../fonts/KohinoorBangala/KohinoorBangla-Bold.ttf') format('truetype');\n\tfont-weight: 700;\n}\n\n/* Primarily this is the KohinoorBangla font provider */\n.App {\n\tfont-family: 'KohinoorBangla', sans-serif;\n\toverflow-x: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
