const UserAccount = () => {
	return (
		<div>
			<p>Podmínkou využívání platformy OLOOPO je založení osobního uživatelského účtu.</p>
			<p>
				V rámci založení uživatelského účtu budeme od Vás vyžadovat uvedení povinných osobních a jiných informací, které
				jsou nutné pro poskytování našich služeb, jak jsou popsány v tomto dokumentu. Současně budete moct uvést
				nepovinné informace, které budou sloužit zejména k lepšímu a pohodlnějšímu užívání OLOOPO platformy a služeb
				nebo k marketingovým účelům. Tyto nepovinné údaje budete moct doplnit do svého účtu i později.
			</p>
			<p>
				Zásady a pravidla zpracování a ochrany osobních údajů jsou dále stanoveny v Zásadách ochrany osobních údajů.
			</p>
			<p>Podmínkou registrace je udělení Vašeho souhlasu s těmito podmínkami a Zásadami ochrany osobních údajů.</p>
			<p>
				Váš uživatelský účet slouží k individualizovanému přístupu k OLOOPO platformě a službám včetně zprostředkování
				mezi Vámi, společností OLOOPO a jinými uživateli. Součástí uživatelského účtu může být i Váš profil
				zaznamenávající zkušenosti jiných uživatelů jakož i Vaše využívaní služeb OLOOPO, přičemž tento profil je
				veřejný bez možnosti vypnutí této funkce. Uživatelská hodnocení, které Vám udělí jiní uživatelé, splňují-li
				požadavky přípustného obsahu na OLOOPO platformě, nejsou společností OLOOPO redigovány a verifikovány, nicméně
				udělení hodnocení je umožněno pouze účastníkům Vašich řetězců akceptací.
			</p>
		</div>
	);
};

UserAccount.title = 'K čemu slouží uživatelský účet?';
UserAccount.id = 'userAccount';

export default UserAccount;
