import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import ReactFlagsSelect from 'react-flags-select';
import { styled } from '@mui/material/styles';

import { ORDERED_FLAG_CODES } from '../constants';
import { getFlagCodeFromCode, getCodeFromFlagCode } from '../utils';

const StyledReactFlagsSelect = styled(ReactFlagsSelect)(({ theme }) => ({
	'.ReactFlagsSelect-module_selectBtn__19wW7': {
		border: 'none',
		borderRadius: 'unset',
		[theme.breakpoints.up('xs')]: { fontSize: '37px !important' },
		[theme.breakpoints.up('md')]: { fontSize: '20px !important' },
	},
	'.ReactFlagsSelect-module_selectOptions__3LNBJ': {
		overflow: 'visible',
		[theme.breakpoints.up('xs')]: { fontSize: '37px !important' },
		[theme.breakpoints.up('md')]: { fontSize: '20px !important' },
	},
	'.ReactFlagsSelect-module_selectBtn__19wW7:after': {
		display: 'none',
	},
	'.ReactFlagsSelect-module_selectOption__3pcgW': {
		listStyleType: 'none',
	},
	'.ReactFlagsSelect-module_selectValue__152eS': {
		color: 'white',
	},
}));

const LanguageSwitcher = () => {
	const { i18n } = useTranslation();

	const handleChangeLanguage = useCallback(
		(code) => {
			i18n.changeLanguage(getCodeFromFlagCode(code));
		},
		[i18n],
	);

	return (
		<Box width={{ md: '60px' }}>
			<StyledReactFlagsSelect
				countries={ORDERED_FLAG_CODES}
				selected={getFlagCodeFromCode(i18n.language)}
				onSelect={handleChangeLanguage}
				showOptionLabel={false}
				showSelectedLabel={false}
			/>
		</Box>
	);
};

export default LanguageSwitcher;
