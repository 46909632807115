import Box from '@mui/material/Box';
import { TextArea } from '../../../Layout/Page';
import iconLinkedIn from '../../../assets/images/iconLinkedIn.svg';
import { openInNewTab } from '../../../features/navigation/utils';

const isEven = (n) => n % 2 === 0;

const XS_DIMENSION = '128px';
const MD_DIMENSION = '192px';

const Photo = ({ src }) => (
	<Box sx={{ width: { xs: XS_DIMENSION, md: MD_DIMENSION }, height: { xs: XS_DIMENSION, md: MD_DIMENSION } }}>
		<img src={src} alt="portrait photo" width="100%" height="100%" style={{ borderRadius: '50%' }} />
	</Box>
);

const PersonCard = ({ name, role, photo, linkedInUrl, index }) => {
	const isPhotoOnLeft = isEven(index);
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'row',
				justifyContent: { xs: 'space-between', md: isPhotoOnLeft ? 'flex-start' : 'flex-end' },
				alignItems: 'center',
				marginTop: '32px',
			}}>
			{isPhotoOnLeft && <Photo src={photo} />}
			<Box
				sx={{
					marginLeft: { md: isPhotoOnLeft ? '42px' : '0px' },
					marginRight: { md: isPhotoOnLeft ? '0px' : '42px' },
				}}>
				<TextArea text={name} bold topDivider="0px" />
				<TextArea text={role}  bottomDivider="6px" topDivider="0px" />
				<Box
					onClick={openInNewTab(linkedInUrl)}
					sx={{ width: { xs: '20px', md: '24px' }, height: { xs: '20px', md: '24px', cursor: 'pointer' } }}>
					<img src={iconLinkedIn} alt="linkedIn logo" width="100%" height="100%" />
				</Box>
			</Box>
			{!isPhotoOnLeft && <Photo src={photo} dimension={XS_DIMENSION} />}
		</Box>
	);
};

export default PersonCard;
