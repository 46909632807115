const LoopRealisation = () => {
	return (
		<div>
			<p>
				K fyzické výměně předmětů mezi účastníky potvrzeného řetězce akceptací dojde výlučně prostřednictvím služeb
				smluvního přepravce společnosti OLOOPO, a to dle volby dostupných způsobů přepravy. Prosím, vezměte na vědomí,
				že tato specifická přeprava je jednou z nutných podmínek využívání platformy a služeb OLOOPO.
			</p>
			<p>
				Přeprava vyměňovaných předmětů se řídí přepravními podmínkami smluvního přepravce OLOOPO. Tyto podmínky tvoří
				nedílnou součást těchto podmínek. Stanovují-li přepravní podmínky pro stanovení
			</p>
			<ul>
				<li>předmětů vyloučených z přepravy,</li>
				<li>hmotnostních, rozměrových a cenových limitů na přepravované předměty,</li>
				<li>požadavků na řádné zabalení a označení přepravovaných předmětů</li>
				<li>
					povinnosti související s podáním zásilky ze strany odesílajícího účastníka řetězce akceptací a s převzetím
					zásilky ze strany přijímajícího účastníka řetězce akceptací,
				</li>
				<li>
					omezení odpovědnosti za poškození nebo ztrátu zásilky ze strany přepravce a závazné postupy nahlašování a
					uplatňování nároků z odpovědnosti přepravce,
				</li>
				<li>
					povinnosti k úhradě jakýchkoli zvýšených nákladů z důvodů za které odpovídá odesílatel nebo příjemce, uplatní
					se tyto podmínky plně i na Vás.
				</li>
			</ul>
			<p>
				OLOOPO vyvine snahu Vás informovat o klíčových povinnostech souvisejících s přepravou vyměňovaných nákladů v
				rámci platformy.
			</p>
			<p>
				Vzniknou-li společnosti OLOOPO jakékoli náklady, škody nebo jiné újmy z důvodu porušení přepravních podmínek na
				Vaší straně, zavazujete se odškodnit OLOOPO v plném rozsahu, a to bez zbytečného odkladu po doručení výzvy
				společnosti OLOOPO k plnění.
			</p>
			<p>
				Odpovědnost společnosti OLOOPO vůči Vám vzniklá v důsledku vad přepravy, včetně zničení, poškození nebo ztráty
				zásilky, je omezená výší a podmíněná poskytnutím kompenzací smluvním přepravcem společnosti OLOOPO. Prostudujte
				si, prosím, uvedené přepravní podmínky a zvažte možné právní důsledky před jakoukoli svou akceptací nabídky
				předmětu k výměně.
			</p>
		</div>
	);
};

LoopRealisation.title = 'Jak dojde k realizaci výměny předmětů a jaké jsou Vaše povinnosti?';
LoopRealisation.id = 'loopRealisation';

export default LoopRealisation;
