import loopAnimation from '../../../../assets/images/loopAnimation500Optimize.gif';
import Typography from '@mui/material/Typography';
import { useTranslation } from '../../../../features/localization/hooks';

const AlgorithmSection = () => {
	const t = useTranslation('landingPage');
	return (
		<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', background: '#FFF' }}>
			<div style={{ overflow: 'hidden' }}>
				<img
					src={loopAnimation}
					alt={'loopAnimation'}
					style={{ width: '300px', height: '300px', margin: '64px 16px 0 16px' }}
				/>
			</div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					background: '#FFF',
				}}>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						padding: '24px 24px 24px 16px',
					}}>
					{/* TEXT SUB-SECTION */}
					<Typography
						sx={{
							fontFamily: 'KohinoorBangla',
							fontSize: '30px',
							fontWeight: 700,
							lineHeight: '45px',
							letterSpacing: '0em',
							textAlign: 'center',
							maxWidth: '264px',
						}}>
						{t('algorithmTitle')}
					</Typography>
				</div>
				<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
					<Typography
						sx={{
							fontFamily: 'KohinoorBangla',
							fontSize: '16px',
							fontWeight: 400,
							lineHeight: '24px',
							letterSpacing: '0em',
							textAlign: 'center',
							maxWidth: '267px',
						}}>
						{t('algorithmDescription')}
						<sup style={{ lineHeight: '15px', fontSize: '10px', verticalAlign: 'super' }}>{'1'}</sup>
					</Typography>
				</div>
			</div>
		</div>
	);
};

export default AlgorithmSection;
