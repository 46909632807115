import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import OloopoLogo from '../../common/Components/OloopoLogo/OloopoLogo';
import '../../assets/css/Header.css';
import { routes } from '../../features/navigation/constants';
import { useHandleNavigate } from '../../features/navigation/hooks';
import LanguageSwitcher from '../../features/localization/components/LanguageSwitcher';
import { useTranslation } from '../../features/localization/hooks';

const drawerWidth = '100%';

const navItems = [routes.ABOUT_US, routes.SUPPORT_AND_FAQ, routes.CAREERS];

const Header = ({ window, activeRoute = '' }) => {
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);

	const handleDrawerToggle = (/* EVENT */) => {
		setIsDrawerOpen((prevState) => !prevState);
	};

	const handleNavigate = useHandleNavigate(handleDrawerToggle);

	const t = useTranslation('common');

	const drawer = (
		<Box sx={{ textAlign: 'center' }}>
			<Box sx={{ display: 'flex', height: '74px', marginX: '16px', alignItems: 'center' }}>
				<OloopoLogo />
				<IconButton
					color="inherit"
					aria-label="close drawer"
					onClick={handleDrawerToggle}
					sx={{ mr: 2, display: { md: 'none' }, color: 'color.primary', marginRight: 0 }}>
					<CloseIcon />
				</IconButton>
			</Box>
			<List sx={{ marginLeft: '8px', marginTop: '16px' /* + 8px from list items */ }}>
				{navItems.map(({ label, route }) => (
					<ListItem key={label} disablePadding>
						<ListItemButton onClick={handleNavigate(route)} sx={{ textAlign: 'left' }}>
							<ListItemText>
								<Typography
									sx={{
										color: 'typography.default',
										fontFamily: 'KohinoorBangla',
										fontSize: '30px',
										fontWeight: 700,
										lineHeight: '45px',
										letterSpacing: '0em',
									}}>
									{t(`navigation.${label}`)}
								</Typography>
							</ListItemText>
						</ListItemButton>
					</ListItem>
				))}
				<ListItem>
					<Box sx={{ marginLeft: '-16px' }}>
						<LanguageSwitcher />
					</Box>
				</ListItem>
			</List>
		</Box>
	);

	const container = window !== undefined ? () => window().document.body : undefined;

	return (
		<Box sx={{ display: 'flex', height: '74px' }}>
			<CssBaseline />
			<AppBar component="nav" sx={{ backgroundColor: 'background.default', boxShadow: 'none' }}>
				<Toolbar sx={{ height: '74px' }}>
					<OloopoLogo />
					<IconButton
						color="inherit"
						aria-label="open drawer"
						edge="start"
						onClick={handleDrawerToggle}
						sx={{ mr: 2, display: { md: 'none' }, color: 'color.primary', marginRight: 0 }}>
						<MenuIcon />
					</IconButton>
					<Box sx={{ display: { xs: 'none', md: 'block' } }}>
						{navItems.map(({ label, route }) => (
							<Button
								onClick={handleNavigate(route)}
								key={label}
								sx={{
									paddingX: '12px',
									'&:hover': {
										textDecoration: 'underline',
										backgroundColor: 'transparent',
										textDecorationColor: 'black',
									},
								}}>
								<Typography
									sx={{
										fontFamily: 'KohinoorBangla',
										textTransform: 'none',
										color: 'typography.default',
										fontSize: '16px',
										fontWeight: activeRoute === route ? 700 : 400,
										lineHeight: '24px',
										letterSpacing: '0em',
										textAlign: 'left',
									}}>
									{t(`navigation.${label}`)}
								</Typography>
							</Button>
						))}
					</Box>
					<Box sx={{ display: { xs: 'none', md: 'inline' } }}>
						<LanguageSwitcher />
					</Box>
				</Toolbar>
			</AppBar>
			<Box component="nav">
				<Drawer
					container={container}
					variant="temporary"
					open={isDrawerOpen}
					onClose={handleDrawerToggle}
					ModalProps={{
						keepMounted: true, // Better open performance on mobile.
					}}
					sx={{
						display: { xs: 'block', md: 'none' },
						'& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
					}}>
					{drawer}
				</Drawer>
				<Toolbar />
			</Box>
		</Box>
	);
};

Header.propTypes = {
	activeRoute: PropTypes.string,
	/**
	 * Injected by the documentation to work in an iframe.
	 * You won't need it on your project.
	 */
	window: PropTypes.func,
};

export default Header;
