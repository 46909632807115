import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

const TitleWithCrossIcon = ({ title, onClose }) => (
	<Box
		sx={{ marginBottom: { xs: '8px', md: '16px' } }}
		display={'flex'}
		width={'100%'}
		flexDirection={'row'}
		justifyContent={'space-between'}
		alignContent={'center'}>
		<Typography
			sx={{
				color: 'typography.default',
				fontFamily: 'KohinoorBangla',
				fontSize: '22px',
				fontWeight: 700,
				lineHeight: '33px',
			}}>
			{title}
		</Typography>
		<IconButton
			sx={{
				color: 'black',
				marginTop: '-4px',
			}}
			onClick={onClose}>
			<CloseIcon />
		</IconButton>
	</Box>
);

TitleWithCrossIcon.propTypes = {
	title: PropTypes.string,
	onClose: PropTypes.func,
};

export default TitleWithCrossIcon;
