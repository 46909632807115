export default {
	claimDontBuy: 'Nekupujte.',
	claimOloopoIt: 'OLOOPOujte.',
	claimIntro: 'Získejte vysněný předmět výměnou za to, co už nepotřebujete, s využitím revoluční technologie.',
	downloadBtn: 'Stáhněte zdarma',
	algorithmTitle: 'Unikátní algoritmus.',
	algorithmDescription:
		'Majitel předmětu, po kterém toužíte, nemá zájem o výměnu za Vaši nabídku? Nevadí! Stačí,\
         aby měl zájem alespoň někdo a náš algoritmus se postará o zbytek.',
	citationOne: {
		text: '“Od Honzíkova jednoho roku mu nekupujeme boty, protože díky OLOOPO rostou společně s ním.”',
		name: 'Eduard a Zuzana, 32 let',
	},
	citationTwo: {
		text: '“Už přes rok jsem si nekoupila oblečení a přesto mám v šatníku stále nové kousky.”',
		name: 'Jana, 44 let',
	},
	citationThree: {
		text: '“Zaprášený obraz z půdy jsem vyměnil za nový iPhone.”',
		name: 'Tomáš, 16 let',
	},
	pleasureTitle: 'Udělejte si radost.',
	pleasureDescription:
		'Nový telefon, trendy módni kousek nebo třeba vstupenku na prestižní koncert získejte\
		výměnou za nepotřebnou věc. Už za 99Kč, a to včetně servisních poplatků, doručení a odeslání.',
	oloopoItBtn: 'Taky chci OLOOPOvat',
	adOne:
		'Pro úspěšnou výměnu je zapotřebí, aby se uzavřel loop (řetězec akceptací) \
        následně prošel schvalovacím procesem všemi zainteresovanými uživateli.',
	adTwo:
		'Cena 99 Kč platí pro výměnu předmětu v hmotnostní kategorii do 2.5 kg, \
		při doručení na výdejní místo a odeslání Vašeho předmětu z výdejního místa. \
        Za vyšší hmotnost doručovaného předmětu je příplatek v závislosti od hmotnosti. \
        Za doručení předmětu na Vaši adresu a/nebo vyzvednutí Vámi nabízeného předmětu kurýrem \
        na Vaší adrese je příplatek.',
};
