export const defaultValues = {
	STRING: '',
	BOOL: false,
	ARRAY: [],
};

// TODO: this is shitty name since real structure is in defaultCareersFormState :)
export const careersFormStructure = {
	generalInformation: {
		FULL_NAME: 'fullName',
		EMAIL: 'email',
		PHONE_NUMBER: 'phoneNumber',
		JOB_TYPE: 'jobType',
		jobTypeOptions: {
			FULL_TIME: 'fullTime',
			PART_TIME: 'partTime',
			INTERNSHIP: 'internship',
			SHORT_TERM: 'shortTerm',
		},
		DEPARTMENT: 'department',
		departmentOptions: {
			IT: 'it',
			FINANCE: 'finance',
			MARKETING: 'marketing',
			HR: 'hr',
			DESIGN: 'design',
			LEGAL: 'legal',
			OPERATIONS: 'operations',
		},
	},
	attachments: {
		LINK_TO_SHARE: 'linkToShare',
		ATTACHMENTS: 'attachments',
	},
	employerBrandingSurvey: {
		BRANDING_SURVEY: 'brandingSurvey',
		questions: {
			OLOOPO_RELATED: 'oloopoRelated',
			SOCIAL_MEDIA: 'socialMedia',
			MEDIA: 'media',
			JOB_PORTAL: 'jobPortal',
			SCHOOL: 'school',
			ADVERT: 'advert',
			OLOOPO_CONTRACT: 'oloopoContact',
			OTHER: 'other',
		},
	},
	RE_CAPTCHA_VALUE: 'reCaptchaValue',
};

export const defaultCareersFormState = {
	[careersFormStructure.generalInformation.FULL_NAME]: defaultValues.STRING,
	[careersFormStructure.generalInformation.EMAIL]: defaultValues.STRING,
	[careersFormStructure.generalInformation.PHONE_NUMBER]: defaultValues.STRING,
	[careersFormStructure.generalInformation.JOB_TYPE]: defaultValues.STRING,
	[careersFormStructure.generalInformation.DEPARTMENT]: defaultValues.STRING,
	[careersFormStructure.attachments.ATTACHMENTS]: defaultValues.ARRAY,
	[careersFormStructure.attachments.LINK_TO_SHARE]: defaultValues.STRING,
	[careersFormStructure.employerBrandingSurvey.BRANDING_SURVEY]: {
		[careersFormStructure.employerBrandingSurvey.questions.OLOOPO_CONTRACT]: defaultValues.BOOL,
		[careersFormStructure.employerBrandingSurvey.questions.SOCIAL_MEDIA]: defaultValues.BOOL,
		[careersFormStructure.employerBrandingSurvey.questions.MEDIA]: defaultValues.BOOL,
		[careersFormStructure.employerBrandingSurvey.questions.JOB_PORTAL]: defaultValues.BOOL,
		[careersFormStructure.employerBrandingSurvey.questions.SCHOOL]: defaultValues.BOOL,
		[careersFormStructure.employerBrandingSurvey.questions.ADVERT]: defaultValues.BOOL,
		[careersFormStructure.employerBrandingSurvey.questions.OLOOPO_CONTRACT]: defaultValues.BOOL,
		[careersFormStructure.employerBrandingSurvey.questions.OTHER]: defaultValues.BOOL,
	},
	[careersFormStructure.RE_CAPTCHA_VALUE]: null,
};

export const defaultValidationState = {
	[careersFormStructure.generalInformation.FULL_NAME]: null,
	[careersFormStructure.generalInformation.EMAIL]: null,
	[careersFormStructure.generalInformation.PHONE_NUMBER]: null,
	[careersFormStructure.attachments.LINK_TO_SHARE]: null,
};
