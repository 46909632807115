import React, { useState, cloneElement, Children } from 'react';
import Box from '@mui/material/Box';
import { assoc } from 'ramda';
import PropTypes from 'prop-types';

import TextField from '../../../common/Components/TextField';

import '../styles.css';
import { useTranslation } from '../../../features/localization/hooks';
import { FormHelperText } from '@mui/material';
import { careersFormStructure } from './careersFormStructure';

const ValidationInput = ({
	label,
	name,
	section,
	formData,
	formValidation,
	setFormValidation,
	onChange,
	marginTop,
	errorMessageResolver,
	isChildrenWithParentProps,
	children,
	translationLanguage,
	...others
}) => {
	const [isFocused, setIsFocused] = useState(false);
	const [wasBlurred, setWasBlurred] = useState(false);

	const t = useTranslation('careers');
	const validationT = useTranslation('validationMessage');

	const fieldData = formData[name];

	const handleBlur = () => {
		setIsFocused(false);
		setWasBlurred(true);
		setFormValidation((prevFormData) => {
			return assoc(name, errorMessageResolver(fieldData), prevFormData);
		});
	};

	const handleOnChange = (event) => {
		if (event.target) {
			onChange(event);
			setFormValidation((prevFormData) => {
				return assoc(name, errorMessageResolver(event.target.value), prevFormData);
			});
		} else {
			onChange(event);
			setFormValidation((prevFormData) => {
				return assoc(careersFormStructure.generalInformation.PHONE_NUMBER, errorMessageResolver(event), prevFormData);
			});
		}
	};

	const fieldProps = {
		id: `validation-input-${name}`,
		name,
		label: label || t(`form.${section}.${name}`),
		value: fieldData,
		onChange: errorMessageResolver && wasBlurred ? handleOnChange : onChange,
		fullWidth: true,
		error: errorMessageResolver && formValidation[name] && !isFocused,
		onFocus: errorMessageResolver ? () => setIsFocused(true) : null,
		onBlur: errorMessageResolver ? handleBlur : null,
		...others,
	};

	const childrenToRender = isChildrenWithParentProps
		? Children.map(children, (child) =>
				cloneElement(child, {
					...fieldProps,
				}),
		  )
		: children;

	return (
		<>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					borderRadius: '6px',
					marginTop,
					overflow: 'hidden',
				}}>
				{childrenToRender || <TextField {...fieldProps} />}
			</Box>
			{formValidation && formValidation[name] && wasBlurred && (
				<FormHelperText sx={{ marginLeft: '4px' }} error={true}>
					{validationT(formValidation[name], { lng: translationLanguage })}
				</FormHelperText>
			)}
		</>
	);
};

ValidationInput.propTypes = {
	label: PropTypes.string,
	name: PropTypes.string,
	section: PropTypes.string,
	formData: PropTypes.object,
	formValidation: PropTypes.object,
	setFormValidation: PropTypes.func,
	onChange: PropTypes.func,
	marginTop: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	errorMessageResolver: PropTypes.func,
	isChildrenWithParentProps: PropTypes.bool,
	translationLanguage: PropTypes.string,
	children: PropTypes.element,
};

export default ValidationInput;
