const WhoAreWe = () => {
	return (
		<div>
			<p>
				„OLOOPO“ nebo „my“ v těchto obchodních podmínkách znamená OLOOPO s.r.o., se sídlem v Praze, Hausmannova 3001/13,
				Modřany, 143 00, IČO: 17135061, DIČ: CZ17135061, společnost zapsaná v obchodním rejstříku vedeném Městským
				soudem v Praze, oddíl C, složka 391029.
			</p>
		</div>
	);
};

WhoAreWe.title = 'Kdo jsme?';
WhoAreWe.id = 'whoAreWe';

export default WhoAreWe;
