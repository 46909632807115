import Box from '@mui/material/Box';
import youtubePlayButton from '../../../assets/images/youtubePlayButton.png';
import PropTypes from 'prop-types';

import DurationTag from './DurationTag';

import { useBreakpoint } from '../../../mui/hooks';

const ThumbnailImage = ({ image, duration }) => {
	const isXs = useBreakpoint('xs');
	return (
		<Box
			sx={{
				position: 'relative',
				display: 'grid',
				borderRadius: '8px',
				overflow: 'hidden',
			}}>
			<Box
				sx={{
					position: 'absolute',
					right: { xs: '16px', md: '24px' },
					bottom: '16px',
				}}>
				<DurationTag duration={duration} />
			</Box>
			<Box
				sx={{
					position: 'absolute',
					alignSelf: 'center',
					justifySelf: 'center',
					'&:hover': {
						cursor: 'pointer',
					},
				}}>
				<img
					style={{
						justifySelf: 'center',
						alignSelf: 'center',
						transform: 'scale(0.14)',
					}}
					src={youtubePlayButton}
				/>
			</Box>
			<img
				style={{
					borderRadius: isXs ? 8 : 16,
					width: '100%',
					height: '100%',
				}}
				src={image}
			/>
		</Box>
	);
};

ThumbnailImage.propTypes = {
	image: PropTypes.string,
	duration: PropTypes.string,
};

export default ThumbnailImage;
