export default {
	price: {
		title: 'Kolik stojí používání služby OLOOPO?',
		content:
			'Stažení aplikace i její používání je zcela zdarma.\
            V případě úspěšné výměny platíte pouze za doručení a odeslání Vámi zvoleného předmětu.',
	},
	chance: {
		title: 'Jak zvýšit šanci na získání předmětů, o které mám zájem?',
		content:
			'Své šance zvýšíte kvalitním inzerátem Vašeho předmětu s dobře nasvícenými fotografiemi a v neposlední řadě\
			dobrým hodnocením Vašeho profilu. Jestli nemáte žádné hodnocení, můžete vyzkoušet službu OLOOPO Garance,\
			kdy po výměně získáte automaticky jedno 5* hodnocení. OLOOPO Garance zvýší atraktivitu Vašeho inzerátu.\
			Pokud na platformě nabízíte více než jeden předmět, můžete akceptovat předmět o který máte zájem\
			každým z nich (v případě, že hodnota každého z nich je dostatečná). Doporučujeme prohlédnout video\
			Jak udělat atraktivní inzerát v sekci Návody na naší webové stránce oloopo.com',
	},
	lowValue: {
		title:
			'Během prohlížení jsem našel předmět, o který mám velký zájem, avšak moje aktuální nabídka\
			není dostatečně atraktivní. Co dělat?',
		content:
			'Předmět, po kterém toužíte si můžete uložit do Oblíbených stisknutím hvězdičky. Potom můžete přidat\
			na OLOOPO jeden nebo více atraktivnějších předmětů a následně s každým z nich tento uložený předmět akceptovat.\
			Je to jednoduché, stačí na spodní liště vybrat sekci Akceptované a v ní přepnout na podsekci Oblíbené.\
			Zde můžete vybraný předmět akceptovat se všemi relevantními Vámi nabízenými předměty.',
	},
	guarantee: {
		title: 'Co je OLOOPO Garance a kdy se vyplatí ji zakoupit?',
		content:
			'OLOOPO Garance je služba ověření stavu Vašeho předmětu nezávislými experty. Po zakoupení služby\
			Vám e-mailem odešleme instrukce pro odeslání Vašeho předmětu na sklad OLOOPO. Zde bude do 2 pracovních dnů zkontrolován\
			jeho stav a věrohodnost popisu. Následně bude předmět uskladněn - až do jeho úspěšné výměny nebo Vaší žádosti o jeho navrácení.',
	},
	loop: {
		title: 'Výměna se uzavřela. Co dál?',
		content_1:
			'V případě, že máte pro Vámi nabízený předmět zakoupenou OLOOPO Garanci, nemusíte dělat nic, než čekat,\
			až Vám bude doručen Vámi vybraný předmět.',
		content_2:
			'V případě že nemáte pro Vámi nabízený předmět zakoupenu OLOOPO Garanci, musíte jej bezpečně zabalit a odeslat.\
			Instrukce pro odeslání Vám budou zaslány e-mailem. Stejné kroky provede majitel předmětu, který jste si vybrali a\
			Vámi zvolený předmět Vám bude následně doručen.',
	},
	item: {
		title: 'Co když mi nebyl zvolený předmět doručen?',
		content:
			'Každou výměnou je uzavřena série závazných kontraktů a každý účastník výměny má zákonnou povinnost\
			odeslat předmět, k jehož odeslání se zavázal. V případě, že tak neučiní, se dopouští přestupku vůči\
			trestnímu zákoníku a může být odsouzen na 2 roky vězení. Společnost OLOOPO bude v případě krádeže nápomocná\
			poškozenému zákazníkovi v kooperaci s policii ČR.',
	},
	maximalChance: {
		title: 'Jak maximalizovat šanci, že po realizaci výměny dostanu předmět, který odpovídá jeho skutečnému popisu?',
		content: 'Volte předměty se zakoupenou službou OLOOPO Garance nebo nabídky od zákazníků s vysokým hodnocením.',
	},
	increaseRating: {
		title: 'Jak můžu zvýšit své hodnocení?',
		content:
			'Ohodnotit Váš profil mohou jedině zákazníci, kteří od Vás po výměně obdrželi Vámi nabízený předmět.\
			Také si můžete zvýšit hodnocení zakoupením služby OLOOPO Garance. Po každé úspěšné výměně se zakoupenou\
			OLOOPO Garancí automaticky získáte jedno maximální hodnocení.',
	},
	lowerRaring: {
		title: 'Uživatel, kterému jsem odeslal/a předmět mi neprávem udělil nízké hodnocení. Co dělat?',
		content:
			'V tomto případě neprodleně kontaktujte naše zákaznické centrum. Pokud bude zjištěno, že daný uživatel\
			hodnotí pravidelně neobjektivně, budeme jej ihned kontaktovat. V krajních případech je možné uživatele\
			zablokovat, ať už dočasně nebo permanentně.',
	},
	limits: {
		title: 'Co když chci vyměnit předmět větší než dostupné rozměrové nebo hmotností limity?',
		content:
			'Přepravní limity jsou pevně stanoveny naší partnerskou přepravní společností. Předměty, které překračují\
			tyto limity, nelze přidat a už vůbec ne vyměnit. V případě přidání takového předmětu dochází k porušení\
			smluvních podmínek, kdy je třeba neprodleně kontaktovat naše zákaznické centrum.',
	},
	otherProblem: {
		title: 'Mám jiný problém.',
		content:
			'V případě, že zde není Váš problém popsán, kontaktujte naše zákaznícke centrum pomocí formuláře níže.\
			Budeme Vás kontaktovat v nejkratším možném čase.',
	},
	otherProblemForm: {
		name: 'Jméno',
		email: 'Email',
		description: 'Popis problému',
		submitForm: 'Odeslat dotaz',
	},
};
