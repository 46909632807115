export default {
	claimDontBuy: "Don't buy it.",
	claimOloopoIt: 'OLOOPO it.',
	claimIntro: 'Get your desired item in exchange for what you no longer need using revolutionary technology.',
	downloadBtn: 'Download for free',
	algorithmTitle: 'Unique algorithm.',
	algorithmDescription:
		'The owner of the object you desire is not interested in exchanging it for your offer? Never mind!\
		It is enough that at least someone is interested and our algorithm will take care of the rest.',
	citationOne: {
		text: "“We haven't bought shoes for Jonny since he was one year old, because thanks to OLOOPO,\
			they grow together with him.”",
		name: 'Eduard and Susie, 32',
	},
	citationTwo: {
		text: "“I haven't bought clothes in over a year and yet I still have new pieces in my wardrobe.”",
		name: 'Jenny, 44',
	},
	citationThree: {
		text: '“I exchanged the dusty painting from the attic for a new iPhone.”',
		name: 'Tom, 16',
	},
	pleasureTitle: 'Indulge yourself',
	pleasureDescription:
		'Get a new phone, a trendy piece of fashion or, for example, a ticket to a prestigious concert in exchange for\
		a redundant item. For just 99 CZK, including service fees, delivery and shipping.',
	oloopoItBtn: 'I want to OLOOPO too',
	adOne:
		'For a successful exchange, it is necessary to close the loop (chain of acceptances) followed by approvals\
		from all users involved in it.',
	adTwo:
		'The price of CZK 99 applies to the exchange of an item in the weight category of up to 2.5 kg, delivered to the\
		Parcel shop while dispatching your item from the Parcel shop. For a higher weight of the delivered item,\
		there is a surcharge depending on the weight. There is an additional charge for delivery of the item to your\
		address and/or pick-up of the item offered by you by courier at your address.',
};
