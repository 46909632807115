import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import PropTypes from 'prop-types';

const CustomAlert = ({ severity, title, description }) => (
	<Alert
		severity={severity}
		sx={{
			width: '100%',
			maxWidth: '700px',
			backgroundColor: severity === 'success' && 'background.success',
			color: severity === 'success' && 'typography.success',
		}}>
		<AlertTitle sx={{ fontWeight: 700 }}>{title}</AlertTitle>
		{description}
	</Alert>
);

CustomAlert.propTypes = {
	severity: PropTypes.string,
	title: PropTypes.string,
	description: PropTypes.string,
};

export default CustomAlert;
