import PropTypes from 'prop-types';
import MuiTextField from '@mui/material/TextField';

const TextField = ({ value, onChange, label, fieldId, sx = {}, ...others }) => {
	return (
		<MuiTextField
			sx={{
				backgroundColor: 'background.default',
				...sx,
				'& .MuiInputLabel-root:Mui-focused': {
					color: 'black !important',
				},
			}}
			InputLabelProps={{
				sx: {
					color: 'typography.gray',
					textTransform: 'capitalize',
					fontFamily: 'KohinoorBangla',
					fontSize: '16px',
					fontWeight: 400,
					letterSpacing: '0em',
					textAlign: 'left',
					'&.Mui-focused': { color: 'typography.gray' },
				},
			}}
			InputProps={{ disableUnderline: true }}
			inputProps={{
				sx: {
					backgroundColor: 'white',
					color: 'typography.default',
					fontFamily: 'KohinoorBangla',
					fontSize: '16px',
					fontWeight: 400,
				},
			}}
			id={`form-field-${fieldId}`}
			label={label}
			variant="filled"
			value={value}
			onChange={onChange}
			fullWidth
			{...others}
		/>
	);
};

TextField.propTypes = {
	onChange: PropTypes.func,
	fieldId: PropTypes.string,
	label: PropTypes.string,
	sx: PropTypes.object,
	value: PropTypes.any,
};

export default TextField;
