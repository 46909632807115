import { useTranslation } from '../../../../features/localization/hooks';

const MaximalChance = () => {
	const t = useTranslation('supportAndFaq');
	return (
		<div>
			<p>{t('maximalChance.content')}</p>
		</div>
	);
};

MaximalChance.title = 'supportAndFaq.maximalChance.title';
MaximalChance.id = 'maximalChance';

export default MaximalChance;
