import { createRef, useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { RE_CAPTCHA_SITE_KEY } from '../../../config';

const ReCaptcha = ({ onClickHandler, translationLanguage }) => {
	const { i18n } = useTranslation();
	const language = translationLanguage || i18n.language;
	const [recaptchaKey, setRecaptchaKey] = useState(0);
	const recaptchaRef = createRef();

	useEffect(() => {
		setRecaptchaKey((prevKey) => prevKey + 1);
		recaptchaRef.current.reset();
	}, [language]);

	return (
		<ReCAPTCHA
			ref={recaptchaRef}
			sitekey={RE_CAPTCHA_SITE_KEY}
			onChange={onClickHandler}
			key={recaptchaKey}
			hl={language}
		/>
	);
};

ReCaptcha.propTypes = {
	onClickHandler: PropTypes.func,
	translationLanguage: PropTypes.string,
};

export default ReCaptcha;
