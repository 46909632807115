import { useTranslation } from '../../../../features/localization/hooks';

const LowerRaring = () => {
	const t = useTranslation('supportAndFaq');
	return (
		<div>
			<p>{t('lowerRaring.content')}</p>
		</div>
	);
};

LowerRaring.title = 'supportAndFaq.lowerRaring.title';
LowerRaring.id = 'lowerRating';

export default LowerRaring;
