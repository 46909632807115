import Typography from '@mui/material/Typography';

const Citation = ({ text, name, md = false }) => (
	<div style={{ width: md ? '400px' : '264px' }}>
		<Typography
			sx={{
				fontFamily: 'KohinoorBangla',
				fontSize: md ? '22px' : '16px',
				fontWeight: 400,
				lineHeight: md ? '33px' : '24px',
				letterSpacing: '0em',
				textAlign: md ? 'left' : 'center',
			}}>
			{text}
		</Typography>
		<Typography
			sx={{
				fontFamily: 'KohinoorBangla',
				fontSize: md ? '22px' : '16px',
				fontWeight: 700,
				lineHeight: md ? '33px' : '24px',
				letterSpacing: '0em',
				textAlign: md ? 'left' : 'center',
				paddingTop: md ? '12px' : '8px',
			}}>
			{name}
		</Typography>
	</div>
);

export default Citation;
