import { join, o, values, path } from 'ramda';
import { careersFormStructure } from './components/careersFormStructure';
import bulletOne from '../../assets/images/1.png';
import bulletTwo from '../../assets/images/2.png';
import bulletThree from '../../assets/images/3.png';
import { languagesDefinitionMap } from '../../features/localization/constants';

const generateCollection = (collectionPath) => o(values, path(collectionPath))(careersFormStructure);

export const JOB_TYPES = generateCollection(['generalInformation', 'jobTypeOptions']);

export const EMPLOYER_BRANDING_SURVEY_OPTIONS = generateCollection(['employerBrandingSurvey', 'questions']);

export const DEPARTMENT_OPTIONS = generateCollection(['generalInformation', 'departmentOptions']);

export const allowedMimeTypes = {
	doc: 'application/msword',
	docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	pdf: 'application/pdf',
	ppt: 'application/vnd.ms-powerpoint',
	pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
	txt: 'text/plain',
	jpg: 'image/jpeg',
};

export const STRINGIFIED_ALLOWED_MIME_TYPES = o(join(','), values)(allowedMimeTypes);

export const MAX_FILE_SIZE = 7340032; // 7 MB ~ 10 MB as base64

export const MAX_FILE_COUNT = 10;

export const phoneNumberInput = {
	DEFAULT_COUNTRY: 'CZ',
	PREFERRED_COUNTRIES: ['CZ', 'SK'],
	EU_COUNTRIES: [
		'AT',
		'BE',
		'BG',
		'CY',
		'CZ',
		'DE',
		'DK',
		'EE',
		'ES',
		'FI',
		'FR',
		'GR',
		'HR',
		'HU',
		'IE',
		'IT',
		'LT',
		'LU',
		'LV',
		'MT',
		'NL',
		'PL',
		'PT',
		'RO',
		'SE',
		'SI',
		'SK',
	],
};

export const careersFormSubmittedAlert = {
	INFO: {
		title: 'Sending CV',
		description: 'Your form is being submitted.',
	},
	SUCCESS: {
		title: 'Your CV has been successfully submitted.',
		description:
			'Thank you for your interest in working at OLOOPO. In case we have a relevant position for you, or once it appears, we will contact you.',
	},
};

export const BULLETS = [
	{ key: 'bulletOne', img: bulletOne },
	{ key: 'bulletTwo', img: bulletTwo },
	{ key: 'bulletThree', img: bulletThree },
];

export const engLanguage = languagesDefinitionMap.EN.code;

export const APPLICANT_AND_CANDIDATE_FILE_NAME = 'Applicant_and_Candidate_Privacy_Notice.pdf';
