import loopAnimation from '../../../../assets/images/loopAnimation500Optimize.gif';
import Typography from '@mui/material/Typography';
import { useTranslation } from '../../../../features/localization/hooks';

const AlgorithmSectionMd = () => {
	const t = useTranslation('landingPage');
	return (
		<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', background: '#FFF' }}>
			<div style={{ overflow: 'hidden' }}>
				<img src={loopAnimation} alt={'loopAnimation'} style={{ width: '100%', paddingTop: '64px' }} />
			</div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					background: '#FFF',
					marginLeft: '200px',
				}}>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}>
					{/* TEXT SUB-SECTION */}
					<Typography
						sx={{
							fontFamily: 'KohinoorBangla',
							fontSize: '64px',
							fontWeight: 700,
							lineHeight: '96px',
							letterSpacing: '0em',
							textAlign: 'left',
							maxWidth: '400px',
						}}>
						{t('algorithmTitle')}
					</Typography>
				</div>
				<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', paddingTop: '40px' }}>
					<Typography
						sx={{
							fontFamily: 'KohinoorBangla',
							fontSize: '22px',
							fontWeight: 400,
							lineHeight: '33px',
							letterSpacing: '0em',
							textAlign: 'left',
							maxWidth: '400px',
						}}>
						{t('algorithmDescription')}
						<sup style={{ lineHeight: '21px', fontSize: '14px', verticalAlign: 'super' }}>{'1'}</sup>
					</Typography>
				</div>
			</div>
		</div>
	);
};

export default AlgorithmSectionMd;
