const PersonalDataSharing = () => {
	return (
		<div>
			<p>
				Vaše osobní údaje můžeme sdílet s třetími stranami, a to v rámci plnění jednotlivých účelů, které jsme popsali
				výše. V rámci transferu Vašich osobních údajů může dojít k jejich poskytnutí do třetích zemí, tj. mimo Evropské
				hospodářské společenství, přičemž zajistíme, že tento přenos bude realizován vždy za splnění podmínek
				stanovených v GDPR (General Data Protection Regulation), a při zajištění ochrany Vašich osobních údajů
			</p>
			<p>
				Vaše osobní údaje můžou být poskytnuty jinými správcům osobních údajů, kteří je budou zpracovávat k vlastním
				účelům a na základě vlastních právních titulů k jejich zpracování, např. Vaše osobní údaje budou v souvislosti s
				užíváním OLOOPO platformy a služeb poskytnuty jiným uživatelům nebo smluvnímu přepravci předmětu Vámi nabízeného
				a Vámi akceptovaného, které jsou součástí potvrzeného řetězce akceptací.
			</p>
			<p>
				Pokud nám udělíte souhlas prostřednictvím nastavení souborů cookie, budeme některé vaše údaje sdílet s našimi
				partnery pro marketingové účely.
			</p>
			<p>
				Více informací, včetně přehledu kategorií správců osobních údajů, s nimiž sdílíme Vaše osobní údaje, naleznete
				tady:
			</p>
			<table>
				<thead>
					<tr>
						<th width="50%">Správce osobních údajů</th>
						<th width="50%">Poskytovaná služba</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>GLS</td>
						<td>Smluvní přepravce</td>
					</tr>
					<tr>
						<td>Gpwebpay</td>
						<td>Platební brána</td>
					</tr>
					<tr>
						<td>Contabo GmbH</td>
						<td>Servery</td>
					</tr>
					<tr>
						<td>PORTAINER.IO LIMITED</td>
						<td>Portal pro správu microservice</td>
					</tr>
					<tr>
						<td>General Logistics Systems Czech Republic s.r.o.</td>
						<td>Přepava</td>
					</tr>
				</tbody>
			</table>
			<p>
				Mnoho úkonů, které činíme v rámci poskytování funkcionalit OLOOPO platformy a OLOOPO služeb, vyžadují zapojení
				našich externích partnerů, kteří Vaše osobní údaje zpracovávají pro nás, na naši odpovědnost, podle našich
				pokynů a výhradně pro naše účely a na základě právních titulů svědčících nám. Tito partneři vystupují v pozici
				zpracovatelů osobních údajů. Ujišťujeme Vás, že své zpracovatele zapojujeme do zpracovávání Vašich osobních
				údajů jen na základě dostatečných záruk, že dodržují zákonné požadavky a že budou vaše údaje vždy uchovávat v
				bezpečí.
			</p>
			<p>
				Více informací, včetně přehledu kategorií zpracovatelů osobních údajů, s nimiž sdílíme Vaše osobní údaje,
				naleznete tady:
			</p>
			<table>
				<thead>
					<tr>
						<th width="50%">Zpracovatel osobních údajů</th>
						<th width="50%">Poskytovaná služba</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>Google</td>
						<td>Přenos a uložiště dat</td>
					</tr>
					<tr>
						<td>Google</td>
						<td>Zpracování marketingových dat, analytika</td>
					</tr>
					<tr>
						<td>Google</td>
						<td>Data o výkonu marketingových kampaní</td>
					</tr>
					<tr>
						<td>Meta</td>
						<td>Data o výkonu marketingových kampaní</td>
					</tr>
					<tr>
						<td>Meta</td>
						<td>Data o návštěvnících webu a jejich chování</td>
					</tr>
					<tr>
						<td>TikTok</td>
						<td>Data o návštěvnících webu a jejich chování</td>
					</tr>
					<tr>
						<td>EcoMail</td>
						<td>Emailová komunikace</td>
					</tr>
					<tr>
						<td>Contabo GmbH</td>
						<td>Servery</td>
					</tr>
					<tr>
						<td>PORTAINER.IO LIMITED</td>
						<td>Portal pro správu microservice</td>
					</tr>
					<tr>
						<td>General Logistics Systems Czech Republic s.r.o.</td>
						<td>Přepava</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
};

PersonalDataSharing.title = 'S kým a proč sdílíme Vaše osobní údaje?';
PersonalDataSharing.id = 'personalDataSharing';

export default PersonalDataSharing;
