import { useContext } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { MuiTelInput } from 'mui-tel-input';

import ValidationInput from './ValidationInput';
import FormTile from './FormTile';
import SelectForm from './SelectForm';

import { useTranslation } from '../../../features/localization/hooks';
import { careersFormStructure } from './careersFormStructure';
import { useSelectOptions } from '../hooks';
import {DEPARTMENT_OPTIONS, engLanguage, JOB_TYPES} from '../constant';
import { phoneNumberInput } from '../constant';
import {
	GET_EMAIL_ERROR_MESSAGE,
	GET_PHONE_NUMBER_ERROR_MESSAGE,
	GET_REQUIRED_ERROR_MESSAGE,
} from '../../../features/validations/validations';
import { CareersContext } from '../../../store/CareersContextProvider';

const validationInputDivider = { xs: '16px', md: '24px' };
const selectFormDivider = { marginBottom: { md: '8px' } };

const GeneralInformation = () => {
	const { formData, formValidation, handleInputChange, setFormValidation } = useContext(CareersContext);
	const t = useTranslation('careers');
	const departmentOptions = useSelectOptions(DEPARTMENT_OPTIONS, 'form.generalInformation.departmentOptions');
	const jobTypes = useSelectOptions(JOB_TYPES, 'form.generalInformation.jobTypeOptions');

	return (
		<FormTile title={t('form.generalInformation.title')} titleDescription={' (required)'}>
			<Box paddingY={'16px'} component="form" noValidate autoComplete="off">
				<ValidationInput
					translationLanguage={engLanguage}
					name={careersFormStructure.generalInformation.FULL_NAME}
					section={'generalInformation'}
					formData={formData}
					formValidation={formValidation}
					setFormValidation={setFormValidation}
					onChange={handleInputChange}
					errorMessageResolver={GET_REQUIRED_ERROR_MESSAGE}
				/>
				<ValidationInput
					translationLanguage={engLanguage}
					marginTop={validationInputDivider}
					name={careersFormStructure.generalInformation.EMAIL}
					section={'generalInformation'}
					formData={formData}
					formValidation={formValidation}
					setFormValidation={setFormValidation}
					onChange={handleInputChange}
					errorMessageResolver={GET_EMAIL_ERROR_MESSAGE}
				/>
				<ValidationInput
					translationLanguage={engLanguage}
					isChildrenWithParentProps
					marginTop={validationInputDivider}
					name={careersFormStructure.generalInformation.PHONE_NUMBER}
					section={'generalInformation'}
					formData={formData}
					formValidation={formValidation}
					setFormValidation={setFormValidation}
					onChange={handleInputChange}
					errorMessageResolver={GET_PHONE_NUMBER_ERROR_MESSAGE}>
					<MuiTelInput
						focusOnSelectCountry
						defaultCountry={phoneNumberInput.DEFAULT_COUNTRY}
						preferredCountries={phoneNumberInput.PREFERRED_COUNTRIES}
						onlyCountries={phoneNumberInput.EU_COUNTRIES}
						InputLabelProps={{
							sx: {
								marginLeft: '50px',
								marginTop: '6px',
								color: 'typography.gray',
								textTransform: 'capitalize',
								fontFamily: 'KohinoorBangla',
								fontSize: '16px',
								fontWeight: 400,
								letterSpacing: '0em',
								textAlign: 'left',
								'&.Mui-focused': { color: 'typography.gray' },
							},
						}}
						variant={'standard'}
						InputProps={{ disableUnderline: true }}
						sx={{
							width: '100%',
							border: 'none',
							height: '56px',
							paddingLeft: '4px',
							backgroundColor: 'typography.white',
						}}
					/>
				</ValidationInput>
				<Divider orientation={'vertical'} sx={selectFormDivider} />
				<SelectForm
					options={jobTypes}
					formData={formData}
					name={careersFormStructure.generalInformation.JOB_TYPE}
					section={'generalInformation'}
					onChange={handleInputChange}
				/>
				<Divider orientation={'vertical'} sx={selectFormDivider} />
				<SelectForm
					options={departmentOptions}
					formData={formData}
					name={careersFormStructure.generalInformation.DEPARTMENT}
					section={'generalInformation'}
					onChange={handleInputChange}
				/>
			</Box>
		</FormTile>
	);
};

export default GeneralInformation;
