import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import OloopoLogo from '../../common/Components/OloopoLogo/OloopoLogo';
import { openInNewTab } from '../../features/navigation/utils';
import { useLocalizedDownloadAppOptions } from '../../features/localization/hooks';
import { SOCIAL_MEDIA_MENU_ITEMS } from './constants';

const buttonStyle = { marginX: '8px', padding: 0, minWidth: 0 };
const iconStyle = { height: '20px' };

const FooterLinksMd = () => {
	const downloadAppOptions = useLocalizedDownloadAppOptions();
	return (
		<Box
			sx={{
				paddingTop: '32px',
				padding: 0,
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'flex-start',
				justifyContent: 'space-between',
				width: '300px',
			}}>
			<OloopoLogo inverted />
			<Box sx={{ height: '16px' }} />
			{downloadAppOptions.map(({ code, link, icon, heightRatio }) => {
				const width = 100;
				return (
					<Button onClick={openInNewTab(link)} key={code} sx={{ ...buttonStyle, width }}>
						<img style={{ width }} src={icon} alt={code} />
					</Button>
				);
			})}
			<Box sx={{ paddingTop: '16px', paddingX: 0, alignItems: 'center' }}>
				{SOCIAL_MEDIA_MENU_ITEMS.map(({ code, link, icon }) => (
					<Button onClick={openInNewTab(link)} key={code} sx={buttonStyle}>
						<img style={iconStyle} src={icon} alt={code} />
					</Button>
				))}
			</Box>
		</Box>
	);
};

export default FooterLinksMd;
