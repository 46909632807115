import { compose, includes, nth, split, cond, always, T } from 'ramda';
import { baseUrls, envSuffixes } from '../../config';
import { platforms } from './constants';

export const openInNewTab = (url) => (event) => {
	event.stopPropagation();
	window.open(url, '_blank');
};

/**
 * Retrieve the host platform in a
 * best-effort way w/ normalized output.
 */
export const getAgentSystem = () => {
	if (!('navigator' in window)) {
		return platforms.UNKNOWN;
	}

	// Use the modern 'web hints' provied by
	// 'userAgentData' if available, else use
	// the deprecated 'platform' as fallback.
	const platform = (navigator.userAgentData?.platform || navigator.platform)?.toLowerCase();

	if (platform.startsWith('android')) {
		return platforms.ANDROID;
	} else if (platform.startsWith('ip')) {
		/* 'iP' since we operate with iPad, iPhone, iPod */
		return platforms.APPLE;
	} else {
		return platforms.UNKNOWN;
	}
};

const parseEnv = (env) => compose(includes(env), nth(1), split('://'));

const isDev = process.env.NODE_ENV === 'development';

const isDevEnv = (origin) => parseEnv(envSuffixes.DEV)(origin);
// TODO: when we switch dev, move isDev to isDevEnv instead
const isTestEnv = (origin) => isDev || parseEnv(envSuffixes.TEST)(origin);

export const resolveBaseUrl = cond([
	[isDevEnv, always(baseUrls.DEV)],
	[isTestEnv, always(baseUrls.TEST)],
	[T, always(baseUrls.PROD)],
]);
