const UserAccountCancellation = () => {
	return (
		<div>
			<p>
				Kdykoli můžete prostřednictvím OLOOPO platformy zrušit svůj uživatelský účet, a to s třicetidenní výpovědní
				lhůtou. Je-li s Vaším účtem spojen aktivní řetězec akceptací nebo po dobu výpovědní lhůty budete svůj
				uživatelský účet aktivně užívat, bude tato skutečnost považována za Vaše neprodlené odvolání zrušení
				uživatelského účtu.
			</p>
			<p>
				OLOOPO si vyhrazuje právo odmítnout Vaši registraci, resp. Váš uživatelský účet dočasně pozastavit nebo trvale
				zrušit a neumožnit založení nového uživatelského účtu v případě, že OLOOPO má rozumné důvody se domnívat, že
				Vaše prohlášení uvedené výše je nebo se stalo nepravdivým. O této skutečnosti budete vhodně vyrozuměny. OLOOPO
				si rovněž vyhrazuje právo k jakýmkoli dalším zákonným krokům v souvislosti s protiprávním jednáním realizovaným
				na platformě nebo v souvislosti se službami OLOOPO, včetně podání podnětu a následné spolupráce s příslušnými
				veřejnými orgány.
			</p>
			<p>
				OLOOPO si vyhrazuje Váš uživatelský účet dočasně pozastavit, trvale zrušit, nebo jej transformovat v jinou formu
				personalizovaného přístup k službám OLOOPO v případě změny platformy nebo služeb OLOOPO nebo trvalé neaktivity
				na Vašem účtu, na tuto skutečnost budete s dostatečným předstihem upozorněni.
			</p>
		</div>
	);
};

UserAccountCancellation.title = 'Může být Váš uživatelský účet zrušen nebo pozastaven?';
UserAccountCancellation.id = 'userAccountCancellation';

export default UserAccountCancellation;
