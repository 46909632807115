import Typography from '@mui/material/Typography';
import ButtonPrimary from '../../../../common/Components/ButtonPrimary';
import { openInNewTab } from '../../../../features/navigation/utils';
import { useTranslation, useLocalizedAppExampleImg } from '../../../../features/localization/hooks';

const underLineStyles = {
	fontFamily: 'KohinoorBangla',
	fontSize: '12px',
	fontWeight: 400,
	lineHeight: '24px',
	letterSpacing: '0em',
	textAlign: 'left',
	color: '#5E7181',
};

const UnderLine = ({ text }) => <Typography sx={underLineStyles}>{text}</Typography>;

const PleasureSectionMd = () => {
	const t = useTranslation('landingPage');
	const appExample = useLocalizedAppExampleImg();
	return (
		<div style={{ background: '#FFF', paddingTop: '64px' }}>
			<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						overflowX: 'hidden',
						padding: '0 16px',
						width: '50%',
					}}>
					<img src={appExample} alt={'appExample'} style={{ width: '100%', height: 'auto' }} />
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'flex-start',
						width: '50%',
						paddingLeft: '64px',
						background: '#FFF',
					}}>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
						}}>
						<Typography
							sx={{
								fontFamily: 'KohinoorBangla',
								fontSize: '64px',
								fontWeight: 700,
								lineHeight: '96px',
								letterSpacing: '0em',
								textAlign: 'left',
								maxWidth: '282px',
							}}>
							{t('pleasureTitle')}
						</Typography>
					</div>
					<div style={{ paddingTop: '40px' }}>
						<Typography
							sx={{
								fontFamily: 'KohinoorBangla',
								fontSize: '22px',
								fontWeight: 400,
								lineHeight: '33px',
								letterSpacing: '0em',
								textAlign: 'left',
								maxWidth: '534px',
							}}>
							{t('pleasureDescription')}
							<sup style={{ lineHeight: '21px', fontSize: '14px', verticalAlign: 'super' }}>{'2'}</sup>
						</Typography>
					</div>
					<div
						style={{
							width: '344px',
							paddingTop: '54px',
						}}>
						<ButtonPrimary label={t('oloopoItBtn')} onClick={openInNewTab('http://tst.oloopo.com:8080')} />
					</div>
				</div>
			</div>
			<div style={{ padding: '64px 16px 40px 16px' }}>
				<ol style={{ paddingLeft: '16px', margin: 0 }}>
					{[t('adOne'), t('adTwo')].map((text, index) => (
						<li key={index} style={{ ...underLineStyles, ...(index !== 0 && { marginTop: '8px' }) }}>
							<UnderLine text={text} />
						</li>
					))}
				</ol>
			</div>
		</div>
	);
};

export default PleasureSectionMd;
