import Container from '@mui/material/Container';
import Header from '../Header';
import Footer from '../Footer';

const Page = ({ children, activeRoute }) => (
	<>
		<Container disableGutters maxWidth="lg">
			<Header activeRoute={activeRoute} />
			{children}
		</Container>
		<Footer />
	</>
);

export default Page;
