import Page from '../../Layout/Page';
import ScrollablePage from './components/ScrollablePage';

const LandingPage = () => {
    return (
        <Page>
            <ScrollablePage />
        </Page>
    );
};

export default LandingPage;
