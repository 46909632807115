export default {
	title: 'Ochranna osobních údajů',
	cookieSettings: 'Nastavení cookies',
	disclaimer: {
		textPartOne:
			'Soubory cookie a podobné technologie používáme k zajištění lepší funkčnosti. Kliknutím na Ok nebo zapnutím možnosti v sekci Nastavení cookies souhlasíte s tím, co je uvedeno v našich ',
		textLink: 'Zásadách používaní cookies.',
		textPartTwo:
			' Chcete-li změnit preference nebo odvolat souhlas, aktualizujte své nastavení v sekci Nastavení cookies.',
	},
	category: {
		essential: {
			title: 'Základní',
			description: 'Tyto položky jsou nezbytné pro fungování webových stránek.',
		},
		marketing: {
			title: 'Marketing',
			description:
				'Tyto položky pomáhají poskytovat reklamu, která je pro vás relevantnější. Mohou být také použity k omezení počtu zobrazení reklamy a k měření účinnosti reklamních kampaní. Jedná se o soubory cookie třetích stran poskytované našimi reklamními partnery.',
		},
		personalization: {
			title: 'Personalizace',
			description:
				'Tyto položky umožňují webové stránce zapamatovat si vaše volby (například uživatelské jméno, jazyk nebo oblast) a přizpůsobit vám funkce. Můžeme vám například zobrazovat různý obsah v závislosti na tom, které z našich stránek jste navštívili a jaký obsah jste četli.',
		},
		analytics: {
			title: 'Analytika',
			description:
				'Tyto položky nám pomáhají porozumět interakcím návštěvníků, měřit výkonnost webových stránek a odhalit případné technické problémy. Nepatří sem základní soubory cookie, které poskytují náhodnou analýzu při fungování webových stránek.',
		},
	},
	enableAll: 'Povolit vše',
	disableAll: 'Odmítnout vše',
	settingsSave: 'Uložit nastavení',
};
