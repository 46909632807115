const ProcessingPersonalDataContacting = () => {
	return (
		<div>
			<p>
				V případě dotazů týkajících se ochrany Vašeho soukromí a osobních údajů, nahlášení jakéhokoli podezření z našeho
				porušení těchto zásad nebo právní úpravy ochrany osobních dat nebo incidentu ohrožujícího ochranu Vašich
				osobních údajů, jakož i v případě uplatnění práv souvisejících s Vašimi osobními údaji nás kontaktuje
				prostřednicím Vašeho uživatelského účtu v rámci OLOOPO platformy nebo kontaktního formuláře na našich stránkách.
				Prosím, vezměte na vědomí, že pro účely uplatnění práv souvisejících s Vašimi osobními údaji můžeme vyžadovat
				verifikaci Vaší identity, a to z bezpečnostních důvodů.
			</p>
		</div>
	);
};

ProcessingPersonalDataContacting.title =
	'Jak nás můžete kontaktovat v souvislosti s naším zpracováváním Vašich osobních údajů?';
ProcessingPersonalDataContacting.id = 'processingPersonalDataContacting';

export default ProcessingPersonalDataContacting;
