import { useTranslation } from '../../features/localization/hooks';

export const useSelectOptions = (options, localizationPath) => {
	const t = useTranslation('careers');
	return options.map((option, index) => ({
		id: index,
		label: t(`${localizationPath}.${option}`),
		value: option,
	}));
};
