import { useCallback } from 'react';
import { compose, toUpper } from 'ramda';
import { dotPath } from 'ramda-extension';
import { useTranslation as _useTranslation } from 'react-i18next';
import { localizedAppExampleImages, localizedBadges } from './constants';
import { parseGenericLanguageCode } from './utils';

const languageCode = compose(toUpper, parseGenericLanguageCode, dotPath('i18n.language'));

export const useTranslation = (localizationFileName) => {
	const { t: _t } = _useTranslation();
	return useCallback((key, options = {}) => _t(`${localizationFileName}.${key}`, options), [localizationFileName, _t]);
};

const useLocalizedBadges = () => {
	const language = languageCode(_useTranslation());
	return localizedBadges[language];
};

export const useLocalizedDownloadAppOptions = () => {
	const { downloadAppStore, downloadGooglePlay } = useLocalizedBadges();
	return [
		{
			code: 'appStore',
			link: '',
			icon: downloadAppStore,
			heightRatio: 1,
		},
		{
			code: 'googlePlay',
			link: '',
			icon: downloadGooglePlay,
			heightRatio: 1.25,
		},
	];
};

export const useLocalizedAppExampleImg = () => {
	const language = languageCode(_useTranslation());
	return localizedAppExampleImages[language];
};
