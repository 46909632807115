import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import styles from './styles';

const ButtonPrimary = ({ label, disabled = false, onClick, maxWidth = '344px', children }) => {
	return (
		<Button
			sx={{
				...styles.buttonPrimary,
				...(disabled && styles.buttonDisabled),
				...styles.buttonHover,
			}}
			style={{ maxWidth }}
			onClick={onClick}
			disabled={disabled}
			disableElevation={true}
			variant="contained"
			component="label">
			{children}
			<Typography
				sx={{
					fontFamily: 'KohinoorBangla',
					fontWeight: 700,
					fontSize: '16px',
					lineHeight: '24px',
					textAlign: 'center',
					textTransform: 'none',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}>
				{label}
			</Typography>
		</Button>
	);
};

ButtonPrimary.propTypes = {
	disabled: PropTypes.bool,
	label: PropTypes.string,
	onClick: PropTypes.func,
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	children: PropTypes.element,
};

export default ButtonPrimary;
