export default {
	title: 'Návody',
	videos: {
		dummyTitle1: 'Dummy název 1',
		dummyTitle2: 'Dummy název 2',
		dummyTitle3: 'Dummy název 3',
		dummyTitle4: 'Dummy název 4',
		dummyTitle5: 'Dummy název 5',
		dummyTitle6: 'Dummy název 6',

		dummyTitleLong: 'Dummy název consectetuer adipiscing elit. Sed vel lectus. Donec odio tempus molestie,',
		dummyDescription:
			'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Sed vel lectus. Donec odio tempus molestie, porttitor ut, iaculis quis, sem. Etiam neque. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Aliquam erat volutpat. ',
	},
};
