export default {
	navigation: {
		aboutUs: 'About us',
		supportAndFaq: 'Customer support',
		careers: 'Careers',
		tutorials: 'Tutorials',
		investors: 'Investors',
		privacy: 'Privacy Policy',
		termsAndConditions: 'Terms and conditions',
	},
	footer: {
		company: 'Company',
		support: 'Support',
		copyRight: '© OLOOPO s.r.o. {{currentYear}}. All rights reserved.',
	},
	currentVersion: 'Current version',
	legacyVersion: 'Previous versions',
};
