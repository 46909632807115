import Box from '@mui/material/Box';
import '../../assets/css/Footer.css';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import { useHandleNavigate } from '../../features/navigation/hooks';
import { useTranslation } from '../../features/localization/hooks';

const FootFooterMenu = ({ navItems = [], title, md = false }) => {
	const handleNavigate = useHandleNavigate();
	const t = useTranslation('common');
	return (
		<Box sx={{ marginTop: md ? '0px' : '40px' }}>
			<Typography
				sx={{
					color: 'rgba(255, 255, 255, 0.5)',
					fontFamily: 'KohinoorBangla',
					fontSize: md ? '22px' : '18px',
					fontWeight: 700,
					lineHeight: md ? '33px' : '27px',
					letterSpacing: '0em',
					textAlign: 'left',
				}}>
				{title}
			</Typography>
			<List sx={{ margin: 0, padding: 0 }}>
				{navItems.map(({ label, route }) => (
					<ListItem key={label} disablePadding>
						<ListItemButton
							onClick={handleNavigate(route)}
							sx={{
								textAlign: 'left',
								margin: '16px 0 0 0',
								padding: 0,
								'&:hover': {
									textDecoration: 'underline',
									backgroundColor: 'transparent',
									textDecorationColor: 'white',
								},
							}}>
							<ListItemText sx={{ margin: 0, padding: 0 }}>
								<Typography
									sx={{
										color: 'typography.white',
										fontFamily: 'KohinoorBangla',
										fontSize: '16px',
										fontWeight: 400,
										lineHeight: '24px',
										letterSpacing: '0em',
										textAlign: 'left',
									}}>
									{t(`navigation.${label}`)}
								</Typography>
							</ListItemText>
						</ListItemButton>
					</ListItem>
				))}
			</List>
		</Box>
	);
};

export default FootFooterMenu;
