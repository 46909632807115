export default {
	navigation: {
		aboutUs: 'O nás',
		supportAndFaq: 'Zákaznická podpora',
		careers: 'Careers',
		tutorials: 'Návody',
		privacy: 'Zásady ochrany soukromí',
		termsAndConditions: 'Obchodní podmínky',
	},
	footer: {
		company: 'Společnost',
		support: 'Podpora',
		copyRight: '© OLOOPO s.r.o. {{currentYear}}. Všechna práva vyhrazena.',
	},
	currentVersion: 'Aktuální verze',
	legacyVersion: 'Předcházejíci verze',
};
