import './PrivacyDataUsage.css';

const PrivacyDataUsage = () => {
	return (
		<div>
			<p>Veškeré Vaše osobní údaje jsou společností OLOOPO využívány k následujícím účelům:</p>
			<table>
				<thead>
					<tr>
						<th width="60%">Účel</th>
						<th width="20%">Kategorie osobních údajů</th>
						<th width="20%">Právní titul</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							Poskytování funkcí OLOOPO Platformy – pro účely poskytování omezených funkcionalit OLOOPO platformy je
							nutná uživatelská registrace (a verifikace) a k využívaní všech základních funkcionalit i registrace
							nabídky předmětu. Uživatelskou registraci je možné realizovat i prostřednictvím služeb třetích stran, o
							nich na základě Vaší volby obdržíme potřebné informace. V důsledku Vašeho požadavku je rovněž možné uložit
							platební údaje u našeho poskytovatele platebních služeb po budoucí použití pro nákup OLOOPO služeb. Rovněž
							pro řádné fungování naší platformy potřebujeme i údaje o zařízeních a metadata. Na základě Vaše volby
							můžeme zpracovávat údaje o Vašich preferencích za účelem personalizovaného poskytování funkcionalit OLOOPO
							Platformy
						</td>
						<td>
							IDENTIFIKAČNÍ ÚDAJE
							<br />
							KONTAKTNÍ ÚDAJE
							<br />
							PLATEBNÍ ÚDAJE
							<br />
							REGISTRAČNÍ ÚDAJE
							<br />
							PREFERENCE
							<br />
							ÚDAJE O POLOZE
						</td>
						<td>
							Plnění smlouvy
							<br />
							<br />
							Oprávněný zájem OLOOPO - obchodní činnost
							<br />
							<br />
							Souhlas
						</td>
					</tr>
					<tr>
						<td>
							Poskytování OLOOPO služeb – v rámci využívání OLOOPO Platformy se můžete rozhodnout pro akceptaci některé
							z nabídek výměny předmětu jiného uživatele a v případě dojde-li k vytvoření a potvrzení řetězce akceptací,
							budou Vaše osobní údaje použité v rámci zprostředkování smluv tvořících řetězce výměn mezi participujícími
							uživateli a zajištění související přepravy předmětů mezi uživateli prostřednictvím smluvního přepravce,
							resp. k úhradě těchto služeb. Doručovací údaje tak budou sdíleny s přepravcem a odesílajícím uživatelem
							Současně si můžete objednat doplňkové služby OLOOPO, a to dle nabídky v rámci Platformy. V případě sporu
							mezi uživateli můžeme zprostředkovat vzájemnou komunikaci a v případě neúspěchu nám každý uživatel dává
							svůj souhlas sdílet jeho identifikační údaje potřebné pro uplatnění práv údajně ním poškozeného uživatele.
						</td>
						<td>
							IDENTIFIKAČNÍ ÚDAJE
							<br />
							KONTAKTNÍ ÚDAJE
							<br />
							PLATEBNÍ ÚDAJE
							<br />
							REGISTRAČNÍ ÚDAJE
							<br />
							KOMUNIKACE
							<br />
							TRANSAKČNÍ ÚDAJE
							<br />
							ÚDAJE O POLOZE
						</td>
						<td>
							Plnění smlouvy
							<br />
							<br />
							Oprávněný zájem OLOOPO – obchodní činnost
							<br />
							<br />
							Souhlas
						</td>
					</tr>
					<tr>
						<td>
							Vývoj produktů, zlepšování služeb a rozvoj obchodní činnosti – abychom mohli zlepšovat OLOOPO platformu a
							služby nebo činit podnikatelská nebo obchodná rozhodnutí týkající se našeho podnikání potřebujeme
							shromažďovat a analyzovat údaje o způsobu, četnosti a ekonomických ukazatelích Vašeho využívání OLOOPO
							služeb.
						</td>
						<td>
							RANSAKČNÍ ÚDAJE
							<br />
							RECENZE A ZPĚTNÁ VAZBA
						</td>
						<td>Oprávněný zájem OLOOPO – rozvoj produktu a obchodní činnost</td>
					</tr>
					<tr>
						<td>
							Prevence podvodů – v rámci běžné činnosti budeme vyhodnocovat obsah zpětné vazby uživatelů i základní
							analytické informace o chování uživatelů s cílem identifikovat zneužití OLOOPO platformy nebo služeb k
							podvodnému, nekalému, nebo jinému protiprávnému jednání. Rovněž v souvislosti s platbami realizovanými v
							rámci OLOOPO platformy bude realizovaná běžná kontrola (interně nebo externě) možného zneužití platebních
							údajů, přičemž tyto údaje můžou být sdíleny s verifikující třetí stranou. V této souvislosti mohou být
							využity Vaše identifikační a kontaktní údaje za účelem dodatečné verifikace Vaší platby a její
							oprávněnosti
						</td>
						<td>
							IDENTIFIKAČNÍ ÚDAJE
							<br />
							KONTAKTNÍ ÚDAJE
							<br />
							PLATEBNÍ ÚDAJE
							<br />
							REGISTRAČNÍ ÚDAJE
							<br />
							KOMUNIKACE
							<br />
							TRANSAKČNÍ ÚDAJE
							<br />
							RECENZE A ZPĚTNÁ VAZBA
							<br />
							ÚDAJE O POLOZE
						</td>
						<td>Oprávněný zájem OLOOPO – prevence podvodů</td>
					</tr>
					<tr>
						<td>
							Shromažďování uživatelské zpětné vazby – pro účely rozvoje naší činnosti, marketingové účely a analytické
							účely můžeme požádat o poskytnutí zpětné vazby (i prostřednictvím kontaktních údajů) a poskytnutou zpětnou
							vazbu (i tu poskytnutou prostřednictvím třetích stran) vhodně využívat
						</td>
						<td>
							KONTAKTNÍ ÚDAJE
							<br />
							RECENZE A ZPĚTNÁ VAZBA
						</td>
						<td>Oprávněný zájem OLOOPO – rozvoj produktu a obchodní činnost, marketing</td>
					</tr>
					<tr>
						<td>
							<p>
								Přímý marketing a online reklama – Vzhledem k tomu, že součástí naší podnikatelské činnosti je i
								marketingová komunikace a prezentace, budeme Vaše osobní údaje zpracovávat pro účely přímého marketingu
								(přímé nabídky zasílané prostřednictvím kontaktních údajů nebo zobrazovaných na OLOOPO platformě a
								související zpracování údajů), abychom vám mohli poskytovat nejvhodnější a nejrelevantnější nabídky.
								Naše přímá marketingová komunikace bude vždy v souladu s právní předpisy regulujícími e-commerce. Kromě
								vašich kontaktních údajů zpracováváme i Vaše transakční údaje a údaje o preferencích (poskytnete-li nám
								je), které nám pomáhají segmentovat zákazníky a připravovat nabídky přímo na míru. Můžeme například
								připravit speciální nabídku přímo pro Vás na základě Vašich předchozích interakcí (i nedokončených) na
								OLOOPO platformě a/nebo Vašich preferencích.
							</p>
							<p>
								V rámci naší marketingové činnosti máme zájem zobrazovat Vám v rámci OLOOPO platformy nebo mimo ni
								personalizovanou reklamu propagující služby OLOOPO nebo služby a produkty třetích stran. Za tímto účelem
								budeme zpracovávat Vaše osobní údaje umožňující identifikovat Vaše osobní preference. Tyto osobní údaje
								můžou být využity i prostřednictvím marketingových partnerů jako affiliate sítě zobrazující relevantní
								reklamy na různých místech na internetu nebo Facebook a jiné sociální platformy zobrazující reklamy v
								rámci svých rozhraní, a to i za použití funkce lookalike publika“ umožňující na základě podobností
								osobních profilů uživatelů aproximovat podobné zájmy a přizpůsobit vhodně marketingové zaměření.
							</p>
						</td>
						<td>
							IDENTIFIKAČNÍ ÚDAJE
							<br />
							KONTAKTNÍ ÚDAJE
							<br />
							REGISTRAČNÍ ÚDAJE
							<br />
							PREFERENCE
							<br />
							TRANSAKČNÍ ÚDAJE
							<br />
							RECENZE A ZPĚTNÁ VAZBA
							<br />
							METADATA, ÚDAJE O ZAŘÍZENÍCH
						</td>
						<td>
							Oprávněný zájem OLOOPO – rozvoj produktu a obchodní činnost, marketing
							<br />
							<br />
							Souhlas
						</td>
					</tr>
					<tr>
						<td>
							Zabezpečení – pro účely monitorování, zlepšování a vyhodnocování bezpečnosti OLOOPO platformy a datové
							integrity, jakož i pro vyšetřování případných bezpečnostních incidentů, budeme muset zpracovávat i Vaše
							osobní údaje, mimo jiné v souvislosti s Vašimi interakcemi s OLOOPO platformou.
						</td>
						<td>
							IDENTIFIKAČNÍ ÚDAJE
							<br />
							TRANSAKČNÍ ÚDAJE
							<br />
							KOMUNIKACE
							<br />
							METADATA, ÚDAJE O ZAŘÍZENÍCH
						</td>
						<td>Oprávněný zájem OLOOPO – zajištění kybernetické bezpečnosti</td>
					</tr>
					<tr>
						<td>
							Určení a uplatnění právních nároků nebo obhajoba proti nim – v rámci právních vztahů vznikajících mezi
							Vámi a společností OLOOPO nebo Vámi a jinými uživateli může docházet k vznesení právních nároků některou
							ze stran, a to především formou soudních a jiných řízení. Pro účely posouzení existence těchto nároků,
							jejich uplatnění nebo obraně proti nim budeme zpracovávat Vaše osobní údaje týkající se Vás jako osoby
							oprávněné nebo povinné, a o okolnostech poskytování OLOOPO služeb nebo aktivitě na OLOOPO platformě. V
							případě sporu uživatelů můžou být informace sdíleny s druhou stranu sporu, resp. orgánem tento spor
							řešícím
						</td>
						<td>
							IDENTIFIKAČNÍ ÚDAJE
							<br />
							KONTAKTNÍ ÚDAJE
							<br />
							PLATEBNÍ ÚDAJE
							<br />
							REGISTRAČNÍ ÚDAJE
							<br />
							KOMUNIKACE
							<br />
							TRANSAKČNÍ ÚDAJE
						</td>
						<td>Oprávněný zájem OLOOPO – ochrana práv</td>
					</tr>
					<tr>
						<td>
							Zákaznická podpora – V souvislosti s Vašimi požadavky o zákaznickou podporu budeme zpracovávat osobní
							údaje týkající se Vašeho požadavku a veškerou související komunikaci.
						</td>
						<td>
							IDENTIFIKAČNÍ ÚDAJE
							<br />
							KONTAKTNÍ ÚDAJE
							<br />
							PLATEBNÍ ÚDAJE
							<br />
							REGISTRAČNÍ ÚDAJE
							<br />
							KOMUNIKACE
							<br />
							TRANSAKČNÍ ÚDAJE
							<br />
							RECENZE A ZPĚTNÁ VAZBA
						</td>
						<td>
							Plnění smlouvy
							<br />
							<br />
							Oprávněný zájem OLOOPO - obchodní činnost
						</td>
					</tr>
					<tr>
						<td>
							Plnění zákonných povinností – Vzhledem k povinnostem, které nám ukládají právní předpisy, musíme
							zpracovávat Vaše identifikační a kontaktní údaje, jako i transakční informace. Právní povinnosti, které
							tímto plníme, vyplývají zejména ze zákona č. 89/2012 Sb., občanského zákoníku, zákona č. 634/1992 Sb., o
							ochraně spotřebitele, zákona č. 235/2004 Sb., o dani z přidané hodnoty a zákona č. 563/1991 Sb., o
							účetnictví, a předpisy na ochranu osobních údajů.
						</td>
						<td>
							IDENTIFIKAČNÍ ÚDAJE
							<br />
							KONTAKTNÍ ÚDAJE
							<br />
							PLATEBNÍ ÚDAJE
							<br />
							TRANSAKČNÍ ÚDAJE
						</td>
						<td>Plnění právních povinností</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
};

PrivacyDataUsage.title = 'K jakým účelům využíváme Vaše osobní údaje?';
PrivacyDataUsage.id = 'privacyDataUsage';

export default PrivacyDataUsage;
