import { useTranslation } from '../../../../features/localization/hooks';

const Limits = () => {
	const t = useTranslation('supportAndFaq');
	return (
		<div>
			<p>{t('limits.content')}</p>
		</div>
	);
};

Limits.title = 'supportAndFaq.limits.title';
Limits.id = 'limits';

export default Limits;
