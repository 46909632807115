import Box from '@mui/material/Box';
import { TitlePrimary, TextArea } from '../../../Layout/Page';
import { useTranslation } from '../../../features/localization/hooks';

const AboutSection = () => {
	const t = useTranslation('aboutUs');
	return (
		<Box>
			<TitlePrimary text={t('aboutHeader')} />
			<TextArea text={t('aboutTextOne')} lineHeightMD={'33px'} />
			<TextArea text={t('aboutTextTwo')} lineHeightMD={'33px'} />
		</Box>
	);
};

export default AboutSection;
