const ProcessingPersonalDataSupervisor = () => {
	return (
		<div>
			<p>
				Máte-li podezření, že Vaše osobní údaje nejsou z naší strany dostatečně chráněny nebo je zpracováváme
				neoprávněně, v rozporu s těmito zásadami nebo právní úpravou ochrany osobních dat, budeme rádi, když se s Vašim
				podezřením obrátíte nejdříve na nás. Vaší stížnosti budeme věnovat řádnou pozornost a věříme, že budeme schopni
				situaci řádně vyšetřit a vyřešit způsobem zajišťujícím ochranu Vašich zájmů.
			</p>
			<p>
				Samozřejmě, je Vašim právem se v takových případech obrátit na dozorový orgán příslušný ve státě Vašeho trvalého
				bydliště, pracoviště nebo místa domnělého narušení Vašeho soukromí. Ve většině případů se Vaším podnětem bude
				zabývat český dozorový orgán, a tedy Úřad pro ochranu osobních údajů. Vice informací o činnosti tohoto orgánu,
				včetně kontaktních údajů, prostřednictvím kterých se na něj můžete obrátit, naleznete tady:{' '}
				<a href="https://www.uoou.cz/" target="_blank" rel="noreferrer">
					https://www.uoou.cz/
				</a>
			</p>
			<p>
				Tyto zásady platí od níže uvedeného data. Vyhrazujeme si právo tyto zásady změnit, změní-li se okolnosti
				zpracovávání osobních údajů v souvislosti s užíváním OLOOPO platformy nebo služeb. Pokud se vás změny budou
				týkat, změny těchto zásad a jejich účinnost Vám budou oznámeny prostřednictvím platformy předem, přičemž máte
				právo naše služby nebo platformu dále nepoužívat. Pokračováním v aktivním využívání naší platformy a služeb
				projevíte souhlas s aktuálním zněním těchto zásad.
			</p>
			<p>Dne 19.11.2023</p>
		</div>
	);
};

ProcessingPersonalDataSupervisor.title = 'Jaký dozorový orgán dohlíží na naše zpracovávání Vašich osobních údajů?';
ProcessingPersonalDataSupervisor.id = 'processingPersonalDataSupervisor';

export default ProcessingPersonalDataSupervisor;
