import Box from '@mui/material/Box';

import Page, { TitledContent } from '../../Layout/Page';
import CareersIntroSectionMD from './components/CareersIntroSectionMD';
import CareersIntroSection from './components/CareersIntroSection';
import CareersFormSection from './components/CareersFormSection';
import CareersContextProvider from '../../store/CareersContextProvider';

import { routes } from '../../features/navigation/constants';
import { useTranslation } from '../../features/localization/hooks';

const Careers = () => {
	const t = useTranslation('careers');
	return (
		<CareersContextProvider>
			<Page activeRoute={routes.CAREERS.route}>
				<TitledContent title={t('title')}>
					<Box sx={{ display: { xs: 'block', md: 'none' } }}>
						<CareersIntroSection />
					</Box>
					<Box sx={{ display: { xs: 'none', md: 'block' } }}>
						<CareersIntroSectionMD />
					</Box>
					<CareersFormSection />
				</TitledContent>
			</Page>
		</CareersContextProvider>
	);
};

export default Careers;
