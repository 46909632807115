import {
	forEachObjIndexed,
	values,
	any,
	map,
	compose,
	pick,
	append,
	isEmpty,
	assoc,
	dissoc,
	join,
	slice,
	toLower,
	split,
	reject,
	reduce,
	o,
} from 'ramda';
import { COOKIE_CATEGORIES, COOKIE_CONSENT_NOT_GIVEN, cookieCategoryEnum } from './constants';
import { isNotEmpty, mapKeys } from 'ramda-extension';

export const getCookieConsentKey = (key) => `COOKIE_CONSENT_${key.toUpperCase()}`;

export const isTimestampInPast = (timestamp) => {
	const now = Date.now().toString();

	return timestamp < now;
};

export const setCookieConsentTimestamp = (category, cookieConsents, setCookieConsents) => {
	const todayPlusOneYear = new Date().setFullYear(new Date().getFullYear() + 1).toString();
	const updatedCookieConsents = assoc(category, todayPlusOneYear, cookieConsents);

	setCookieConsents(updatedCookieConsents);
};

export const enableAllAndSave = (cookieConsents) => {
	const todayPlusOneYear = new Date().setFullYear(new Date().getFullYear() + 1).toString();

	return reduce(
		(acc, category) => {
			return assoc(category, todayPlusOneYear, acc);
		},
		cookieConsents,
		[cookieCategoryEnum.ANALYTICS, cookieCategoryEnum.PERSONALIZATION, cookieCategoryEnum.MARKETING],
	);
};

export const removeCookieConsentTimestamp = (category, cookieConsents, setCookieConsents) => {
	const updatedCookieConsents = dissoc(category, cookieConsents);

	setCookieConsents(updatedCookieConsents);
};

export const saveCookieConsents = (cookieConsents) => {
	const areAllCookieConsentsEqualToFalse = isEmpty(cookieConsents);
	const todayPlusSixMonths = new Date().setMonth(new Date().getMonth() + 6).toString();

	if (areAllCookieConsentsEqualToFalse) {
		localStorage.setItem(COOKIE_CONSENT_NOT_GIVEN, todayPlusSixMonths);
	} else {
		const isCookieConsentNotGiven = localStorage.getItem(COOKIE_CONSENT_NOT_GIVEN);
		if (isCookieConsentNotGiven) {
			const isAnyCookieConsentsGiven = isNotEmpty(cookieConsents);
			if (isAnyCookieConsentsGiven) {
				localStorage.removeItem(COOKIE_CONSENT_NOT_GIVEN);
			}
		}
	}

	forEachObjIndexed((value, key) => {
		const localStorageKey = getCookieConsentKey(key);

		if (!(key in cookieConsents) && localStorage.getItem(localStorageKey)) {
			localStorage.removeItem(localStorageKey);
		}
	}, cookieCategoryEnum);

	forEachObjIndexed((value, key) => {
		const localStorageKey = getCookieConsentKey(key);
		if (value) {
			localStorage.setItem(localStorageKey, value);
		} else {
			localStorage.removeItem(localStorageKey);
		}
	}, cookieConsents);
};

const transformLocalStorageKey = compose(join('_'), map(toLower), slice(2, Infinity), split('_'));

const getCookieConsents = compose(
	(keysToCheck) => pick(keysToCheck, localStorage),
	append('cookiesConsentNotGiven'),
	map(getCookieConsentKey),
);

export const loadCookieConsentsFromLocalStorage = compose(
	reject(isTimestampInPast),
	mapKeys(transformLocalStorageKey),
	(keysToCheck) => pick(keysToCheck, localStorage),
	map(getCookieConsentKey),
);

const isAnyCookieConsentInPast = o(any(isTimestampInPast), values);

export const getIsCookiesModalVisible = (isCookiesModalBlocked) => {
	const cookieConsents = getCookieConsents(COOKIE_CATEGORIES);

	if (isCookiesModalBlocked) {
		return false;
	} else if (isEmpty(cookieConsents)) {
		return true;
	} else if (cookieConsents) {
		return isAnyCookieConsentInPast(cookieConsents);
	} else {
		return false;
	}
};
