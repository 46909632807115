const DocumentIntro = () => {
	return (
		<div>
			<p>Ve společnosti OLOOPO věříme, že Vaše osobní údaje si zasluhují vysoký standard ochrany.</p>
			<p>
				Osobní údaje, které nám při využívání OLOOPO platformy a služeb sdělíte, budeme zpracovávat zákonným a
				legitimním způsobem, tak aby Vaše práva a povinnosti byly řádně chráněny a bylo předcházeno jejich zneužití.
				Naše zpracování Vašich osobních údajů podléhá GDPR (General Data Protection Regulation) a příslušné české
				vnitrostátní regulaci.
			</p>
			<p>
				Tyto zásady představují popis rozsahu, způsobu a účelu zpracovávání jakýchkoli informací týkajících se přímo
				nebo nepřímo Vás (jako subjektu údajů), které nám (jako správci osobních údajů) poskytnete při užívání OLOOPO
				platformy a služeb. Prostřednictvím těchto zásad plníme svojí základní informační povinnost vůči Vám, a to i
				ohledně Vašich práv spojených se zpracováváním Vašich osobních údajů, jako i jejich případného sdílení s třetími
				stranami (např. námi využívanými zpracovateli osobních údajů).
			</p>
			<p>
				Váš souhlas s těmito zásadami (který můžete projevit v rámci vytvoření Vašeho uživatelského účtu nebo kdykoli
				potom v rámci OLOOPO platformy nebo mimo ni) současně představuje jeden ze základů pro naše zpracování Vašich
				osobních údajů.
			</p>
			<p>
				Rádi bychom Vás upozornili, že tento dokument může obsahovat odkazy na jiné dokumenty rovněž zveřejněné na naší
				platformě nebo veřejně přístupné na internetu. Tyto dokumenty jsou v takovém případě vtěleny do těchto zásad
				jako jejich nedílné součásti a můžou obsahovat naše dodatečná vzájemná práva a povinnosti. Věnujte jim, prosím,
				pozornost a pečlivě si je prostudujte.
			</p>
		</div>
	);
};

DocumentIntro.title = 'Co tento dokument představuje?';
DocumentIntro.id = 'documentIntro';

export default DocumentIntro;
