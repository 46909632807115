const LoopAgreement = () => {
	return (
		<div>
			<p>
				Jako uživateli OLOOPO platformy s registrovaným předmětem Vám bude umožněno prohlížení předmětů nabízených k
				výměně jinými registrovanými uživateli. V případě, že v rámci svého uživatelského účtu zadáte své preference a
				jiné osobní informace sloužící k individualizovanému zobrazovaní nabízených předmětů, můžou být přednostně
				zobrazovány předměty odpovídající Vašim preferencím. Nabídky předmětů nabízených k výměně jinými registrovanými
				uživateli jsou následně řazeny k zobrazování v pořadí stanoveném na základě vážených hodnot ekvivalence cenové
				hladiny hodnoty zobrazovaného předmětu a Vámi nabízeného předmětu, indexu celkového počtu akceptací k zobrazením
				zobrazovaného předmětu na OLOOPO platformě, indexu celkového počtu uložení k zobrazením zobrazovaného předmětu
				na OLOOPO platformě, indexu počtu Vašich akceptací k zobrazení obdobných předmětů, a počtu již Vám realizovaných
				zobrazení daného předmětu. OLOOPO může rovněž přednostně zobrazovat předměty uživatelů, kteří využívají zvláštní
				služby OLOOPO zajišťující přednostní zobrazování.
			</p>
			<p>
				Zobrazované nabídky předmětů k výměně můžete akceptovat nebo odmítnout. Vaše akceptace se vztahuje ke
				konkrétnímu předmětu, jež sami aktuálně nabízíte. Máte-li více registrovaných nabízených předmětů, můžete mezi
				nimi kdykoli přepínat. Je možné akceptovat vícero nabídek předmětů k výměně pro každý Váš registrovaný předmět,
				přičemž OLOOPO si vymezuje právo tento počet omezit. Nedošlo-li k uzavření řetězce akceptací, můžete své
				akceptace kdykoli zrušit.
			</p>
			<p>
				Uzavření řetězce akceptací lze ilustrovat na případě, že Vaše akceptace nabídky předmětu k výměně jiného
				uživatele, spojená s konkrétním Vámi nabízeným předmětem, navazuje na jeho akceptaci nabídky předmětu k výměně
				jiného uživatele, který akceptoval nabídky předmětu k výměně jiného uživatele, a tento řetězec nakonec vede k
				akceptaci konkrétního Vámi nabízeného předmětu. Účastníkem řetězce akceptací můžete být Vy a jediný další
				uživatel (v případě že Vaše akceptace nabídky předmětu k výměně jiného uživatele, spojená s konkrétním Vámi
				nabízeným předmětem, navazuje na jeho akceptaci teto Vaší nabídky konkrétního předmětu k výměně), nebo Vy a
				vícero jiných uživatelů platformy. OLOOPO si vymezuje právo tento počet účastníků řetězce akceptací omezit.
			</p>
			<p>
				Uzavřením řetězce akceptací dochází k aktivaci Vašeho závazku (a obdobného závazku dalších účastníků řetězce
				akceptací) uzavřít smlouvu na jejímž základě bude realizovaná výměna předmětů.
			</p>
			<p>
				Váš závazek bude splněn Vaším potvrzením řetězce akceptací v rámci OLOOPO platformy ve stanovené lhůtě.
				Odmítnutí uzavřeného řetězce akceptací je možné jenom v odůvodněných případech, jež jste povinni nám oznámit
				(např. změna stavu Vámi nabízeného předmětu, jeho zničení nebo ztráta). OLOOPO posoudí odůvodněnost odmítnutí na
				základě svého rozumného uvážení. Bezdůvodné odmítnutí uzavřeného řetězce akceptací nebo odepření udělení
				souhlasu ve stanovené lhůtě je porušením Vašich povinností dle těchto podmínek a OLOOPO má právo v takových
				případech dočasně pozastavit Váš uživatelský účet a v případě opakovaného porušení Váš uživatelský účet trvale
				zrušit, neumožnit Vám založení nového uživatelského účtu a uplatnit nárok na úhradu smluvní pokuty ve výši
				1.000,- Kč za každé takové porušení Vaší povinnosti. Zvažte, prosím, tyto možné právní důsledky před jakoukoli
				svou akceptací nabídky předmětu k výměně.
			</p>
			<p>
				Potvrzením řetězce akceptací dochází mezi Vámi a ostatními účastníky řetězce akceptací k uzavření souboru smluv,
				kdy každý z účastníků (včetně Vás) se zavazuje bezúplatně převést vlastnické právo k svému nabízenému předmětu
				na účastníka řetězce akceptací, který akceptoval nabídku výměny tohoto předmětu, a tento předmět mu předat
				výhradně způsobem stanoveným dále. Současně každému z účastníků (včetně Vás) svědčí odpovídající právo vůči
				účastníku řetězce akceptací, jehož nabídku k výměně akceptoval, získat vlastnické právo k jeho nabízenému
				předmětu a tento předmět převzít. Každá ze smluv tvořící soubor vzniklý potvrzením řetězce akceptací je
				nezávislou smlouvou. Závazný obsah těchto smluv, včetně práv a povinností z nich vyplývajících stanovují tyto
				podmínky, od nichž se není možné odchýlit. Vy společně s ostatními účastníky řetězce akceptací tímto souhlasně
				prohlašujete, že hodnota a běžná cena jednotlivých předmětů v rámci akceptovaného řetězce akceptací je stejná a
				vzájemně odpovídající.
			</p>
			<p>
				VEZMĚTE, PROSÍM, NA VĚDOMÍ, ŽE OLOOPO NENÍ ÚČASTNÍKEM SMLUV VYPLÝVAJÍCÍCH Z POTVRZENÉHO ŘETĚZCE AKCEPTACÍ.
				OLOOPO TEDY NEPOSKYTUJE JAKÉKOLI ZÁRUKY A NEODPOVÍDÁ ZA SOULADNOST STAVU PŘEDMĚTU S JEHO POPISEM UVEDENÝM NA
				PLATFORMĚ NEBO S JEHO DORUČENÍM OPRÁVNĚNÉMU UŽIVATELI. OLOOPO JE NEZÁVISLÝM ZPROSTŘEDKOVATELEM PŘÍLEŽITOSTI
				UZAVŘÍT UVEDENÉ SMLOUVY MEZI ÚČASTNÍKY POTVRZENÉHO ŘETĚZCE AKCEPTACÍ A ZAJISTIT JEJICH PŘEPRAVU V SOULADU S
				OBSAHEM TĚCHTO SMLUV, A TO PROSTŘEDNICTVÍM NEZÁVISLÉHO SMLUVNÍHO PŘEPRAVCE, PŘIČEMŽ PODMÍNKOU REALIZACE TÉTO
				ČINNOSTI SPOLEČNOSTÍ OLOOPO JE PŘEDCHOZÍ BEZHOTOVOSTNÍ ÚHRADA ODMĚNY VE VÝŠI, KTERÁ VÁM BUDE SDĚLENA V DŮSLEDKU
				POTVRZENÍ ŘETĚZCE AKCEPTACÍ, A TO PROSTŘEDNICTVÍM PLATEBNÍ BRÁNY UMÍSTĚNÉ V RÁMCI PLATFORMY OLOOPO. TATO ODMĚNA
				NÁLEŽÍ SPOLEČNOSTI OLOOPO JIŽ SAMOTNÝM POTVRZENÍM ŘETĚZCE AKCEPTACÍ, BEZ OHLEDU NA SPLNĚNÍ ČI PŘÍPADNÉ PORUŠENÍ
				SMLUV VZNIKLÝM V DŮSLEDKU TOHOTO POTVRZENÍ NĚKTERÝM Z ÚČASTNÍKŮ ŘETĚZCE AKCEPTACÍ. ODMĚNA SPOLEČNOSTI OLOOPO SE
				NEVRACÍ. Odměna společnosti OLOOPO v sobě zahrnuje poplatky za přepravu Vašeho předmětu jeho novému vlastníkovi,
				a to v závislosti na vybraném typu podporované přepravy, avšak nezahrnuje zvýšené náklady a dodatečné poplatky
				vzniklé v důsledku Vašeho porušení těchto podmínek. Tyto dodatečné náklady a poplatky jste povinni zaplatit bez
				zbytečného odkladu po výzvě společnosti OLOOPO převodem prostředků na účet společnosti OLOOPO ve výši která Vám
				bude oznámena.
			</p>
		</div>
	);
};

LoopAgreement.title = 'Jak dojde k dohodě o výměně a co je to řetězec akceptací?';
LoopAgreement.id = 'loopAgreement';

export default LoopAgreement;
