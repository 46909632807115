import DocumentIntro from './termsAndConditionsChapters/DocumentIntro';
import WhoAreWe from './termsAndConditionsChapters/WhoAreWe';
import ProvidedServices from './termsAndConditionsChapters/ProvidedServices';
import UserAccount from './termsAndConditionsChapters/UserAccount';
import UserAccountCreation from './termsAndConditionsChapters/UserAccountCreation';
import UserAccountCancellation from './termsAndConditionsChapters/UserAccountCancellation';
import OperationalConditions from './termsAndConditionsChapters/OperationalConditions';
import AddItem from './termsAndConditionsChapters/AddItem';
import LoopAgreement from './termsAndConditionsChapters/LoopAgreement';
import LoopRealisation from './termsAndConditionsChapters/LoopRealisation';
import LoopFailure from './termsAndConditionsChapters/LoopFailure';
import ExtraServices from './termsAndConditionsChapters/ExtraServices';
import Guarantee from './termsAndConditionsChapters/Guarantee';
import Others from './termsAndConditionsChapters/Others';
import DocumentsAccordion from '../../../common/Components/DocumentsAccordion';
import DocumentsToDownload from '../../../common/Components/DocumentsToDownload';

const createDoc = (label, filename) => ({ label, filename });

const accordionItems = [
	DocumentIntro,
	WhoAreWe,
	ProvidedServices,
	UserAccount,
	UserAccountCreation,
	UserAccountCancellation,
	OperationalConditions,
	AddItem,
	LoopAgreement,
	LoopRealisation,
	LoopFailure,
	ExtraServices,
	Guarantee,
	Others,
];

const TermsAndConditionsSection = () => {
	return (
		<div>
			<DocumentsAccordion accordionItems={accordionItems} />
			<DocumentsToDownload
				currentDocument={createDoc(
					'OLOOPO Obchodní podmínky platné od 18.11.2023',
					'OLOOPO_Obchodni_podminky_18_11_2023.pdf',
				)}
			/>
		</div>
	);
};

export default TermsAndConditionsSection;
