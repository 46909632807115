import React, { useCallback, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Divider from '@mui/material/Divider';

import CustomAlert from '../../../../common/Components/CustomAlert';
import ReCaptcha from '../../../Careers/components/ReCaptcha';
import FormTile from '../../../Careers/components/FormTile';
import ButtonPrimary from '../../../../common/Components/ButtonPrimary';
import ValidationInput from '../../../Careers/components/ValidationInput';

import { useOtherProblemForm } from './hooks';
import { useSubmitForm } from '../../../../features/restApi/hooks';
import { useTranslation } from '../../../../features/localization/hooks';
import { GET_EMAIL_ERROR_MESSAGE, GET_REQUIRED_ERROR_MESSAGE } from '../../../../features/validations/validations';

const dividerStyle = { marginBottom: { xs: '16px', md: '24px' } };

const centeredProps = { display: 'flex', flexDirection: 'column', alignItems: 'center' };

const fields = ['name', 'email', 'description'];

const OtherProblemForm = () => {
	const t = useTranslation('supportAndFaq');
	const { formData, setFormData, formValidation, setFormValidation, isSendButtonDisabled } = useOtherProblemForm();

	const [isCallInProgress, setIsCallInProgress] = useState(false);
	const [isSubmitted, setSubmitted] = useState(false);

	const handleSubmitForm = useSubmitForm('/contact/handle', formData, setSubmitted, () => setIsCallInProgress(true));

	const handleInputChange = useCallback(
		(field) => (event) => setFormData((prevFormData) => ({ ...prevFormData, [field]: event.target.value })),
		[setFormData],
	);

	const handleClickReCaptcha = useCallback(
		(reCaptchaValue) => setFormData((prevFormData) => ({ ...prevFormData, reCaptchaValue })),
		[setFormData],
	);

	return (
		<Box paddingTop={'16px'} display="flex" flexDirection={'column'} justifyContent="center" alignItems="center">
			{isCallInProgress && !isSubmitted ? (
				<CustomAlert severity={'info'} title={'Odesílání'} description={'Váš dotaz se odesílá'} />
			) : isSubmitted ? (
				<CustomAlert severity={'success'} title={'Odesláno'} description={'Váš dotaz byl odeslán'} />
			) : (
				<>
					<FormTile>
						<Box
							component="form"
							sx={{
								'& .MuiFilledInput-root': {
									backgroundColor: '#FFF !important',
								},
								'& .MuiFilledInput-root:Mui-focused': {
									backgroundColor: '#FFF !important',
								},
							}}
							noValidate
							autoComplete="off">
							{fields.map((field, index) => (
								<React.Fragment key={field}>
									{index > 0 && <Divider orientation={'vertical'} sx={dividerStyle} />}
									<ValidationInput
										label={t(`otherProblemForm.${field}`)}
										key={field}
										name={field}
										formData={formData}
										onChange={handleInputChange(field)}
										formValidation={formValidation}
										setFormValidation={setFormValidation}
										errorMessageResolver={field === 'email' ? GET_EMAIL_ERROR_MESSAGE : GET_REQUIRED_ERROR_MESSAGE}
										multiline={field === 'description'}
										minRows={field === 'description' ? 4 : 0}
									/>
								</React.Fragment>
							))}
						</Box>
					</FormTile>
					<Grid container spacing={2}>
						<Grid xs={12} sx={{ marginTop: '40px', ...centeredProps }}>
							<ReCaptcha onClickHandler={handleClickReCaptcha} />
						</Grid>
						<Grid xs={12} sx={{ marginTop: '24px', ...centeredProps }}>
							<ButtonPrimary
								label={t('otherProblemForm.submitForm')}
								disabled={isSendButtonDisabled()}
								onClick={handleSubmitForm}
							/>
						</Grid>
					</Grid>
				</>
			)}
		</Box>
	);
};

OtherProblemForm.title = 'supportAndFaq.otherProblemForm.title';

export default OtherProblemForm;
