import { useCallback } from 'react';
import { useEnvironmentBaseUrl } from '../navigation/hooks';

export const useSubmitForm = (uri, formData, responseSetter, onCallHandler) => {
	const baseUrl = useEnvironmentBaseUrl();
	return useCallback(
		(event) => {
			event.preventDefault();
			onCallHandler && onCallHandler();
			fetch(`${baseUrl}${uri}`, {
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
				method: 'POST',
				body: JSON.stringify(formData),
			})
				.then((res) => res.json())
				.then((data) => {
					responseSetter(data);
				})
				.catch((error) => {
					responseSetter(error);
					console.log('ERROR: ', error);
				});
		},
		[uri, formData, onCallHandler, responseSetter],
	);
};
