import { Route, Routes, Navigate } from 'react-router-dom';
import './assets/css/App.css';
import { routes } from './features/navigation/constants';
import LandingPage from './Pages/LandingPage';
import AboutUs from './Pages/AboutUs';
import SupportAndFaq from './Pages/SupportAndFaq';
import SupportAndFaqApp from './Pages/SupportAndFaq/SupportAndFaqApp';
import Careers from './Pages/Careers';
import TermsAndConditions from './Pages/TermsAndConditions';
import TermsAndConditionsApp from './Pages/TermsAndConditions/TermsAndConditionsApp';
import Privacy from './Pages/Privacy';
import PrivacyApp from './Pages/Privacy/PrivacyApp';
import Tutorials from './Pages/Tutorials/Tutorials';
import CookiesModal from './features/cookies/CookiesModal';

const App = () => (
	<div className="App">
		<Routes>
			<Route path={routes.HOME.route} element={<LandingPage />} />
			<Route path={routes.ABOUT_US.route} element={<AboutUs />} />
			<Route path={routes.SUPPORT_AND_FAQ.route} element={<SupportAndFaq />} />
			<Route path={routes.CAREERS.route} element={<Careers />} />
			<Route path={routes.TERMS_AND_CONDITIONS.route} element={<TermsAndConditions />} />
			<Route path={routes.TUTORIALS.route} element={<Tutorials />} />
			<Route path={routes.PRIVACY.route} element={<Privacy />} />
			{/* Those are only for oo-app usage, it's not reachable from the web navigation */}
			<Route path={routes.TERMS_AND_CONDITIONS_APP.route} element={<TermsAndConditionsApp />} />
			<Route path={routes.SUPPORT_AND_FAQ_APP.route} element={<SupportAndFaqApp />} />
			<Route path={routes.PRIVACY_APP.route} element={<PrivacyApp />} />
			<Route path="*" element={<Navigate replace to={routes.HOME.route} />} />
		</Routes>
		<CookiesModal />
	</div>
);

export default App;
