import { compose, find, head, prop, propEq, split, values } from 'ramda';
import { languagesDefinitionMap } from './constants';
import { defaultToEmptyString } from 'ramda-extension';

export const parseGenericLanguageCode = compose(head, split('-'), defaultToEmptyString);

export const getFlagCodeFromCode = (code) =>
	compose(prop('flagCode'), find(propEq(parseGenericLanguageCode(code), 'code')), values)(languagesDefinitionMap);

export const getCodeFromFlagCode = (flagCode) =>
	compose(prop('code'), find(propEq(flagCode, 'flagCode')), values)(languagesDefinitionMap);
