import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import YouTube from 'react-youtube';
import YoutubeTileTitleAndDescription from './YoutubeTileTitleAndDescription';
import { getVideoIdFromUrl } from '../utils';
import { useBreakpoint } from '../../../mui/hooks';
import PropTypes from 'prop-types';

const YoutubeVideoTile = ({ isMainVideo, url, title, description, onClick }) => {
	const [gridDimensionsWidth, setGridDimensionsWidth] = useState(0);
	const [isLoading, setIsLoading] = useState(true);

	const videoId = getVideoIdFromUrl(url);

	useEffect(() => {
		const width = document.getElementById(videoId).offsetWidth + 5;
		setGridDimensionsWidth(width);
		setIsLoading(false);
	}, []);

	const isXs = useBreakpoint('xs');

	const videoOptions = {
		playerVars: { autoplay: isMainVideo ? 0 : 1 },
		width: gridDimensionsWidth,
		height: isXs ? 200 : 435,
	};

	return (
		<Box id={videoId} onClick={onClick}>
			<Box
				sx={{
					borderRadius: { xs: '8px', md: '16px' },
					overflow: 'hidden',
					backgroundColor: 'black',
				}}>
				{videoId && !isLoading && <YouTube videoId={videoId} opts={videoOptions} />}
			</Box>
			<YoutubeTileTitleAndDescription title={title} description={description} isMainVideo={isMainVideo} />
		</Box>
	);
};

YoutubeVideoTile.propTypes = {
	isMainVideo: PropTypes.bool,
	url: PropTypes.string,
	title: PropTypes.string,
	description: PropTypes.string,
	onClick: PropTypes.func,
};

export default YoutubeVideoTile;
