import { useContext } from 'react';
import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';

import FormTile from './FormTile';

import { useSelectOptions } from '../hooks';
import { useTranslation } from '../../../features/localization/hooks';
import { careersFormStructure } from './careersFormStructure';
import { EMPLOYER_BRANDING_SURVEY_OPTIONS } from '../constant';
import { CareersContext } from '../../../store/CareersContextProvider';

const EmployerBrandingSurvey = () => {
	const { handleInputCheckboxChange } = useContext(CareersContext);
	const t = useTranslation('careers');
	const brandingSurveyOptions = useSelectOptions(
		EMPLOYER_BRANDING_SURVEY_OPTIONS,
		'form.employerBrandingSurvey.questions',
	);

	return (
		<FormTile title={t('form.employerBrandingSurvey.title')} titleDescription={' (optional)'}>
			<Box paddingTop={'16px'}>
				<FormLabel sx={{ fontFamily: 'inherit', fontSize: '18px', color: 'typography.default' }}>
					{t('form.employerBrandingSurvey.description')}
				</FormLabel>
				<FormGroup
					name={careersFormStructure.employerBrandingSurvey.BRANDING_SURVEY}
					onChange={handleInputCheckboxChange}>
					{brandingSurveyOptions.map(({ id, label, value }) => (
						<FormControlLabel
							sx={{ paddingTop: '12px' }}
							name={value}
							key={id}
							label={
								<Typography
									sx={{
										fontFamily: 'inherit',
										fontSize: { xs: '16px', md: '18px' },
										fontWeight: 400,
										lineHeight: { xs: '24px', md: '27px' },
										letterSpacing: '0em',
										textAlign: 'left',
									}}
									variant="body1"
									color="typography.default">
									{label}
								</Typography>
							}
							control={
								<Checkbox
									sx={{
										alignSelf: 'flex-start',
										'&.Mui-checked': {
											color: 'typography.primary',
										},
									}}
								/>
							}
						/>
					))}
				</FormGroup>
			</Box>
		</FormTile>
	);
};

export default EmployerBrandingSurvey;
