import { useTranslation } from '../../../../features/localization/hooks';

const IncreaseRating = () => {
	const t = useTranslation('supportAndFaq');
	return (
		<div>
			<p>{t('increaseRating.content')}</p>
		</div>
	);
};

IncreaseRating.title = 'supportAndFaq.increaseRating.title';
IncreaseRating.id = 'increaseRating';

export default IncreaseRating;
