const WhoAreWe = () => {
	return (
		<div>
			<p>
				„OLOOPO“ nebo „my“ v těchto zásadách znamená OLOOPO s.r.o., se sídlem Hausmannova 3001/13, Modřany, 143 00 Praha
				4, Česká republika, IČO: 17135061, DIČ: CZ17135061, společnost zapsaná v Obchodním rejstříku vedeném Městským
				soudem v Praze, pod sp. zn. C 391029.
				<br />
				Ve vztahu k Vašim osobním údajům, které nám poskytnete podle těchto zásad, vystupujeme jako správce osobních
				údajů.
			</p>
		</div>
	);
};

WhoAreWe.title = 'Kdo jsme?';
WhoAreWe.id = 'whoWeAre';

export default WhoAreWe;
