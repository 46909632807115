import DocumentIntro from './privacySectionChapters/DocumentIntro';
import WhoAreWe from './privacySectionChapters/WhoAreWe';
import ProvidedPrivacyData from './privacySectionChapters/ProvidedPrivacyData';
import PrivacyDataUsage from './privacySectionChapters/PrivacyDataUsage';
import PersonalDataSharing from './privacySectionChapters/PersonalDataSharing';
import Cookies from './privacySectionChapters/Cookies';
import PersonalDataStorage from './privacySectionChapters/PersonalDataStorage';
import ProcessingPersonalDataRights from './privacySectionChapters/ProcessingPersonalDataRights';
import ProcessingPersonalDataContacting from './privacySectionChapters/ProcessingPersonalDataContacting';
import ProcessingPersonalDataSupervisor from './privacySectionChapters/ProcessingPersonalDataSupervisor';
import DocumentsToDownload from '../../../common/Components/DocumentsToDownload';
import DocumentsAccordion from '../../../common/Components/DocumentsAccordion/DocumentsAccordion';

const createDoc = (label, filename) => ({ label, filename });

const accordionItems = [
	DocumentIntro,
	WhoAreWe,
	ProvidedPrivacyData,
	PrivacyDataUsage,
	PersonalDataSharing,
	Cookies,
	PersonalDataStorage,
	ProcessingPersonalDataRights,
	ProcessingPersonalDataContacting,
	ProcessingPersonalDataSupervisor,
];

const PrivacySection = () => {
	return (
		<div>
			<DocumentsAccordion accordionItems={accordionItems} />
			<DocumentsToDownload
				currentDocument={createDoc(
					'Zásady ochrany osobnyých udajů platné od 19.11.2023',
					'OLOOPO_Zasady_osobnich_udaju_19_11_2023.pdf',
				)}
			/>
		</div>
	);
};

export default PrivacySection;
