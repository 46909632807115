import { assoc, concat, isEmpty, isNil, reject, remove, values, prop, take, pick, any, both, o, compose } from 'ramda';
import { flipIncludes, isNilOrEmpty, isNilOrEmptyString, notEqual } from 'ramda-extension';
import { allowedMimeTypes, MAX_FILE_COUNT, MAX_FILE_SIZE } from './constant';
import { careersFormStructure } from './components/careersFormStructure';

export const addFileToFormData = (setFormData, fileArray, formData) => {
	const updatedFormAfterAdd = assoc(
		careersFormStructure.attachments.ATTACHMENTS,
		concat(fileArray, formData[careersFormStructure.attachments.ATTACHMENTS]),
		formData,
	);
	setFormData(updatedFormAfterAdd);
};

export const removeFileFormFormData = (setFormData, index, formData) => {
	const updatedFormAfterRemove = assoc(
		careersFormStructure.attachments.ATTACHMENTS,
		remove(index, 1, formData.attachments),
		formData,
	);
	setFormData(updatedFormAfterRemove);
};

export const openFileInNewTab = (base64File, mimeType) => {
	const binaryData = atob(base64File.split(',')[1]);
	const arrayBuffer = new ArrayBuffer(binaryData.length);
	const uint8Array = new Uint8Array(arrayBuffer);

	for (let i = 0; i < binaryData.length; i++) {
		uint8Array[i] = binaryData.charCodeAt(i);
	}

	const blob = new Blob([arrayBuffer], { type: mimeType });
	const blobUrl = URL.createObjectURL(blob);

	return window.open(blobUrl);
};

export const toBase64 = (file) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = reject;
	});

export const uploadImage = async (event, formData, setFormData, alertSetter) => {
	const files = Array.from(event.target.files);
	const processedFiles = await Promise.all(
		take(MAX_FILE_COUNT - formData.attachments.length, files).map(async (file) => {
			if (file.size > MAX_FILE_SIZE) {
				alertSetter('form.attachments.alertFileMB', file.name);
			} else if (flipIncludes(allowedMimeTypes, file.type)) {
				alertSetter('form.attachments.alertFileType', file.name);
			} else {
				try {
					const contentBase64 = await toBase64(file);
					return {
						mimeType: file.type,
						timestamp: file.lastModifiedDate,
						size: file.size,
						name: file.name,
						contentBase64,
					};
				} catch (error) {
					alertSetter('form.attachments.alertFileProcessingError', file.name);
				}
			}
		}),
	);
	addFileToFormData(setFormData, reject(isNil, processedFiles), formData);
};

const isAnyValueNotEqualsToNull = compose(any(notEqual(null)), values);

const areAttachmentsEmpty = both(o(isEmpty, prop('attachments')), o(isNilOrEmptyString, prop('linkToShare')));

const isAnySelectedFieldEmpty = compose(
	any(isNilOrEmpty),
	values,
	pick(['fullName', 'email', 'phoneNumber', 'jobType', 'department', 'reCaptchaValue']),
);

export const isCareersFormInvalid = (formData, formValidation) => {
	return (
		areAttachmentsEmpty(formData) || isAnyValueNotEqualsToNull(formValidation) || isAnySelectedFieldEmpty(formData)
	);
};
