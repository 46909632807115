const Others = () => {
	return (
		<div>
			<p>
				Vzhledem k povaze našich služeb nemáte právo odstoupit od Smlouvy do 14 dnů od jejího uzavření ani kdykoli poté.
				Naše služby začneme poskytovat ihned po obdržení Vašeho pokynu či souhlasu, tj. před uplynutím 14denní lhůty pro
				odstoupení od smlouvy podle směrnice 2011/83/EU o právech spotřebitelů. Váš souhlas nebo instrukce s
				poskytováním našich služeb bude považována za Vaši žádost a výslovný souhlas s okamžitým poskytováním našich
				služeb. Toto ustanovení neomezuje Vaše právo vymazat svůj uživatelský účet nebo registrovaného předmětu, a to za
				podmínek stanovených těmito podmínkami (zejména, není-li tím negativně ovlivněn uzavřený řetězec akceptací).
			</p>
			<p>
				Pokud se kterékoli z ustanovení těchto podmínek ukáže být neplatným nebo neúčinným, ostatní zbývající ustanovení
				zůstanou v plné platnosti a účinnosti, a to v rozsahu, v jakém nejsou závislé na takovém neplatném nebo
				neúčinném ustanovení. V takovém případě bude jakékoli neplatné nebo neúčinné ustanovení nahrazeno novým, platným
				a účinným, které odpovídá záměru a účelu ustanovení, které je nahrazováno.
			</p>
			<p>
				Tyto podmínky a jakékoli právní vztahy, které z ní vyplývají nebo jsou z ní odvozené, včetně otázek jejího
				vzniku a platnosti, se řídí právními předpisy České republiky, s vyloučením veškerých kolizních norem.
			</p>
			<p>
				V případě sporů mezi Vámi a námi budou příslušné k projednání veškerých sporů vzniknuvších mezi námi soudy České
				republiky, místně příslušné pro město Praha, ledaže aplikovatelná kogentní právní úprava stanoví jinak.
			</p>
			<p>
				Podle právních předpisů EU jsou všichni spotřebitelé s bydlištěm na zemí EU oprávněni před podáním soudní žaloby
				zahájit dle zákona č. 634/1992 Sb., o ochraně spotřebitele, mimosoudní jednání o narovnání sporů s námi, a to za
				předpokladu, že žádný takový spor mezi spotřebitelem v EU a námi nebyl úspěšně narovnán přímo. Institucí
				odpovědnou za mimosoudní řešení sporů spotřebitelů v EU s naší společností je Česká obchodní inspekce (coi.cz).
				Více informací o mimosoudním řešení spotřebitelských sporů naleznete na adrese:
				https://www.coi.cz/informace-o-adr/.
			</p>
			<p>
				Podle nařízení EU č. 524/2013 jsou spotřebitelé oprávněni zahájit mimosoudní řešení spotřebitelských sporů
				online prostřednictvím platformy ODR pro online řešení spotřebitelských sporů dostupné na adrese:
				ec.europa.eu/consumers/odr. Před zahájením kteréhokoli z výše uvedených způsobů řešení sporů nás prosím
				kontaktujte prostřednictvím našeho kontaktního formuláře dostupného na naší platformě.
			</p>
			<p>
				V případě, že je nám zabráněno či zamezeno plnění nebo se zpozdíme s plněním kterékoli z našich smluvních nebo
				jiných povinností vůči Vám, a to z důvodů vyšší moci, včetně zejména faktických, technických, politických,
				ekonomických, meteorologických a jiných neočekávaných okolností, jako je například zásah vyšší moci, přírodní
				katastrofy, epidemie, pandemie, války, občanské nepokoje, protesty, demonstrace, výpadky proudu, stávky,
				jakákoli vládní nařízení nebo opatření, výpadky nebo omezení v dodávkách zboží a služeb, nařízení a doporučení
				omezující cestování a jiné okolnosti, které nemůžeme přiměřeně ovlivnit a vyřešit, ať už byly předvídatelné, či
				nikoli, jsme osvobozeni od jakýchkoli odvozených právních povinností vznikajících z důvodu těchto nedostatků
				nebo nedodržení povinností v souvislosti s dopadem vyšší moci.
			</p>
			<p>
				Tyto podmínky stanovují naše minimální povinnosti vůči Vám a neomezují naše právo Vám nabídnout a poskytovat
				naši platformu a služby za podmínek výhodnějších pro Vás, trvale nebo časově omezeně. Tyto podmínky Vám budou
				vhodným způsobem oznámeny v rámci běžné komunikace, a to včetně prostřednictvím naší platformy.
			</p>
			<p>
				Nestanoví-li tyto podmínky jinak, společnost OLOOPO odpovídá za škodu, kterou Vám způsobila naše nedbalost nebo
				úmyslné porušení těchto podmínek nebo jiných aplikovatelných právních předpisů, a to až do maximální výše škody
				odpovídající běžné hodnotě Vašeho registrovaného předmětu, v souvislosti s kterým došlo k porušení povinností ze
				strany společnosti OLOOPO, nejvíce však 30.000,- Kč.
			</p>
			<p>
				Tyto podmínky platí od níže uvedeného data. Vyhrazujeme si právo tyto podmínky kdykoli změnit. Změny těchto
				podmínek a jejich účinnost Vám budou oznámeny prostřednictvím platformy předem, přičemž máte právo naše služby
				nebo platformu dále nepoužívat. Pokračováním v aktivním využívání naší platformy a služeb projevíte souhlas s
				aktuálním zněním těchto podmínek.
			</p>
			<p>Dne 18.11.2023</p>
		</div>
	);
};

Others.title = '… a pár právních nezbytností (kterým byste ale měli věnovat pozornost).';
Others.id = 'others';

export default Others;
