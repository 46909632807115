export const routes = {
	HOME: {
		route: '/',
	},
	ABOUT_US: {
		label: 'aboutUs',
		route: '/about-us',
	},
	SUPPORT_AND_FAQ: {
		label: 'supportAndFaq',
		route: '/support-and-faq',
	},
	SUPPORT_AND_FAQ_APP: {
		label: 'supportAndFaq',
		route: '/support-and-faq-app',
	},
	CAREERS: {
		label: 'careers',
		route: '/careers',
	},
	TUTORIALS: {
		label: 'tutorials',
		route: '/tutorials',
	},
	PRIVACY: {
		label: 'privacy',
		route: '/privacy',
	},
	PRIVACY_APP: {
		label: 'privacy',
		route: '/privacy-app',
	},
	TERMS_AND_CONDITIONS: {
		label: 'termsAndConditions',
		route: '/terms-and-conditions',
	},
	TERMS_AND_CONDITIONS_APP: {
		label: 'termsAndConditions',
		route: '/terms-and-conditions-app',
	},
};

export const platforms = {
	APPLE: 'apple',
	ANDROID: 'android',
	UNKNOWN: 'unknown',
};
