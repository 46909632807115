import { o, map, toUpper, pluck } from 'ramda';
import downloadAppStoreCz from '../../assets/images/downloadAppStoreCz.svg';
import downloadAppStoreEn from '../../assets/images/downloadAppStoreEn.svg';
import downloadGooglePlayCz from '../../assets/images/downloadGooglePlayCz.png';
import downloadGooglePlayEn from '../../assets/images/downloadGooglePlayEn.png';
import appExampleCz from '../../assets/images/appExampleCz.png';
import appExampleEn from '../../assets/images/appExampleEn.png';

export const LANGUAGE_QUERY_PARAM_NAME = 'lang';

export const languagesDefinitionMap = {
	CS: { code: 'cs', flagCode: 'CZ' },
	EN: { code: 'en', flagCode: 'GB' },
};

const ORDERED_LANGUAGES = [languagesDefinitionMap.CS, languagesDefinitionMap.EN];

export const ORDERED_LANGUAGE_CODES = pluck('code', ORDERED_LANGUAGES);

export const ORDERED_FLAG_CODES = o(map(toUpper), pluck('flagCode'))(ORDERED_LANGUAGES);

export const localizedBadges = {
	CS: { downloadAppStore: downloadAppStoreCz, downloadGooglePlay: downloadGooglePlayCz },
	EN: { downloadAppStore: downloadAppStoreEn, downloadGooglePlay: downloadGooglePlayEn },
};

export const localizedAppExampleImages = {
	CS: appExampleCz,
	EN: appExampleEn,
};
