import { useState, useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { prop } from 'ramda';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { ACCORDION_PANEL_ID_PREFIX, ACTIVE_PANEL } from './constants';

const StyledAccordion = styled(Accordion)(({ theme }) => {
	return {
		boxShadow: 'none', // this styles directly apply to accordion
		'.MuiAccordionDetails-root': {},
		'.MuiAccordionSummary-root': {}, // this apply to Summary
		'&.Mui-expanded': {
			borderTop: `1px solid rgba(0, 0, 0, 0.12)`,
		},
		'&:first-of-type': {
			borderTop: 'none',
		},
	};
});

const ControlledAccordions = ({ accordionItems = [] }) => {
	const { t } = useTranslation();
	const [expanded, setExpanded] = useState('');

	const location = useLocation();

	const locationSearch = prop('search')(location);

	useEffect(() => {
		if (locationSearch) {
			const searchParams = new URLSearchParams(locationSearch);
			const activePanel = searchParams.get(ACTIVE_PANEL);

			if (activePanel) {
				setExpanded(activePanel);
				const element = document.getElementById(`${activePanel}bh-header`);
				if (element) {
					element.scrollIntoView({ behavior: 'smooth', block: 'start' });
				}
			}
		}
	}, [locationSearch]);

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : '');
	};

	return (
		<div>
			{accordionItems.map((Content) => {
				const panelId = `${ACCORDION_PANEL_ID_PREFIX}${Content.id}`;
				return (
					<StyledAccordion key={panelId} expanded={expanded === panelId} onChange={handleChange(panelId)}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon sx={{ color: 'color.primary' }} />}
							aria-controls={`${panelId}bh-content`}
							id={`${panelId}bh-header`}
							sx={{ paddingY: { xs: '12px', md: '16px' }, paddingLeft: { md: '32px' } }}>
							<Typography
								sx={{
									width: '95%',
									flexShrink: 0,
									color: 'typography.default',
									fontFamily: 'KohinoorBangla',
									fontSize: { xs: '18px', md: '22px' },
									fontWeight: 700,
									lineHeight: { xs: '33px', md: '27px' },
									letterSpacing: '0em',
									textAlign: 'left',
								}}>
								{t(Content.title)}
							</Typography>
						</AccordionSummary>
						<AccordionDetails sx={{ paddingY: { md: '0px' }, paddingLeft: { md: '32px' } }}>
							<Content />
						</AccordionDetails>
					</StyledAccordion>
				);
			})}
		</div>
	);
};

export default ControlledAccordions;
