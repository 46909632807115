import Box from '@mui/material/Box';
import { TitlePrimary, TextArea } from '../../../Layout/Page';
import { useTranslation } from '../../../features/localization/hooks';
import document_file from '../../../assets/images/document.png';

const DocumentLine = ({ label, filename }) => {
	return (
		<Box
			sx={{
				paddingY: { sx: '12px', md: '16px' },
			}}>
			<a style={{ textDecoration: 'none' }} href={process.env.PUBLIC_URL + `/${filename}`} download={filename}>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'flex-start',
						alignItems: 'center',
						cursor: 'pointer',
					}}>
					<Box
						sx={{
							width: { xs: '16px', md: '22px' },
							height: { xs: '16px', md: '22px' },
							marginRight: '18px',
						}}>
						<img src={document_file} alt="document" width="100%" height="100%" />
					</Box>
					<TextArea text={label} color="typography.primary" topDivider="0px" />
				</Box>
			</a>
		</Box>
	);
};

const DocumentsToDownload = ({ currentDocument, legacyDocuments = [] }) => {
	const t = useTranslation('common');

	const legacyDocumentsNotEmpty = legacyDocuments.length > 0;

	return (
		(currentDocument || legacyDocumentsNotEmpty) && (
			<Box sx={{ marginTop: { xs: '32px', md: '40px' } }}>
				{currentDocument && (
					<Box>
						<Box sx={{ marginBottom: { xs: '12px', md: '16px' } }}>
							<TitlePrimary text={t('currentVersion')} />
						</Box>
						<DocumentLine label={currentDocument.label} filename={currentDocument.filename} />
					</Box>
				)}
				{legacyDocumentsNotEmpty > 0 && (
					<Box sx={{ marginTop: { xs: '12px', md: '24px' } }}>
						<Box sx={{ marginBottom: { xs: '12px', md: '16px' } }}>
							<TitlePrimary text={t('legacyVersion')} />
						</Box>
						{legacyDocuments.map(({ label, filename }) => (
							<DocumentLine key={label} label={label} filename={filename} />
						))}
					</Box>
				)}
			</Box>
		)
	);
};

export default DocumentsToDownload;
