import { matchIsValidTel } from 'mui-tel-input';

const EMAIL = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const URL = /^(www\.)?([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(:[0-9]+)?(\/.*)?$/;

export const GET_REQUIRED_ERROR_MESSAGE = (fieldValue) => (fieldValue === '' ? 'fieldRequired' : null);

export const GET_EMAIL_ERROR_MESSAGE = (fieldValue) => {
	if (fieldValue === '') {
		return 'fieldRequired';
	} else if (!EMAIL.test(fieldValue)) {
		return 'invalidEmail';
	} else {
		return null;
	}
};

export const GET_URL_ERROR_MESSAGE = (fieldValue) => {
	if (fieldValue === '') {
		return null;
	} else if (!URL.test(fieldValue)) {
		return 'invalidURL';
	} else {
		return null;
	}
};

export const GET_PHONE_NUMBER_ERROR_MESSAGE = (fieldValue) => {
	if (fieldValue === '') {
		return 'fieldRequired';
	} else if (!matchIsValidTel(fieldValue)) {
		return 'invalidPhoneNumber';
	} else {
		return null;
	}
};
