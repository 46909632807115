import { useTranslation } from '../../../../features/localization/hooks';

const Item = () => {
	const t = useTranslation('supportAndFaq');
	return (
		<div>
			<p>{t('item.content')}</p>
		</div>
	);
};

Item.title = 'supportAndFaq.item.title';
Item.id = 'item';

export default Item;
