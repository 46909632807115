export const RE_CAPTCHA_SITE_KEY = '6LdponkmAAAAAL8eUTth35iff7dK9TjZp75nBbTj';

export const envSuffixes = {
	DEV: 'dev',
	TEST: 'test',
};

export const baseUrls = {
	DEV: 'https://dev.oloopo.com:6001/api/rest',
	TEST: 'https://test.oloopo.com:6001/api/rest',
	PROD: 'https://oloopo.com:6001/api/rest',
};
