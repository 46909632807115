import aboutUs from './aboutUs';
import careers from './careers';
import common from './common';
import tutorials from './tutorials';
import landingPage from './landingPage';
import supportAndFaq from './supportAndFaq';
import validationMessage from './validationMessage';
import cookies from './cookies';

export default {
	translation: { aboutUs, careers, common, landingPage, supportAndFaq, validationMessage, tutorials, cookies },
};
