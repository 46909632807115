const PersonalDataStorage = () => {
	return (
		<div>
			<p>
				Nestanoví-li právní úprava nebo Vámi udělený souhlas (je-li základem zpracování tento souhlas) specifickou dobu
				zpracovávání Vašich osobních údajů, a to např. v důsledku výkonu Vašich práv jako subjektu údajů, budeme
				zpracovávat Vaše osobní údaje po přiměřenou dobu potřebnou k naplnění účelů jejich zpracovávání, které jsou
				uvedeny v těchto zásadách.
			</p>
			<p>
				Za takovou přiměřenou dobu obvykle považujeme délku promlčecí doby, která v závislosti na aplikovatelném právu,
				může dosahovat až 6 let od posledního zpracování předmětných osobních údajů, prodlouženou o dodatečný 1 rok za
				účelem umožnění organizačných a technických operací souvisejících s ukončením zpracování Vašich osobních údajů.
			</p>
			<p>
				Právní úprava může stanovit delší dobu zpracování osobních údajů, např. jsme nuceni zpracovávat Vaše osobní
				údaje tvořící součást daňových dokladů spolu s těmito doklady po dobu 10 let od vydání těchto dokladů.
			</p>
			<p>
				Toto ustanovení nijak neomezuje výkon Vašich práv jako subjektu údajů nebo Vašich práv jako adresáta
				marketingové komunikace dle aplikovatelné úpravy e-commerce, včetně práva odvolat svůj souhlas se zpracováním
				Vašich osobních údajů a přijímáním marketingových sdělení.
			</p>
		</div>
	);
};

PersonalDataStorage.title = 'Jak dlouho uchováváme Vaše osobní údaje?';
PersonalDataStorage.id = 'personalDataStorage';

export default PersonalDataStorage;
