import { useTranslation } from '../../../../features/localization/hooks';

const LowValue = () => {
	const t = useTranslation('supportAndFaq');
	return (
		<div>
			<p>{t('lowValue.content')}</p>
		</div>
	);
};

LowValue.title = 'supportAndFaq.lowValue.title';
LowValue.id = 'lowValue';

export default LowValue;
