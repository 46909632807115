const LoopFailure = () => {
	return (
		<div>
			<p>
				Přes veškerou naši snahu se může stát, že se výměna nepodaří a vy obdržíte předmět poškozený, neodpovídající
				svému popisu na platformě, jiný předmět nebo zásilku nebo předmět vůbec neobdržíte.
			</p>
			<p>
				OLOOPO je v případě takovéto problému s realizovanou výměnou připraveno poskytnout zákonnou součinnost Vám i
				osobám či orgánům příslušným k šetření tohoto incidentu nebo rozhodovat o Vašich nárocích spojených s tímto
				incidentem. OLOOPO v obdobných případech na žádost orgánu zpřístupní informace týkající se předmětné výměny a
				jejich účastníků. OLOOPO plně podporuje jakoukoli Vaší legitimní iniciativu směřující k vyšetření Vašich
				podezření spojených s jakýmkoli incidentem spojeným s využitím služeb OLOOPO nebo k vymožení Vašich práv
				spojených s incidentem.
			</p>
			<p>
				VEZMĚTE, PROSÍM, NA VĚDOMÍ, ŽE OLOOPO NENÍ NIJAK ODPOVĚDNÉ ZA NESOULAD PŘEDMĚTU S JEHO POPISEM, JEHO POŠKOZENÍ
				NEBO ZTRÁTU BĚHEM PŘEPRAVY, NEBO PŘÍPADNÉ PODVODNÉ JEDNÁNÍ UŽIVATELŮ PLATFORMY OLOOPO.
			</p>
			<p>
				Rovněž vezměte na vědomí, že smlouva, podle které jste v rámci potvrzeného řetězce akceptací povinni převést
				vlastnické právo k svému předmětu na jiného účastníka, a smlouva, podle které je převáděn Vámi akceptovaný
				předmět do Vašeho vlastnictví, jsou vzájemně nezávislé smlouvy mezi různými subjekty, a tedy mezi nároky
				vyplývající z problému s realizovanou výměnou nepatří vrácení předmětu odesílaným postiženým uživatelem.
			</p>
			<p>
				V souladu s těmito podmínkami uživateli negativně postiženým jim nezaviněným problémem s realizovanou výměnou
				náleží právo:
			</p>
			<ul>
				<li>
					v případě, že tento problém je způsoben porušením povinností odesílajícího účastníka řetězce akceptací dle
					těchto podmínek, na úhradu rozdílu ceny mezi běžnou cenou skutečně dodaného předmětu a běžnou cenou předmětu
					dle popisu uvedeného na platformě. Alternativně, vrátí-li postižený uživatel obdržený předmět odesílajícímu
					účastníkovi řetězce výměn, má právo na úhradu celé běžné ceny předmětu, který měl obdržet, dle popisu
					uvedeného na platformě OLOOPO;
				</li>
				<li>
					v případě, že tento problém je způsoben pochybením smluvního přepravce společnosti OLOOPO, na jakoukoli
					kompenzaci, kterou tento přepravce poskytne společnosti OLOOPO na základě reklamace.
				</li>
			</ul>
			<p>
				Nad rámec uvedeného se uživatel postižený problémem s realizovanou výměnou vzdává svých jiných práv a nároků
				vůči společnosti OLOOPO a uživatelům platformy a služeb OLOOPO, ať už vyplývají ze smlouvy či jakéhokoli
				právního předpisu. Vezměte, prosím, na vědomí, že Vaše nároky spojené s problémem s realizovanou výměnou se
				omezují pouze na ty výslovně uvedené v těchto podmínkách.
			</p>
		</div>
	);
};

LoopFailure.title = 'Co když výměna nedopadla, jak měla?';
LoopFailure.id = 'loopFailure';

export default LoopFailure;
