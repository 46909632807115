import Box from '@mui/material/Box';
import VideoCamIcon from '@mui/icons-material/VideocamOutlined';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

const DurationTag = ({ duration }) => (
	<Box
		sx={{
			display: 'flex',
			backgroundColor: 'rgba(0, 0, 0, 0.5)',
			flexDirection: 'row',
			borderRadius: '16px',
			paddingX: '12px',
			paddingY: '4px',
		}}>
		<VideoCamIcon sx={{ color: 'white' }} />
		<Typography sx={{ color: 'white', marginLeft: '4px' }}>{duration}</Typography>
	</Box>
);

DurationTag.propTypes = {
	duration: PropTypes.string,
};

export default DurationTag;
