const Guarantee = () => {
	return (
		<div>
			<p>
				OLOOPO Vám při registraci Vašeho předmětu může nabídnout poskytnutí OLOOPO Garance v souvislost s konkrétním
				registrovaným předmětem, a to za cenu, která Vám bude oznámena spolu s nabídkou.
			</p>
			<p>Vašim přijetím nabídky OLOOPO Garance a úhradou ceny této zvláštní služby se OLOOPO zavazuje k:</p>
			<ul>
				<li>
					Zajištění přepravy předmětu od Vás do OLOOPO provozovny způsobem stanoveným v nabídce, a to za účelem
					provedení kontroly předmětem ze strany společnosti OLOOPO,
				</li>
				<li>
					Realizaci kontroly Vašeho předmětu, a to za účelem potvrzení souladu jeho stavu s popisem, který jste uvedli
					na OLOOPO platformě,
				</li>
				<li>
					Potvrdí-li kontrola souladnost aktuálního stavu předmětu a jeho popisu, zveřejnění Vaší nabídky výměny tohoto
					předmětu s označením „OLOOPO Garance“ nebo obdobného významu a uložení předmětu v OLOOPO skladu po dobu 30 dní
					s možností prodloužení za dodatečný poplatek,
				</li>
				<li>
					V případě realizace výměny v důsledku uzavření a potvrzení řetězce akceptací s uloženým předmětem, zajištění
					přepravy Vašeho předmětu z OLOOPO skladu k uživateli, který akceptoval nabídku výměny Vašeho předmětu a
					automatické udělení plného hodnocení ve Vašem profilu;
				</li>
				<li>
					V případě realizace výměny v důsledku uzavření a potvrzení řetězce akceptací s daným předmětem, přičemž
					uživatel, který akceptoval nabídku výměny Vašeho předmětu, bude postižen problémem s realizovanou výměnou
					vzniklým v důsledku nesouladu mezi stavem předmětu a jeho popisem, který společnost OLOOPO měla a mohla
					odhalit během kontroly, společnost OLOOPO za Vás uhradí nárok uživatele postiženého problémem s realizovanou
					výměnou v rozsahu stanoveném OLOOPO obchodními podmínkami
				</li>
				<li>
					Potvrdí-li kontrola nesouladnost aktuálního stavu předmětu a jeho popisu, s Vaším souhlasem bude realizována
					úprava popisu předmětu způsobem odpovídajícím aktuálnímu stavu Vašeho předmětu,
				</li>
				<li>
					Potvrdí-li kontrola nesouladnost aktuálního stavu předmětu a jeho popisu a Vy neudělíte souhlas s úpravou
					popisu předmětu, nebo skončí-li úložná doba Vašeho předmětu, aniž byste ji prodloužili, společnost OLOOPO
					zajistí přepravu Vašeho předmětu z OLOOPO skladu zpět k Vám způsobem stanoveným v nabídce.
				</li>
			</ul>
			<p>
				OLOOPO realizuje kontrolu předmětů výhradně v rozsahu potvrzení rozsahu zjevných vad odhalitelných kontrolou
				kompletnosti předmětu, jeho estetického stavu a základní funkčnosti předmětu (např. potvrzení možnosti zapnout
				vypnout elektrické/elektronické zařízení, funkčnosti jeho displejů a ovládacích prvků). Společnost OLOOPO není
				povinna realizovat kontrolu nad rámec uvedeného. Vadou, kterou společnost OLOOPO měla a mohla odhalit během své
				kontroly a za kterou odpovídá dle podmínek OLOOPO Garance, se rozumí vada odhalitelná v rámci kontroly ve výše
				vymezeném rozsahu. Jiné vady nejsou OLOOPO Garancí kryté a odpovědnost uživatele nabízejícího daný předmět za
				tyto vady je zachována v rozsahu OLOOPO Obchodních podmínek.
			</p>
		</div>
	);
};

Guarantee.title = 'Co je to OLOOPO Garance?';
Guarantee.id = 'guarantee';

export default Guarantee;
