const ProvidedServices = () => {
	return (
		<div>
			<p>
				Společnost OLOOPO provozuje za stanovených podmínek online platformu, která Vám, jako registrovanému uživateli
				po splnění našich podmínek a registraci předmětu nabízeného Vámi k výměně, umožňuje prohlížet nabídky výměny od
				jiných OLOOPO uživatelů.
			</p>
			<p>
				V případě, že vy a jiný uživatel nebo uživatelé akceptujete své nabídky tak, že dojde k uzavření řetězce
				akceptací, umožní Vám OLOOPO platforma tuto výměnu jednoduše realizovat.
			</p>
			<p>
				OLOOPO za úplatu zprostředkuje přepravu vyměňovaných předmětů prostřednictvím stanoveného přepravce, přičemž
				budete v rámci aplikace informováni o průběhu a nutných krocích.
			</p>
			<p>
				OLOOPO je technologická platforma a zprostředkovatel. OLOOPO NIJAK NEODPOVÍDÁ ZA VYMĚŇOVANÉ PŘEDMĚTY, JEJICH
				DODÁNÍ, ANI INFORMACE O NICH UVEDENÉ V RÁMCI OLOOPO PLATFORMY. TUTO ODPOVĚDNOST NESE VŽDY UŽIVATEL NABÍZEJÍCÍ
				DANÝ PŘEDMĚT. ODPOVĚDNOST ZA PŘEPRAVU PAK NESE PŘEPRAVCE. Vaše nároky vůči třetím osobám však OLOOPO podpoří a
				poskytne Vám součinnost při jejich vymáhání.
			</p>
			<p>
				Čtěte, prosím, dále podrobnosti o podmínkách Vaší účasti na OLOOPO platformě, podmínkách platných v rámci
				uzavřených výměn a jejich realizace i o zvláštních OLOOPO službách nabízených s cílem Vám užívaní OLOOPO
				platformy a výměnu Vašich předmětů ulehčit, zpříjemnit, zefektivnit a poskytnout Vám dodatečnou ochranu.
			</p>
		</div>
	);
};

ProvidedServices.title = 'Jaké služby zajišťuje OLOOPO?';
ProvidedServices.id = 'providedServices';

export default ProvidedServices;
