const ProvidedPrivacyData = () => {
	return (
		<div>
			<p>
				V rámci využívání platformy a služeb společnosti OLOOPO nebo při Vaší komunikací s ní dochází k shromažďování a
				zpracovávání následujících kategorií Vašich osobních údajů:
			</p>
			<p>
				IDENTIFIKAČNÍ ÚDAJE – Informace, podle kterých Vás identifikujeme jako individuální osobu, zejména jméno,
				příjmení, pohlaví, datum (rok) narození, adresa bydliště, doručovací adresa nebo jiný umělý identifikátor
				vytvořený pro Vaší identifikaci.
			</p>
			<p>
				KONTAKTNÍ ÚDAJE – Informace umožňující Vaše kontaktování a komunikaci s Vámi, zejména e-mailová adresa,
				telefonní číslo, identifikátor na sociálních sítích nebo komunikačních aplikacích.
			</p>
			<p>
				REGISTRAČNÍ ÚDAJE – Informace využívané nebo generované prostřednictvím Vašeho uživatelského účtu, zejména
				uživatelské jméno, heslo, profilový obrázek, avatar, preference nastavení, jiné údaje uložené v rámci Vašeho
				uživatelského účtu.
			</p>
			<p>
				TRANSAKČNÍ ÚDAJE – Informace týkající se Vašich interakcí s OLOOPO platformou a využití OLOOPO služeb – údaje
				týkající se Vašeho chování na Platformě, například informace o zobrazeném obsahu, jak dlouho jste danou činností
				strávili, na co jste klikli, údaje o Vaší interakci s e-maily a upozorněními, které vám posíláme, například
				jestli e-mail otevřete nebo jestli kliknete na některý z odkazů, a dále údaje týkající se Vašich nabídek výměny
				(vč. veškerých údajů o předmětech a jejich popisů), Vašich uložení zobrazovaných předmětů do sekce oblíbené,
				Vašich akceptací nabídek výměny předmětů, Vašeho potvrzení/odmítnutí/nepotvrzení uzavřeného řetězce výměn, údaje
				o akceptovaných/ neakceptovaných nabídkách našich služeb.
			</p>
			<p>
				PLATEBNÍ ÚDAJE – Informace umožňující autorizaci a provedení platby za OLOOPO služby, případně refundace za naše
				služby, zejména údaje o Vaší platební kartě nebo jiné verifikační údaje vyžadované pro realizaci platby jinými
				platebním prostředky.
			</p>
			<p>
				Vezměte, prosím, v úvahu, že platby prostřednictvím platební karty jsou realizovány prostřednictvím systému
				GPWebpay. Informace o Vaší platební kartě tedy nebudou poskytnuty přímo společnosti OLOOPO a tato k nim nebude
				mít přístup. Víc informací o zpracování Vašich údajů ze strany GPWebpay můžete nalézt zde:
				<a href="https://www.globalpayments.cz/cs-cz/ochrana-osobnich-udaju/" target="_blank" rel="noreferrer">
					https://www.globalpayments.cz/cs-cz/ochrana-osobnich-udaju
				</a>
			</p>
			<p>
				METADATA, ÚDAJE O ZAŘÍZENÍCH – Informace o zařízení, prostřednictvím kterého používáte OLOOPO platformu,
				metadata vašeho síťového připojení a také informace plynoucí z těchto dat. Tyto informace zahrnují například
				operační systém, internetový prohlížeč, nebo aplikaci, rozlišení obrazovky, IP adresu a další.
			</p>
			<p>
				ÚDAJE O POLOZE – Přesná poloha Vašeho zařízení, přičemž tato je stanovená na základě geolokačních dat z Vašeho
				zařízení. Tyto údaje zpracováváme pouze na základě Vašeho souhlasu, za účelem poskytnutí našich služeb nebo
				funkcí naší platformy a v nezbytně nutném rozsahu (např. umožnění výběru místa odeslání Vašeho předmětu). Je
				možné, že přístup k údajům o Vaší poloze budeme potřebovat i když nebudete mít aplikaci otevřenou (na pozadí).
				Při udělování souhlasu budete o této skutečnosti vždy informováni.
			</p>
			<p>
				KOMUNIKACE – Veškerá písemná, elektronická nebo verbální komunikace mezi Vámi a společností OLOOPO nebo Vámi a
				jiným uživateli (je-li realizována prostřednictvím platformy OLOOPO), a to včetně metadat, poznámek, shrnutí a
				jiných odvozených dat.
			</p>
			<p>
				RECENZE A ZPĚTNÁ VAZBA – Hodnocení vaší zkušenosti s platformou nebo službami OLOOPO, vč. odpovědí na konkrétní
				otázky k tomuto tématu a obecná zpětná vazba nebo obsah recenze, hodnocení zkušeností ve vztahu k výměnám a
				jiným uživatelům.
			</p>
			<p></p>
		</div>
	);
};

ProvidedPrivacyData.title = 'Jaké osobní údaje nám poskytujete?';
ProvidedPrivacyData.id = 'providedPrivacyData';

export default ProvidedPrivacyData;
