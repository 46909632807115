import swap from '../../../../assets/images/swap.svg';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Button from '@mui/material/Button';
import { useLocalizedDownloadAppOptions, useTranslation } from '../../../../features/localization/hooks';
import ButtonPrimary from '../../../../common/Components/ButtonPrimary';
import { getAgentSystem, openInNewTab } from '../../../../features/navigation/utils';
import { platforms } from '../../../../features/navigation/constants';

const IntroSectionMD = () => {
	const t = useTranslation('landingPage');
	const downloadAppOptions = useLocalizedDownloadAppOptions();
	return (
		<>
			{/* TEXT + CTA BUTTON SECTION */}
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					background: '#FFF',
					width: '400px',
					zIndex: 11,
				}}>
				{/* WORDS SUB-SECTION */}
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						padding: '16px',
					}}>
					{/* TEXT SUB-SECTION */}
					{[t('claimDontBuy'), t('claimOloopoIt')].map((text) => (
						<Typography
							key={text}
							sx={{
								whiteSpace: 'nowrap',
								fontFamily: 'KohinoorBangla',
								fontSize: '64px',
								fontWeight: 700,
								lineHeight: '96px',
								letterSpacing: '0em',
								textAlign: 'left',
							}}>
							{text}
						</Typography>
					))}
				</div>
				<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
					<Typography
						sx={{
							fontFamily: 'KohinoorBangla',
							fontSize: '22px',
							fontWeight: 400,
							lineHeight: '33px',
							letterSpacing: '0em',
							textAlign: 'left',
							maxWidth: '364px',
							paddingTop: '24px',
						}}>
						{t('claimIntro')}
					</Typography>
				</div>
				{/* TEXT SUB-SECTION */}
				<div
					style={{
						paddingTop: '64px',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'flex-start',
						alignItems: 'center',
					}}>
					{getAgentSystem() === platforms.UNKNOWN ? (
						downloadAppOptions.map(({ code, link, icon, heightRatio }) => {
							const height = Math.round(48 * heightRatio);
							return (
								<Button onClick={openInNewTab(link)} key={code} sx={{ marginX: '8px', padding: 0, height }}>
									<img style={{ height }} src={icon} alt={code} />
								</Button>
							);
						})
					) : (
						<ButtonPrimary label={t('downloadBtn')} onClick={openInNewTab('http://tst.oloopo.com:8080')} />
					)}
				</div>
			</div>
			{/* IMAGE SECTION */}
			<div
				style={{
					width: '700px',
					background: '#FFF',
				}}>
				<img src={swap} alt={'swap'} style={{ width: '200%', position: 'relative', left: '-40%' }} />
			</div>
			<KeyboardArrowDownIcon
				sx={{
					fontSize: '40px',
					position: 'absolute',
					bottom: '0',
					left: '50%',
					transform: 'translateX(-50%)',
					cursor: 'pointer',
					color: 'color.primary',
				}}
				onClick={() => {}}
			/>
		</>
	);
};

export default IntroSectionMD;
