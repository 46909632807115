export default {
	title: 'Privacy Policy',
	cookieSettings: 'Cookie settings',
	disclaimer: {
		textPartOne:
			'We use cookies and similar technologies to provide better functionality. By clicking Ok or enabling the option in the Cookie settings section, you agree to what is set out in our  ',
		textLink: 'Cookie Policy.',
		textPartTwo:
			' To change your preferences or withdraw your consent, please update your settings in the Cookie Settings section.',
	},
	category: {
		essential: {
			title: 'Essential',
			description: 'These items are necessary for the website to work.',
		},
		marketing: {
			title: 'Marketing',
			description:
				'These items help deliver advertising that’s more relevant to you. They may also be used to limit the number of times you see an ad and measure the effectiveness of ad campaigns. These are third party cookies provided by our advertising partners.',
		},
		personalization: {
			title: 'Personalization',
			description:
				'These items let the website remember choices you make (like user name, language, or region) and personalize features for you. For example, we may show you different content depending on which of our sites you’ve visited and content you’ve read.',
		},
		analytics: {
			title: 'Analytics',
			description:
				'These items help us understand visitor interactions, measure website performance, and spot potential technical issues. These don’t include essential cookies that provide incidental analytics while making the website work.',
		},
	},
	enableAll: 'Enable all',
	disableAll: 'Disable all',
	settingsSave: 'Save settings',
};
