export default {
	title: 'About us',
	aboutHeader: 'Our mission is to enable people to get what they want in exchange for what they no longer need.',
	aboutTextOne:
		'The idea of a circular barter came to our founder and CEO, Marián Seifert, when, as a student,\
		he wanted to get textbooks for the new year in exchange for textbooks from the previous year.',
	aboutTextTwo:
		'Development of the platform began in 2020 and the company was officially founded on Earth Day 2022.\
		In February 2023, a patent application protecting our revolutionary algorithm was filed.',
	boardTitle: 'Executive Board',
};
