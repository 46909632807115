const UserAccountCreation = () => {
	return (
		<div>
			<p>
				Úspěšným dokončením registrace uzavíráte smlouvu s OLOOPO jejíž předmětem je Vaše právo využívat OLOOPO
				platformu a služby za podmínek stanovených zde.
			</p>
			<p> Úspěšným dokončením registrace rovněž prohlašujete, že:</p>
			<ul>
				<li>jste osobou starší 18 let, a</li>
				<li>máte plnou způsobilost k právnímu jednání, a</li>
				<li>nemáte již založený jiný OLOOPO uživatelský účet, a</li>
				<li>
					neexistuje žádná zákonná, smluvní či jiná překážka uzavření smlouvy mezi Vámi a OLOOPO umožňující využívání
					OLOOPO platformy a služeb, a
				</li>
				<li>
					neexistuje žádná zákonná, smluvní či jiná překážka uzavření smlouvy mezi Vámi a jinými uživateli umožňující
					výměnu předmětů za podmínek uvedených dále, a
				</li>
				<li>
					veškeré údaje, které jste uvedli, jsou pravdivé a kompletní, přičemž dojde-li kdykoli za trvání existence
					Vašeho uživatelského účtu k jejich změně, neprodleně je aktualizujete v rámci svého uživatelského účtu, a
				</li>
				<li>plně a bezvýhradně souhlasíte s těmito podmínkami a budete se jimi řídit, a</li>
				<li>udělujete OLOOPO právo zpracovávat Vaše osobní údaje v souladu se Zásadami ochrany osobních údajů, a</li>
				<li>
					že svůj uživatelský účet, platformu a služby OLOOPO budete využívat pouze k vlastním soukromým účelům,
					sledující pouze legitimní účely využívat povoleným způsobem funkcionality OLOOPO platformy a OLOOPO služby, a
				</li>
				<li>
					máte veškerá práva na jakýkoli obsah, který umístíte na platformu, přičemž jeho využitím v rámci platformy
					nedojde k zásahu do práv třetích osob, a
				</li>
				<li>
					že svůj uživatelský účet, platformu a služby OLOOPO nebudete využívat k podvodným účelům, nebo k
					automatizovanému anebo manuálnímu sběru dat a informací jakkoli souvisejících s uživateli, platformou samotnou
					či službami poskytovanými jejím prostřednictvím, nebo k analýze zdrojového kódu platformy nebo její části,
					reverznímu inženýrství nebo kopírování či jiné replikaci zdrojového kódu platformy, nebo k šíření nevyžádaných
					marketingových a jiných sdělení nesouvisejících se službami OLOOPO, nebo k šíření jakéhokoli nezákonného,
					škodlivého, urážlivého, vulgárního, pornografického, jinak nevhodného, rasistického nebo jinak nenávistného
					obsahu.
				</li>
			</ul>
		</div>
	);
};

UserAccountCreation.title = 'Co pro Vás znamená založení uživatelského účtu?';
UserAccountCreation.id = 'userAccountCreation';

export default UserAccountCreation;
