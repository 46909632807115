export default {
	price: {
		title: 'How much does it cost to use OLOOPO?',
		content:
			'The mobile application is completely free to download and use. In the event of a successful exchange\
			you only pay for the delivery and shipping of the item you selected.',
	},
	chance: {
		title: "How can I increase my chances of getting the items I'm interested in?",
		content:
			"You can increase your chances with a high-quality advertisement of your item with well-lit photos and,\
			last but not least, a good rating of your profile. If you don't have any ratings, you can try the OLOOPO\
			Guarantee service, where after the exchange you will automatically get one 5* rating. The OLOOPO Guarantee\
			will increase the attractiveness of your ad. If you offer more than one item on our platform, you can accept\
			the ad you are interested in with each of them (if their value is sufficient). We recommend watching the video\
			How to make an attractive ad in the Tutorials section on our website oloopo.com",
	},
	lowValue: {
		title:
			'While browsing, I found an item that I am very interested in, but my current offer is not attractive enough.\
			What to do?',
		content:
			"You can save the item you desire to your Favorites by pressing the star. Then you can upload to OLOOPO one\
			or more attractive items and subsequently accept this saved item with each of them. It's easy, just tap the\
			Accepted section on the bottom bar and switch to the Favorites subsection inside it. Here you can accept the\
			selected item with all relevant items you offer.",
	},
	guarantee: {
		title: 'What is OLOOPO Guarantee and when is it worth buying?',
		content:
			'OLOOPO Guarantee is a service to verify the condition of your item by independent experts.\
			After purchasing the service, we will send you instructions for sending your item to the OLOOPO warehouse\
			by e-mail. Here, its condition and the credibility of the description will be checked within 2 working days.\
			Subsequently, the object will be stored - until its successful exchange or your request for its return.',
	},
	loop: {
		title: "Exchange loop closed. What's next?",
		content_1:
			'In the event that you have purchased OLOOPO Guarantee for the item offered to you, you do not have to do\
			anything but wait for the item you selected to be delivered to you.',
		content_2:
			'If you have not purchased OLOOPO Guarantee for the item you are offering, you must pack it safely and send it.\
			Instructions for shipping will be sent to you by e-mail. The same steps will be taken by the owner of the item\
			you selected and it will be subsequently delivered.',
	},
	item: {
		title: 'What if the selected item was not delivered to me?',
		content:
			'Each exchange creates a series of binding contracts, and each participant in the exchange has a legal\
			obligation to send the item they have agreed to send. If they fail to do so, they commit an offence against\
			the Criminal Code and can be sentenced to 2 years in prison. In case of theft, OLOOPO will assist the harmed\
			customer in cooperation with the police of the Czech Republic.',
	},
	maximalChance: {
		title: 'How do I maximise the chance that I will receive an item that matches its actual description?',
		content: 'Choose items with the OLOOPO Guarantee or offers from highly rated customers.',
	},
	increaseRating: {
		title: 'How can I increase my rating?',
		content:
			'Only customers who received the item you offered can rate your profile, after the exchange. You can also\
			increase your rating by purchasing the OLOOPO Guarantee. After each successful exchange with purchased\
			OLOOPO Guarantee, you will automatically receive one maximum rating.',
	},
	lowerRaring: {
		title: 'A user to whom I sent the item gave me unreasonably low rating. What to do now?',
		content:
			'In this case, contact our customer center immediately. If it is found that the given user regularly rates\
			inadequately, we will contact them immediately. In extreme cases, it is possible to block the user,\
			either temporarily or permanently.',
	},
	limits: {
		title: 'What if I want to exchange an item larger than the available size or weight limits?',
		content:
			'Shipping limits are set by our partner parcel delivery company. Items that exceed these limits cannot be\
			advertised or exchanged. Advertising such an item is a violation of the terms and conditions,\
			in which case our customer center must be contacted immediately.',
	},
	otherProblem: {
		title: 'I have another problem.',
		content:
			'If your problem is not described here, contact our customer center using the form below.\
			We will contact you in the shortest possible time.',
	},
	otherProblemForm: {
		name: 'Name',
		email: 'Email',
		description: 'Description of the problem',
		submitForm: 'Submit an inquiry',
	},
};
