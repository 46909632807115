import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { resolveBaseUrl } from './utils';

export const useHandleNavigate = (onClickHandler) => {
	const navigate = useNavigate();
	return useCallback(
		(route) => (/* EVENT */) => {
			if (onClickHandler) {
				onClickHandler();
			}
			navigate(route);
		},
		[onClickHandler],
	);
};

export const useEnvironmentBaseUrl = () => {
	const origin = window.location.origin;
	return useMemo(() => resolveBaseUrl(origin), [origin]);
};
