import React, { createContext, useState, useCallback } from 'react';
import { assoc, lensPath, set } from 'ramda';

import { GET_PHONE_NUMBER_ERROR_MESSAGE } from '../features/validations/validations';
import { careersFormStructure } from '../Pages/Careers/components/careersFormStructure';
import { defaultCareersFormState, defaultValidationState } from '../Pages/Careers/components/careersFormStructure';
import {useSubmitForm} from "../features/restApi/hooks";

export const CareersContext = createContext();

const CareersContextProvider = ({ children }) => {
	const [formData, setFormData] = useState(defaultCareersFormState);
	const [formValidation, setFormValidation] = useState(defaultValidationState);
	const [isCallInProgress, setIsCallInProgress] = useState(false);
	const [isSubmitted, setSubmitted] = useState(false);

	const handleInputChange = useCallback(
		(event) => {
			if (event.target) {
				const { name, value } = event.target;
				setFormData(assoc(name, value, formData));
			} else {
				setFormValidation(
					assoc(
						careersFormStructure.generalInformation.PHONE_NUMBER,
						GET_PHONE_NUMBER_ERROR_MESSAGE(event),
						formValidation,
					),
				);
				setFormData(assoc(careersFormStructure.generalInformation.PHONE_NUMBER, event, formData));
			}
		},
		[formData, formValidation],
	);

	const handleCheckBoxInputChange = useCallback(
		(event) => {
			const { name, checked } = event.target;
			setFormData(
				set(lensPath([careersFormStructure.employerBrandingSurvey.BRANDING_SURVEY, name]), checked, formData),
			);
		},
		[formData, setFormData],
	);

	const handleClickReCaptcha = useCallback(
		(reCaptchaValue) => setFormData((prevFormData) => ({ ...prevFormData, reCaptchaValue })),
		[setFormData],
	);

	const handleSubmitForm = useSubmitForm('/careers/handle', formData, setSubmitted, () => setIsCallInProgress(true));


	const careersContextValue = {
		formData,
		formValidation,
		handleInputChange,
		handleCheckBoxInputChange,
		handleClickReCaptcha,
		setFormValidation,
		setFormData,
		handleSubmitForm,
		isCallInProgress,
		isSubmitted
	};

	return <CareersContext.Provider value={careersContextValue}>{children}</CareersContext.Provider>;
};

export default CareersContextProvider;
