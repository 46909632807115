const Cookies = () => {
	return (
		<div>
			<p>
				Technologie cookies a obdobné technologie umožňují uložení malých souborů na Vašem zařízení, přičemž informace
				uložené v rámci těchto souborů můžou sloužit k Vaší identifikaci a pro shromažďování informací o Vás a Vašem
				chování na OLOOPO platformě. Cookies nám umožňují personalizovat uživatelský zážitek a poskytnout lepší služby.
				Tyto soubory mohou obsahovat informace, jako jsou například identifikátory zařízení, údaje o přístupu a další
				technické informace.
			</p>
			<p>V zásadě lze identifikovat 3 typy cookies:</p>
			<ol>
				<li>
					Cookies nezbytně nutné pro řádné fungování OLOOPO platformy a jejich funcionalit, jako i pro poskytování
					našich služeb
				</li>
				<li>Cookies využívané k statistickým účelům a měření různých výkonnostních parametrů OLOOPO platformy</li>
				<li>Cookies využívané k marketingovým účelům</li>
			</ol>
			<p>
				Používaní druhé a třetí kategorie cookies v souvislosti s Vašim využíváním OLOOPO platformy a služeb je možné
				zakázat v příslušných nastavení v rámci OLOOPO platformy nebo v rámci Vašeho zařízení.
			</p>
			<p>
				Své předvolby týkající se digitální reklamy si můžete zkontrolovat a změnit u Aliance pro digitální reklamu
				(DAA) na stránce{' '}
				<a href="http://optout.aboutads.info/" target="_blank" rel="noreferrer">
					http://optout.aboutads.info/
				</a>{' '}
				nebo u Evropské aliance pro interaktivní digitální reklamu (EDAA) na stránce{' '}
				<a href="http://www.youronlinechoices.com/" target="_blank" rel="noreferrer">
					http://www.youronlinechoices.com/
				</a>
				.
			</p>
			<p>Více informací o použití cookies v rámci OLOOPO platformy naleznete tady: LINK</p>
		</div>
	);
};

Cookies.title = 'Co jsou to Cookies a jak tato technologie souvisí s mým osobními údaji?';
Cookies.id = 'cookies';

export default Cookies;
