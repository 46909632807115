import CenteredSVG from '../../../../common/Components/CenteredSVG';
import Citation from './Citation';
import swapExampleEduardAndZuzana from '../../../../assets/images/swapExampleEduardAndZuzana.svg';
import swapExampleJana from '../../../../assets/images/swapExampleJana.svg';
import swapExampleTomas from '../../../../assets/images/swapExampleTomas.svg';
import { useTranslation } from '../../../../features/localization/hooks';

const StorySection = () => {
	const t = useTranslation('landingPage');
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				background: '#FFF',
				paddingTop: '64px',
			}}>
			<CenteredSVG src={swapExampleEduardAndZuzana} />
			<div style={{ marginTop: '-32px' }}>
				<Citation text={t('citationOne.text')} name={t('citationOne.name')} />
			</div>
			<div style={{ paddingTop: '52px' }}>
				<CenteredSVG src={swapExampleJana} />
			</div>
			<div style={{ marginTop: '-32px' }}>
				<Citation text={t('citationTwo.text')} name={t('citationTwo.name')} />
			</div>
			<div style={{ paddingTop: '52px' }}>
				<CenteredSVG src={swapExampleTomas} />
			</div>
			<div style={{ marginTop: '-32px' }}>
				<Citation text={t('citationThree.text')} name={t('citationThree.name')} />
			</div>
		</div>
	);
};

export default StorySection;
