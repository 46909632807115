import Typography from '@mui/material/Typography';

const TitlePrimary = ({ text }) => {
	return (
		<Typography
			sx={{
				color: 'typography.default',
				fontFamily: 'KohinoorBangla',
				fontSize: { xs: '22px', md: '34px' },
				fontWeight: 700,
				lineHeight: { xs: '33px', md: '51px' },
				letterSpacing: '0em',
			}}>
			{text}
		</Typography>
	);
};

export default TitlePrimary;
