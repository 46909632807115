const ExtraServices = () => {
	return (
		<div>
			<p>
				OLOOPO si vyhrazuje právo Vám v rámci platformy nabídnout své zvláštní služby, které mají za cíl zjednodušit,
				zpříjemnit nebo zefektivnit využívání OLOOPO platformy a služeb OLOOPO, případně poskytnout uživatelům
				dodatečnou ochranu.
			</p>
			<p>
				Rozsah zvláštních služeb OLOOPO je závislý pouze a výhradně na uvážení společnosti OLOOPO a žádný uživatel nemá
				nárok na obdržení nabídky některé ze zvláštních služeb OLOOPO. Společnost OLOOPO má právo kdykoli dočasně nebo
				trvale zastavit poskytování jakékoli své služby, přičemž však nabídky jejich poskytnutí akceptované uživatelem
				budou společností OLOOPO respektovány a služby budou poskytnuté v souladu s podmínkami účinnými v době přijetí
				jejich nabídky uživatelem.
			</p>
			<p>
				K dnešnímu dni společnost OLOOPO může uživatelům OLOOPO platformy nabídnout poskytnutí těchto zvláštních služeb,
				a to za podmínek uvedených pod příslušným odkazem:
			</p>
			<ul>
				<li>OLOOPO Garance </li>
			</ul>
		</div>
	);
};

ExtraServices.title = 'Jaké zvláštní služby poskytuje OLOOPO?';
ExtraServices.id = 'extraServices';

export default ExtraServices;
