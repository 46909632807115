import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useTranslation } from '../../../features/localization/hooks';
import { APPLICANT_AND_CANDIDATE_FILE_NAME } from '../constant';

const StyledAnchor = styled('a')(({ theme }) => {
	const style = { color: theme.palette.typography.primary };
	return {
		textDecoration: 'none',
		'&:link': style,
		'&:visited': style,
		'&:hover': style,
		'&:active': style,
	};
});

const Disclaimer = () => {
	const t = useTranslation('careers');
	return (
		<Box>
			<Typography
				sx={{
					color: 'typography.gray',
					fontFamily: 'KohinoorBangla',
					fontSize: { xs: '14px', md: '16px' },
					fontWeight: 400,
					lineHeight: { xs: '21px', md: '24px' },
					letterSpacing: '0em',
				}}>
				<>
					{t('form.disclaimer.description')}
					<StyledAnchor
						href={process.env.PUBLIC_URL + `/${APPLICANT_AND_CANDIDATE_FILE_NAME}`}
						download={APPLICANT_AND_CANDIDATE_FILE_NAME}>
						{t('form.disclaimer.link')}
					</StyledAnchor>
				</>
			</Typography>
		</Box>
	);
};

export default Disclaimer;
