import Box from '@mui/material/Box';
import { TitlePrimary, TextArea } from '../../../Layout/Page';
import { useTranslation } from '../../../features/localization/hooks';
import careersGraphic from '../../../assets/images/careersGraphic.svg';
import { BULLETS } from '../constant';

const CareersIntroSection = () => {
	const t = useTranslation('careers');
	return (
		<Box>
			<TitlePrimary text={t('introSectionHeader')} />
			<TextArea text={t('introSectionFirstText')} />
			<TextArea text={t('introSectionSecondText')} />
			<Box sx={{ marginTop: { xs: '32px' } }}>
				{BULLETS.map(({ key, img }) => (
					<Box
						key={key}
						sx={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'flex-start',
							alignItems: 'center',
							height: '60px',
							paddingLeft: { xs: '8px', md: '12px' },
						}}>
						<Box
							sx={{
								width: { xs: '50px', md: '66px' },
								height: { xs: '44px', md: '52px' },
								minWidth: { xs: '50px', md: '66px' },
								marginRight: { xs: '24px', md: '32px' },
							}}>
							<img src={img} alt="careers graphic" width="100%" height="100%" />
						</Box>
						<TextArea text={t(key)} topDivider="0px" bold />
					</Box>
				))}
			</Box>
			<Box sx={{ marginX: { xs: '0px' }, marginTop: { xs: '40px' } }}>
				<img src={careersGraphic} alt="careersGraphic" width="100%" height="100%" />
			</Box>
		</Box>
	);
};

export default CareersIntroSection;
