const styles = {
	buttonSecondary: {
		width: '100%',
		fontFamily: 'Kohinoor Bangla',
		display: 'flex',
		textTransform: 'none',
		alignItems: 'center',
		justifyContent: 'center',
		height: '48px',
		borderRadius: '16px',
		backgroundColor: 'white',
		borderWidth: '1px',
		borderColor: '#fa7007',
		color: '#fa7007',
		textAlign: 'center',
		cursor: 'pointer',
		fontWeight: '700',
		fontSize: '16px',
		autoCapitalize: 'none',
	},
	buttonDisabled: {
		cursor: 'not-allowed',
		backgroundColor: '#92a4ad',
	},
	buttonHover: {
		'&:hover, &:active': {
			backgroundColor: 'white',
			borderColor: '#fa7007',
		},
	},
};

export default styles;
