import { TitledContent } from '../../Layout/Page';
import PrivacySection from './components/PrivacySection';

const PrivacyApp = () => (
	<TitledContent title="Zásady ochrany soukromí">
		<PrivacySection />
	</TitledContent>
);

export default PrivacyApp;
