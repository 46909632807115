const styles = {
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	overflowX: 'hidden',
	width: '100vw',
};

const CenteredSVG = ({ src }) => (
	<div style={styles}>
		<img src={src} alt={src} />
	</div>
);

export default CenteredSVG;
