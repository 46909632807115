const ProcessingPersonalDataRights = () => {
	return (
		<div>
			<p>
				Společnost OLOOPO respektuje Vaše práva jako subjektu osobních údajů a zajistí, že budou tyto práva chráněna a
				dodržována v souladu s platnými právními předpisy. Máte právo požadovat následující:
			</p>
			<ol>
				<li>
					Přístup k osobním údajům: Máte právo požádat nás o potvrzení, zda zpracováváme vaše osobní údaje a o informace
					o následujících aspektech zpracovávání Vašich osobních údajů:
					<ol type="a">
						<li>účely, pro které zpracováváme vaše osobní údaje,</li>
						<li>kategorie vašich osobních údajů, které zpracováváme,</li>
						<li>
							seznam třetích stran, se kterými sdílíme vaše osobní údaje, zejména, pokud se tyto třetí strany nacházejí
							ve třetích zemích,
						</li>
						<li>
							plánovaná doba, po kterou budeme zpracovávat vaše osobní údaje, nebo alespoň jak stanovujeme dobu uchování
							osobních údajů,
						</li>
						<li>Vaše práva coby subjektu údajů podle GDPR,</li>
						<li>Vaše právo podat stížnost u dozorového úřadu,</li>
						<li>informace o tom, odkud jsme obdrželi vaše osobní údaje, pokud jsme je nezískali přímo od vás,</li>
						<li>jakékoliv informace o tom, že jste předmětem automatizovaného rozhodování (pokud k němu dochází),</li>
						<li>kdy byly osobní údaje předány do třetí země a jaká platí bezpečnostní opatření v souladu s GDPR.</li>
					</ol>
					V případě opakovaných žádostí si vyhrazujeme právo požadovat po Vás úhradu přiměřených nákladů spojených s
					vyřizováním Vašich žádostí.
				</li>
				<li>
					Opravu osobních údajů: Pokud jsou vaše osobní údaje neúplné, nepřesné nebo neaktuální, máte právo požadovat
					jejich opravu.
				</li>
				<li>
					Výmaz osobních údajů: Můžete požadovat, aby vaše osobní údaje byly vymazány, (plně anonymizovány) pokud jsou
					již nepotřebné pro účely, pro které byly původně shromážděny, nebo jsme k tomu povinni dle GDPR v důsledku
					Vašich námitek, nebo jsme Vaše osobní údaje zpracovávali neoprávněně, nebo jsme k tomu povinni dle jiného
					právního předpisu, nebo pokud jste odvolali souhlas se zpracováním osobních údajů. Vaši žádosti nemusíme
					vyhovět, pokud je jejich zpracování nutné za účelem výkonu práva na svobodu projevu a informací, nebo splnění
					právní povinnosti, která od nás vyžaduje uchování osobních údajů, nebo potřebujeme osobní údaje z důvodu
					určení, výkonu nebo obhajoby právních nároků.
				</li>
				<li>
					Omezení zpracování: Můžete požádat o omezení zpracování vašich osobních údajů, pokud se domníváte, že jsou
					nepřesné nebo nezákonné, nebo pokud je spor ohledně toho, zda jsou vaše osobní údaje oprávněně zpracovávány,
					nebo je potřebujeme výhradně z důvodu určení, výkonu nebo obhajoby právních nároků.
				</li>
				<li>
					Přenositelnost údajů: Pokud jsou vaše osobní údaje zpracovávány na základě vašeho souhlasu nebo plnění smlouvy
					a zpracování probíhá automatizovaně, máte právo požadovat přenos vašich osobních údajů do jiné společnosti
					nebo organizace.
				</li>
				<li>
					Odvolání souhlasu se zpracováním osobních údajů: Můžete kdykoliv odvolat svůj souhlas se zpracováním vašich
					osobních údajů, pokud jsou zpracovávány na základě vašeho souhlasu.
				</li>
			</ol>
			<p>
				Pokud máte zájem využít některého z těchto práv, nebo pokud máte jakékoli otázky nebo obavy týkající se
				zpracování vašich osobních údajů, můžete nás kontaktovat způsobem stanoveným v těchto zásadách.
			</p>
		</div>
	);
};

ProcessingPersonalDataRights.title = 'Jaké jsou Vaše práva v souvislosti s naším zpracováním osobních údajů?';
ProcessingPersonalDataRights.id = 'processingPersonalDataRights';

export default ProcessingPersonalDataRights;
