import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

const YoutubeTileTitleAndDescription = ({ isMainVideo, isEllipsis, title, description }) => (
	<Box sx={{ paddingTop: { xs: '16px', md: '24px' } }}>
		<Typography
			sx={{
				color: 'typography.default',
				fontFamily: 'KohinoorBangla',
				fontSize: { xs: '22px', md: isMainVideo ? '34px' : '26px' },
				fontWeight: 700,
				lineHeight: { xs: '33px', md: isMainVideo ? '51px' : '26px' },
				letterSpacing: '0em',
				marginBottom: '8px',
				WebkitBoxOrient: isEllipsis ? 'vertical' : 'none',
				display: '-webkit-box',
				overflow: 'hidden',
				WebkitLineClamp: 3,
			}}>
			{title}
		</Typography>
		<Typography
			sx={{
				color: 'typography.default',
				fontFamily: 'KohinoorBangla',
				fontSize: { xs: '16px', md: '22px' },
				fontWeight: 400,
				lineHeight: { xs: '33px', md: '33px' },
				letterSpacing: '0em',
				marginBottom: '8px',
				WebkitBoxOrient: isEllipsis ? 'vertical' : 'none',
				display: '-webkit-box',
				overflow: 'hidden',
				WebkitLineClamp: 3,
			}}>
			{description}
		</Typography>
	</Box>
);

YoutubeTileTitleAndDescription.propTypes = {
	isMainVideo: PropTypes.bool,
	isEllipsis: PropTypes.bool,
	title: PropTypes.string,
	description: PropTypes.string,
};

export default YoutubeTileTitleAndDescription;
