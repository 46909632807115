import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import ThumbnailImage from './ThumbnailImage';
import YoutubeTileTitleAndDescription from './YoutubeTileTitleAndDescription';

const YoutubeThumbnailTile = ({isMainVideo ,image, duration, title, description, onClick }) => (
	<Box onClick={onClick}>
		<ThumbnailImage image={image} duration={duration} />
		<YoutubeTileTitleAndDescription title={title} description={description} isEllipsis={!isMainVideo} />
	</Box>
);

YoutubeThumbnailTile.propTypes = {
	image: PropTypes.string,
	duration: PropTypes.string,
	title: PropTypes.string,
	description: PropTypes.string,
	onClick: PropTypes.func,
};

export default YoutubeThumbnailTile;
