import YoutubeVideoTile from './YoutubeVideoTile';
import YoutubeThumbnailTile from './YoutubeThumbnailTile';
import PropTypes from 'prop-types';

const YoutubeTileResolver = ({ isMainVideo, isVideo, title, description, video, onClick }) => {
	const { url, duration, image } = video;
	if (isVideo) {
		return (
			<YoutubeVideoTile description={description} title={title} url={url} onClick={onClick} isMainVideo={isMainVideo} />
		);
	} else {
		return (
			<YoutubeThumbnailTile
				isMainVideo={isMainVideo}
				title={title}
				description={description}
				image={image}
				duration={duration}
				onClick={onClick}
			/>
		);
	}
};

YoutubeTileResolver.propTypes = {
	isMainVideo: PropTypes.bool,
	isVideo: PropTypes.bool,
	title: PropTypes.string,
	description: PropTypes.string,
	video: PropTypes.object,
	onClick: PropTypes.func,
};

export default YoutubeTileResolver;
