import { languagesDefinitionMap, LANGUAGE_QUERY_PARAM_NAME, ORDERED_LANGUAGE_CODES } from './constants';
import resources from './resources';

const detectionConfig = {
	order: ['querystring', 'localStorage', 'navigator'],
	lookupQuerystring: LANGUAGE_QUERY_PARAM_NAME,
};

export default {
	detection: detectionConfig,
	fallbackLng: languagesDefinitionMap.EN.code,
	nonExplicitSupportedLngs: true,
	supportedLngs: ORDERED_LANGUAGE_CODES,
	resources,
};
