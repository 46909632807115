import { useTranslation } from '../../../../features/localization/hooks';

const Chance = () => {
	const t = useTranslation('supportAndFaq');
	return (
		<div>
			<p>{t('chance.content')}</p>
		</div>
	);
};

Chance.title = 'supportAndFaq.chance.title';
Chance.id = 'chance';

export default Chance;
