const AddItem = () => {
	return (
		<div>
			<p>
				Podmínkou využívání základní funkcionality platformy, a tedy prohlížení si nabídek výměny předmětů jiných
				uživatelů platformy a jejich akceptace nebo neakceptace, je registrace alespoň jednoho předmětu, který nabízíte
				k výměně vy. OLOOPO si vyhrazuje právo stanovit limit na počet registrovaných předmětů jedním uživatelským
				účtem.
			</p>
			<p> Registrovaným předmětem může být pouze předmět:</p>
			<ul>
				<li>který je movitým a hmotným předmětem; a</li>
				<li>
					který je ve Vašem výlučném vlastnictví a neexistuje jakékoli omezení bránící jeho převodu na jiného uživatele;
					a
				</li>
				<li>který spadá do jedné z kategorií uvedených na platformě; a</li>
				<li>
					který splňuje maximální hmotnostní a rozměrové limity stanovené přepravními podmínkami smluvního přepravce
					OLOOPO, jak jsou uvedeny níže; a
				</li>
				<li>
					jehož hodnota nepřesahuje maximální pojistné krytí dle přepravních podmínek smluvního přepravce OLOOPO, jak
					jsou uvedeny níže; a
				</li>
				<li>
					který jinak nespadá do kategorie předmětů vyloučených z přepravy stanovené přepravními podmínkami smluvního
					přepravce OLOOPO, jak jsou uvedeny níže; a
				</li>
				<li>
					který není nebezpečné, jedovaté, výbušné, těkavé, tekuté, leptavé, zdraví škodlivé nebo jinak nevhodné povahy,
				</li>
				<li>
					předmět, jehož distribuce a prodej nebyl zakázán na území České republiky nebo Evropské unie, jeho výrobcem,
					distributorem nebo veřejným orgánem; a
				</li>
				<li>
					který není nezákonné, škodlivé, urážlivé, vulgární, pornografické, jinak nevhodné, rasistické nebo jinak
					nenávistné povahy; a
				</li>
				<li>který nepodléhá rychlé zkáze; a</li>
				<li>
					který neporušuje práva duševního vlastnictví třetích osob nebo jinak klamavě indikuje svůj specifický původ,
					výrobce nebo kvalitu; a
				</li>
				<li>
					jehož prodej, distribuce nebo užití není vázáno na zvláštní podmínky, včetně věkového omezení nebo vázání na
					zvláštní povolení.
				</li>
			</ul>
			<p>
				Úspěšnou registrací předmětu do Vašeho uživatelského účtu souhlasíte, že registrovaný předmět bude zobrazován
				jiným uživatelům platformy jako Vaše závazná nabídka výměny tohoto registrovaného předmětu a v případě, bude-li
				tato nabídka některým uživatelem akceptována a dojde k uzavření řetězce akceptací obsahující Váš registrovaný
				předmět, zavazujete se realizovat výměnu registrovaného předmětu za podmínek uvedených v těchto podmínkách. Před
				samotnou registrací proto řádně zvažte skutečnost, že registrací zakládáte Váš smluvní závazek vůči jiným
				uživatelům. Tato skutečnost nemá vliv na Vaše právo Váš registrovaný předmět, jenž není součástí uzavřeného
				řetězce akceptací, kdykoli vymazat.
			</p>
			<p>
				Prosím, věnujte pozornost skutečnosti, že součástí registrace předmětu je poskytnutí jeho úplného a pravdivého
				popisu, včetně určení povinných parametrů, které jsou vyžadovány společností OLOOPO a nahrání nejméně jedné
				fotografie registrovaného předmětu. Nemůžete použit fotografii nalezenou na internetu a/nebo fotografii
				podobného předmětu. Úspěšnou registrací předmětu do Vašeho uživatelského účtu výslovně prohlašujete vůči
				společnosti OLOOPO a jiným uživatelům OLOOPO platformy, že popis předmětu zachycuje pravdivě a úplně jeho stav,
				a že tento popis je úplný a zcela pravdivý (a to včetně popisu v rámci odpovědí na případné dodatečné dotazy).
				Ukáže-li se, že toto Vaše prohlášení není pravdivé může to mít pro Vás negativní důsledky, zejména
			</p>
			<ul>
				<li>
					Vám může vzniknout odpovědnost vůči uživateli, který akceptoval Vaši nabídku v rámci následně potvrzeného
					řetězce akceptací, na základě Vámi poskytnutého popisu, a to v rozsahu uvedeném níže; a
				</li>
				<li>
					Vám vznikne povinnost uhradit společnosti OLOOPO smluvní pokutu ve výši 1.000,- Kč a dále zvýšených nákladů na
					přepravu, v případě porušíte-li svou povinnost uvádět pravdivé údaje tím, že uvedete nižší kategorii předmětu
					rozhodnou pro stanovení přepravních nákladů; a
				</li>
				<li>
					OLOOPO může bez dalšího vymazat registrovaný předmět, jehož popis se ukáže být nepravdivým nebo neúplným.
				</li>
			</ul>
			<p>
				Dojde-li k změně stavu předmětu po jeho registraci a způsobem činícím jeho popis nepravdivým nebo neúplným, je
				Vaší povinností tuto skutečnost neprodleně zohlednit aktualizací jeho popisu nebo jeho vymazáním, není-li
				předmět součástí uzavřeného řetězce akceptací. Je-li předmět již součástí uzavřeného, no nepotvrzeného řetězce
				akceptací, je Vaší povinností zabránit potvrzení.
			</p>
			<p>
				Vezměte, prosím, na vědomí, že uživatelé nabízející předměty na platformě OLOOPO negarantují jakékoli vlastnosti
				nebo stav předmětu nad rámec těch výslovně uvedených v popisu daného předmětu. Je-li pro Vaše rozhodnutí
				akceptovat nabídku výměny daného předmětu rozhodná informace, která není v popisu uvedená, prosím, kontaktujte
				nabízejícího uživatele s Vaším dotazem, ještě před akceptací jeho nabídky výměny předmětu.
			</p>
		</div>
	);
};

AddItem.title = 'Co znamená přidání předmětu do Vašeho uživatelského účtu?';
AddItem.id = 'addItem';

export default AddItem;
