import { TitledContent } from '../../Layout/Page';
import TermsAndConditionsSection from './components/TermsAndConditionsSection';

const TermsAndConditionsApp = () => (
	<TitledContent title="OLOOPO Obchodní podmínky">
		<TermsAndConditionsSection />
	</TitledContent>
);

export default TermsAndConditionsApp;
