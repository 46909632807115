import React, { useState } from 'react';
import Grid2 from '@mui/material/Unstable_Grid2';
import { head, tail } from 'ramda';
import Page, { TitledContent } from '../../Layout/Page';
import YoutubeTileResolver from './components/YoutubeTileResolver';
import { useTranslation } from '../../features/localization/hooks';
import { tutorialVideos } from './constants';

const Tutorials = () => {
	const t = useTranslation('tutorials');

	const [activeVideoIndex, setActiveVideoIndex] = useState(null);

	const mainVideo = head(tutorialVideos);

	const modifiedTutorialVideos = tail(tutorialVideos);

	const handleImageClick = (index, elementId) => {
		const element = document.getElementById(elementId);
		if (element) {
			element.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}

		setActiveVideoIndex((prevIndex) => (prevIndex === index ? null : index));
	};

	return (
		<Page>
			<TitledContent title={t('title')}>
				<Grid2 container spacing={{ xs: 6, md: 4 }} marginTop={'16px'} direction={{ md: 'row' }}>
					<Grid2 id={mainVideo.title} xs={12} md={8}>
						<YoutubeTileResolver
							isMainVideo
							video={mainVideo}
							isVideo={activeVideoIndex === null}
							title={t(mainVideo.title)}
							description={t(mainVideo.description)}
							onClick={() => handleImageClick(null, mainVideo.title)}
						/>
					</Grid2>
					<Grid2 md={4} style={{ margin: 0, padding: 0 }} />
					{modifiedTutorialVideos.map((video, index) => (
						<Grid2 id={video.title} key={video.url} item xs={12} md={activeVideoIndex === index ? 8 : 4}>
							<YoutubeTileResolver
								isVideo={index === activeVideoIndex}
								title={t(video.title)}
								description={t(video.description)}
								activeIndex={activeVideoIndex}
								video={video}
								onClick={() => handleImageClick(index, video.title)}
							/>
						</Grid2>
					))}
				</Grid2>
			</TitledContent>
		</Page>
	);
};

export default Tutorials;
