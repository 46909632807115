export default {
	title: 'O nás',
	aboutHeader: 'Naší misí je umožnit lidem získat to, po čem touží, výměnou za to, co už nepotřebují.',
	aboutTextOne:
		'Nápad cirkulární výměny dostal náš zakladatel a CEO, Marián Seifert, když jako student\
		chtěl získat učebnice do nového ročníku výměnou za učebnice z roku předchozího.',
	aboutTextTwo:
		'Vývoj platformy začal v roce 2020 a společnost byla oficiálně založena na Den Země roku 2022.\
		V únoru 2023 byla podána patentová přihláška chránící náš revoluční algoritmus.',
	boardTitle: 'Výkonná rada',
};
